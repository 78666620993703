<template>
    <div v-if="Act.UserName===$store.state.UserName" title="若是打开自己创建的活动">
        <div class="XLR YC MR4">
            <div class="BottGray PA3">活动简介：</div>
            <div>
                <div class="DispIB">
                    <span v-if="Act.Pic.length===0">─</span>
                    <ShowPFromThumb v-else :Msg="{Pic:Act.Pic,Update:false}"></ShowPFromThumb>
                </div>
                <div class="DispIB ML2">
                    <span v-if=" Act.Video.length===0">─</span>
                    <ShowVFromThumb v-else :Msg="{Video: Act.Video,Update:false}"></ShowVFromThumb>
                </div>
            </div>
        </div>
        <div class="MP4">
            <p>{{Act.Text}}</p>
            <div class="XR MLR4 TF">{{StampToTimeStr(Act.TimeStamp)}}</div>
        </div>
        <MyDivider ShowStr="当前报名用户列表"></MyDivider>
        <div class="PA3 BottGray" v-for="(Join,index) in Joins" :key="index">
            <div class="XL">
                <div style="width: 21vw;overflow: hidden"><img :src="'UserUpload/1/S' + Join.UserLogo" class="AvatarWH">
                </div>
                <div class="ML2" style="width: 68vw;overflow: hidden">
                    <div class="XLR YC BottO">
                        <div>
                            <UserFace :UserName="Join.UserName" :Certified="Join.Certified" :ZX="Join.ZX"></UserFace>
                        </div>
                        {{Join.Title}}
                    </div>
                    <ShowPVT :PVT="{P:Join.Pic,V:Join.Video,T:Join.Text,Update:false}"></ShowPVT>
                    留言：{{Join.JoinContact}}
                </div>
            </div>
            <div class="TG">
                个别通知：
                <div class="W86 PA2 BoxGray1 TF" @click="InputI1=index">{{Join.ActContact===''?'......':Join.ActContact}}
                </div>
            </div>
        </div>
        <MyInput v-if="InputI1!==-1" Title="发送通知" Type="text" :Begin="Joins[InputI1].ActContact"
                 @FromMyI="MyInputVal1"></MyInput>
    </div>
    <div v-else title="否则,开始报名响应">
        <div class="XLR MP4">
            <div>
                <img :src="'UserUpload/1/S' + Act.UserLogo" class="AvatarWH">
                <div class="TF T3">
                    {{Act.UserName}}
                </div>
            </div>
            <div>
                <p>{{Act.Text}}</p>
                <div class="XR MLR4">
                    <div class="DispIB">
                        <span v-if="Act.Pic.length===0">─</span>
                        <ShowPFromThumb v-else :Msg="{Pic:Act.Pic,Update:false}"></ShowPFromThumb>
                    </div>
                    <div class="DispIB ML2">
                        <span v-if=" Act.Video.length===0">─</span>
                        <ShowVFromThumb v-else :Msg="{Video: Act.Video,Update:false}"></ShowVFromThumb>
                    </div>
                    <div class="DispIB TF">{{StampToTimeStr(Act.TimeStamp)}}发布</div>
                </div>
            </div>
        </div>
        <MyDivider ShowStr="活动报名"></MyDivider>
        <div class="R00">
            <div class="R04">
                <img src='../../../static/img/ButtonPic/ACancel.png' @click="Quit" class="RCB  Box41">
                <img src='../../../static/img/ButtonPic/AConfirm.png' @click="SaveCurrJoinDoc" class="RCB  Box42 ML2">
            </div>
        </div>
        <div v-if="Join.ActContact!==''" class="MP4 BottO">
            个别通知：{{Join.ActContact}}
        </div>
        <div class="MP4 TF LH150">
            <div class="XL YC TF ">
                角色：
                <el-select v-model="Join.Title" style="width: 120px">
                    <el-option v-for="item in ['出席参加','A角','B角','C角','打工服务','志愿者']" :key="item" :label="item" :value="item"/>
                </el-select>
            </div>
            <div class="MT2 XL YC">
                报名者信息：　
                <ShowUpdatePVT v-if="Show1" :PVTF="{P:Join.Pic,V:Join.Video,T:Join.Text, TextTitle:'报名者情况介绍'}"
                               @ShowUT="CallBack2"></ShowUpdatePVT>
            </div>
            <div class="MT2 XLR YC">
                留言：<input type="text" class="MP4 W60" v-model="Join.JoinContact"/>
            </div>
            
            <MyInput v-if="InputI2===2" Title="留言" Type="text" :Begin="Join.JoinContact"
                     @FromMyI="MyInputVal2"></MyInput>
            <br>
        </div>
    </div>
</template>

<script>
    import {myMongoDBPost, DeleteMsgDetails, StampToTimeStr} from '@/components/SharedVues/Shared0.js'
    import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";
    import ShowPVT from "@/components/MyPVTs/ShowPVT";
    import ShowPFromThumb from "@/components/MyPVTs/ShowPFromThumb";
    import ShowVFromThumb from "@/components/MyPVTs/ShowVFromThumb";
    import MyInput from "@/components/SharedVues/MyInput";
    import UserFace from "../SharedVues/UserFace";
    import MyDivider from "../SharedVues/MyDivider";

    export default {
        name: "OpenOneAct",
        components: {
            MyDivider,
            UserFace,
            MyInput,
            ShowVFromThumb,
            ShowPFromThumb,
            ShowPVT,
            ShowUpdatePVT
        },
        props: ['Msg'],
        data() {
            return {
                ShowPoppup: true,
                Act: this.Msg,
                Join: {
                    ActUserName: this.Msg.UserName,
                    ActId: this.Msg._id,

                    UserName: this.$store.state.UserName,
                    Certified: this.$store.state.Certified,
                    UserLogo: this.$store.state.UserLogo,
                    ZX: this.$store.state.ZX,

                    Title: '出席参加',
                    Pic: '',
                    Video: '',
                    Text: '',
                    TimeStamp: (new Date()).getTime(),
                    JoinContact: '',
                    ActContact: ''
                },
                Joins: [],
                P: '',
                C: '',
                DC: '',
                Show1: false,
                InputI1: -1,
                InputI2: -1
            }
        },
        mounted() {
            let that = this;
            //console.log("进入OpenOneAct>>>>>");

            if (that.$store.state.UserName === this.Act.UserName) {
                let aObj = {UPTJ: {_id: this.Msg._id}, UPObj: {NewJoin: false}};
                myMongoDBPost("UpdateDoc/Acts", aObj, function () {
                })//一个求缘被打开,是由发布者自己打开的,则应将相应的对缘相应标志设为false
            } else {
                let aObj = {UPTJ: {_id: this.Join.ActId}, UPObj: {$inc: {LookedTimes: 1}}};
                myMongoDBPost("UpdateDoc2/Acts", aObj, function () {
                })
            } //一个求缘被打开,但不是由发布者打开的,则应将相应的LookedTimes:自动增1


            myMongoDBPost("GetDocs/Joins", {ActId: this.Msg._id}, function (Docs) {
                that.Joins = Docs;
                myMongoDBPost("GetDocs/Joins", {ActId: that.Msg._id, UserName: that.$store.state.UserName}, function (Docs2) {
                    if (Docs2.length > 0) that.Join = Docs2[0];
                    that.Show1 = true;
                });
            });
            /*
                  myMongoDBPost("GetDocs/fyuser0", {UserName: this.Msg.UserName}, function (Docs) {
                    that.P = Docs[0].FixedAddr.P;
                    that.C = Docs[0].FixedAddr.C;
                    that.DC = Docs[0].FixedAddr.DC;
                  });*/
            this.Act.TimeStamp = (new Date()).getTime();
        },
        methods: {
            MyInputVal1(Val) {
                this.Joins[this.InputI1].ActContact = Val;
                this.SaveCurrJoinActContact(this.Joins[this.InputI1]._id, this.Joins[this.InputI1].ActContact);
                this.InputI1 = -1;
            },
            MyInputVal2(Val) {
                this.Join.JoinContact = Val;
                this.InputI2 = -1;
            },
            StampToTimeStr(X) {
                return StampToTimeStr(X)
            },
            CallBack1(Val) {
                if (Val.P) this.Act.Pic = Val.P;
                if (Val.V) this.Act.Video = Val.V;
                if (Val.T) this.Act.Text = Val.T;
            },
            SaveCurrActDoc() {
                let that = this;
                //delete that.Act._id;
                //let aObj = {UPTJ: {_id: Id}, UPObj: that.Act};
                myMongoDBPost("UpdateObjs/Acts", [that.Act], function () {
                    that.ShowPoppup = false;
                    that.$emit("OpenOneAct");

                });
            },
            SaveCurrJoinActContact(Id, ActContact) {
                let aObj = {UPTJ: {_id: Id}, UPObj: {ActContact: ActContact}};
                myMongoDBPost("UpdateDoc/Joins", aObj, function () {
                })
            },

            Delete(Id) {
                let that = this;
                DeleteMsgDetails(Id);
                if (Id !== undefined) myMongoDBPost("RemoveDoc/Acts", {_id: Id}, function () {
                    myMongoDBPost("RemoveDoc/Joins", {ActId: Id}, function () {
                        that.ShowPoppup = false;
                        that.$emit("OpenOneAct");
                    })
                })
            },
            SetEndBool(Id) {
                let that = this, aObj = {UPTJ: {_id: Id}, UPObj: {EndBool: 2}};
                myMongoDBPost("UpdateDoc/Acts", aObj, function () {
                    myMongoDBPost("UpdateDoc/Joins", aObj, function () {
                        that.ShowPoppup = false;
                        that.$emit("OpenOneAct");
                    })
                })
            },
            CallBack2(Val) {
                if (Val.P) this.Join.Pic = Val.P;
                if (Val.V) this.Join.Video = Val.V;
                if (Val.T) this.Join.Text = Val.T;
            },
            SaveCurrJoinDoc() {
                let that = this;//如果存在则修改,如果不存在则存入

                let aObj = {UPTJ: {_id: this.Join.ActId}, UPObj: {NewJoin: true}};
                myMongoDBPost("UpdateDoc/Acts", aObj, function () {
                });
                if (this.Join._id) myMongoDBPost("UpdateObjs/Joins", [that.Join], function () {
                    that.ShowPoppup = false;
                    that.$emit("OpenOneAct");
                }); else myMongoDBPost("PutDoc/Joins", this.Join, function (data) {
                    that.ShowPoppup = false;
                    that.$emit("OpenOneAct");
                });
            },
            Quit() {
                this.ShowPoppup = false;
                this.$emit("OpenOneAct");
            },
        }
    }
</script>

<style scoped>
</style>
