import {createStore} from 'vuex';

export default createStore({
    state: {
        StepEnable: false,//Step组件使能控制
        BackN: 0,

        UserName: '游客',//当前泛约昵称
        ZX: 0,//泛约币字符串
        UserLogo: '',//当前用户头像
        Certified: '尚未',//当前用户等级
        RealName: '',//．．．．用户实名
        Mobile: '',//．．．．手机号码
        DefaultItem: '',
        Sex: '',
        Age: 0,

        UserP: '省份',//当前用户所在省
        UserC: '城市',//当前用户所在市
        UserDC: '区县',//当前用户所在区县


        WXId: '',//登录后根据访问者用户名和密码取出的当前用户微信id,通过注册时将VisitWXId写入或首次微信登录时(VisitWXId!==初值' && WXId==='')写入
        FYArea: 'P:天津:C:D',//当前泛约区域:通过FYArea组件唯一入口写入
        UserBools: ['日记本'],// 用户身份标志数组

        RealTimeStr: '',//当前用户实时呼叫字符串;后面的...应该不断的延长

        HTM0: '决不放弃',//后台最高管理员的泛约昵称
        //SeedDC:'素野凝香',//种子代持股东用户名
        HTMs: [],//后台运营管理员
        SiteMs: [],//场地管理员泛约昵称数组:续先进行资格申请

        CurrInputP: '',
        CurrInputC: '',
        CurrInputDC: '',

        Bool1: '',

        CurrZindex: 10,//遮照层当前序号

        CurrItemStr: '',


        LandTo: ''

    },
    mutations: {
        ChangeUserP(state, value) {
            state.UserP = value;
        },
        ChangeUserC(state, value) {
            state.UserC = value;
        },
        ChangeUserDC(state, value) {
            state.UserDC = value;
        },
        ChangeTermType(state, value) {
            state.TermType = value;
        },
        ChangeRealTimeStr(state, value) {
            state.RealTimeStr = value;
        },
        ChangeBackN(state, value) {
            state.BackN = value;
        },

        ChangeStepEnable(state, value) {
            state.StepEnable = value;
        },

        ChangeUserName(state, value) {
            state.UserName = value;
        },
        ChangeZX(state, value) {
            state.ZX = Math.trunc(value);
        },
        ChangeUserLogo(state, value) {
            state.UserLogo = value;
        },
        ChangeCertified(state, value) {
            state.Certified = value;
        },

        ChangeRealName(state, value) {
            state.RealName = value;
        },
        ChangeMobile(state, value) {
            state.Mobile = value;
        },
        ChangeDefaultItem(state, value) {
            state.DefaultItem = value;
        },
        ChangeSex(state, value) {
            state.Sex = value;
        },
        ChangeAge(state, value) {
            state.Age = value;
        },

        ChangeWXId(state, value) {
            state.WXId = value;
        },
        ChangeFYArea(state, value) {
            state.FYArea = value;
        },
        ChangeUserBools(state, value) {
            state.UserBools = value;
        },
        ChangeSiteMs(state, n) {
            state.SiteMs.push(n);
        },

        ChangeCurrInputP(state, value) {
            state.CurrInputP = value;
        },
        ChangeCurrInputC(state, value) {
            state.CurrInputC = value;
        },
        ChangeCurrInputDC(state, value) {
            state.CurrInputDC = value;
        },

        ChangeBool1(state, value) {
            state.Bool1 = value;
        },
        ChangeCurrZindex(state, value) {
            state.CurrZindex = value;
        },

        ChangeCurrItemStr(state, value) {
            state.CurrItemStr = value;
        },
        ChangeLandTo(state, value) {
            state.LandTo = value;
        },

    }
})
