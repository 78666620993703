<template>
    <el-divider/>
    <div class="XLR MP4 TB" @click="Show1 = true">
        出生时辰：({{UserDoc0.BirthHour}})
        <el-icon>
            <ArrowRight/>
        </el-icon>
    </div>
    <PopUpDown v-if="Show1" Title="请选择" Pos="btt" :ANs="1" @FromPop5="Show1=false">
        <div class="Popup0">
            <div class="Css0" v-for="(x,index) in DataDic.birthHour" :key="index"
                 @click="UserDoc0.BirthHour=x,Show1=false">{{x}}
            </div>
        </div>
    </PopUpDown>
    <el-divider/>
    
    <div class="XLR MP4 TB" @click="Show2 = true">
        血型：({{UserDoc0.BloodType}})
        <el-icon>
            <ArrowRight/>
        </el-icon>
    </div>
    <PopUpDown v-if="Show2" Title="请选择" Pos="btt" :ANs="1" @FromPop5="Show2=false">
        <div class="Popup0">
            <div class="Css2" v-for="(x,index) in DataDic.bloodType" :key="index"
                 @click="UserDoc0.BloodType=x,Show2=false">{{x}}
            </div>
        </div>
    </PopUpDown>
    <el-divider/>
    
    <div class="XLR MP4 TB" @click="Show3 = true">
        婚姻状况：({{UserDoc0.Marriage}})
        <el-icon>
            <ArrowRight/>
        </el-icon>
    </div>
    <PopUpDown v-if="Show3" Title="请选择" Pos="btt" :ANs="1" @FromPop5="Show3=false">
        <div class="Popup0">
            <div class="Css1" v-for="(x,index) in DataDic.marriage" :key="index"
                 @click="UserDoc0.Marriage=x,Show3=false">
                {{x}}
            </div>
        </div>
    </PopUpDown>
    <el-divider/>
    
    <div class="XLR MP4 TB" @click="Show4 = true">
        学历：({{UserDoc0.Education}})
        <el-icon>
            <ArrowRight/>
        </el-icon>
    </div>
    <PopUpDown v-if="Show4" Title="请选择" Pos="btt" :ANs="1" @FromPop5="Show4=false">
        <div class="Popup0">
            <div class="Css1" v-for="(x,index) in DataDic.education" :key="index"
                 @click="UserDoc0.Education=x,Show4=false">{{x}}
            </div>
        </div>
    </PopUpDown>
    <el-divider/>
    
    <div class="XLR MP4 TB" @click="Show5 = true">
        出国经历：({{UserDoc0.AbroadExperience}})
        <el-icon>
            <ArrowRight/>
        </el-icon>
    </div>
    <PopUpDown v-if="Show5" Title="请选择" Pos="btt" :ANs="1" @FromPop5="Show5=false">
        <div class="Popup0">
            <div class="Css3" v-for="(x,index) in DataDic.abroadExperience" :key="index"
                 @click="UserDoc0.AbroadExperience=x,Show5=false">{{x}}
            </div>
        </div>
    </PopUpDown>
    <el-divider/>
    <div v-if="Loaded" class="XLR MP4 TB">
        综合性自我介绍：
        <ShowUpdatePVT :PVTF="{P:UserDoc0.Pic2,V:UserDoc0.Video2,T:UserDoc0.Text2, TextTitle:'自我介绍(300自以内)'}"
                       @ShowUT="CallBack2"></ShowUpdatePVT>
    </div>
    <el-divider/>
    <!--div>
      <el-divider>名人专家身怀绝技者需输入</el-divider>
      <div class="MP4 TF">
        各种头衔:
        <textarea class="Mtextarea2" v-model="UserDoc0.Honor"></textarea>
        <br>
        擅长方向:<textarea class="Mtextarea2" v-model="UserDoc0.GoodAt"></textarea>
      </div>
      <MyInput v-if="InputI===1" Title="各种头衔" Type="textarea" :Begin="UserDoc0.Honor" @FromMyI="MyInputVal"></MyInput>
      <MyInput v-if="InputI===2" Title="擅长方向" Type="textarea" :Begin="UserDoc0.GoodAt"
               @FromMyI="MyInputVal"></MyInput>
    </div-->
</template>

<script>
    import {myMongoDBPost, myDataFileGet, CloneObject} from '@/components/SharedVues/Shared0.js'

    import PopTitle from "@/components/MyPopWin/PopTitle";

    import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";
    import MyInput from "@/components/SharedVues/MyInput";
    import PopUpDown from "../MyPopWin/PopUpDown";

    export default {
        name: "FJXX",
        components: {PopUpDown, MyInput, ShowUpdatePVT, PopTitle},
        data() {
            return {
                DataDic: {},
                Checked3: false,
                UserDoc1: {},
                UserDoc0: {
                    BirthHour: '请选择录入',
                    BloodType: '请选择录入',
                    Marriage: '请选择录入',
                    Education: '请选择录入',
                    AbroadExperience: '请选择录入',
                    Honor: '',
                    GoodAt: '',
                    Pic2: '',
                    Video2: '',
                    Text2: ''
                },
                Show1: false,
                Show2: false,
                Show3: false,
                Show4: false,
                Show5: false,
                Loaded: false,
                InputI: -1,
            }
        },
        mounted() {
            let that = this;
            myDataFileGet("DataDictionary/?FindWhat=FJXXDataDics", function (data) {
                that.DataDic = data;//是一个对象
            });
            if (this.$store.state.UserName !== '游客')
                myMongoDBPost("GetDocs/fyUser0", {UserName: this.$store.state.UserName}, function (data) {
                    that.UserDoc0 = {
                        BirthHour: data[0].BirthHour || '请选择录入',
                        BloodType: data[0].BloodType || '请选择录入',
                        Marriage: data[0].Marriage || '请选择录入',
                        Education: data[0].Education || '请选择录入',
                        AbroadExperience: data[0].AbroadExperience || '请选择录入',
                        Honor: data[0].Honor || '',
                        GoodAt: data[0].GoodAt || '',
                        Pic2: data[0].Pic2 || '',
                        Video2: data[0].Video2 || '',
                        Text2: data[0].Text2 || ''
                    };//用户信息
                    that.UserDoc1 = CloneObject(that.UserDoc0);
                    if (data[0].UserBools.indexOf('开放用户') >= 0) that.Checked3 = true;
                    that.Loaded = true;
                });//到数据库中取出当前用户的用户资料数据
        },
        beforeUnmount() {
            let that = this;
            if (this.$store.state.UserName !== '游客') myMongoDBPost("UpdateDoc/fyuser0", {
                UPTJ: {UserName: this.$store.state.UserName},
                UPObj: that.UserDoc0
            }, function () {
            })
        },
        methods: {
            CallBack2(Val) {
                if (Val.P) this.UserDoc0.Pic2 = Val.P;
                if (Val.V) this.UserDoc0.Video2 = Val.V;
                if (Val.T) this.UserDoc0.Text2 = Val.T;
            },
            MyInputVal(Val) {
                if (this.InputI === 1) this.UserDoc0.Honor = Val;
                if (this.InputI === 2) this.UserDoc0.GoodAt = Val;
                this.InputI = -1;
            }
        }
    }
</script>

<style scoped>
    
    .Popup0 {
        padding: 15px 0 0 10vw;
        text-align: left;
        }
    .Css0 {
        display: inline-block;
        width: 45vw;
        text-align: left;
        color: #04597d;
        padding-top: 10px;
        }
    .Css1 {
        display: inline-block;
        width: 30vw;
        text-align: left;
        color: #04597d;
        padding-top: 10px;
        }
    .Css2 {
        display: inline-block;
        width: 20vw;
        text-align: left;
        color: #04597d;
        padding-top: 10px;
        }
    .Css3 {
        display: inline-block;
        width: 28vw;
        text-align: left;
        color: #04597d;
        padding-top: 10px;
        }
</style>
