<template>
  <div>
    <div v-if="AreaStr.charAt(0)==='N'" class="XL YC  PA2" @click="SetShow(0)">
      <div class="DispIB TC MR2">全国</div>
      <el-icon><ArrowDown /></el-icon>
    </div>
    <div v-if="AreaStr.charAt(0)==='P'" class="XL YC  PA2">
      <div class="DispIB TB MR2" @click="SetShow(0)">{{AreaStr.split(":")[1]}}</div>
      <div @click="SetShow(1)">
        <el-icon><ArrowDown /></el-icon>
      </div>
    </div>
    <div v-if="AreaStr.charAt(0)==='C'" class="XL YC  PA2">
      <div class="DispIB TB MR2" @click="Show=0">{{AreaStr.split(":")[1]}}</div>&nbsp;•&nbsp;
      <div class="DispIB TB MR2" @click="SetShow(1)">{{AreaStr.split(":")[2]}}</div>
      <div class="DispIB" @click="SetShow(2)">
        <el-icon><ArrowDown /></el-icon>
      </div>
    </div>
    <div v-if="AreaStr.charAt(0)==='D'" class="XL YC  PA2">
      <div class="TB MR2" @click="Show=0">{{AreaStr.split(":")[1]}}</div>&nbsp;•&nbsp;
      <div class="TB MR2" @click="SetShow(1)">{{AreaStr.split(":")[2]}}</div>&nbsp;•&nbsp;
      <div class="TB MR2" @click="SetShow(2)">{{AreaStr.split(":")[3]}}</div>
    </div>
    <MySele1 v-if="Show===0" :Opts="Ps" AddAN="" :ColN="3" @FromMySele1="SetAreaStr($event,0)"></MySele1>
    <MySele1 v-if="Show===1" :Opts="CurrCs" AddAN="全国" :ColN="3" @FromMySele1="SetAreaStr($event,1)"></MySele1>
    <MySele1 v-if="Show===2" :Opts="CurrDCs" AddAN="全省" :ColN="3" @FromMySele1="SetAreaStr($event,2)"></MySele1>
  </div>
</template>

<script>//特别提醒：泛约区域和泛约地址是有区别的
  import {   UpdatelocalStorage, myMongoDBPost} from '@/components/SharedVues/Shared0.js'
  import MySele1 from "./MySele1";
  //泛约区域应立即更新localStorage,并发射最新区域字符串:P=P:C:DC 第一位P表示当前区域是省 具体省份在后面
  export default {
    name: "FYArea",
    components: {MySele1},
    data() {
      return {
        AreaStr: this.$store.state.FYArea,
        Ps: [],
        CurrCs: [],
        CurrDCs: [],
        Show: -1,
      }
    },
    mounted() {
      this.$emit('FromFYArea', this.FYAreaShow())
    },
    methods: {
      SaveAreaStr() {
        UpdatelocalStorage('FYArea', this.AreaStr);//更新localStorage
        this.$store.commit("ChangeFYArea", this.AreaStr);
      },
      FYAreaShow() {
        let Asd = this.AreaStr;
        if (Asd.split(":")[0] === 'N') return '全国';
        else if (Asd.split(":")[0] === 'P') return Asd.split(":")[1];
        else if (Asd.split(":")[0] === 'C') return Asd.split(":")[1] + Asd.split(":")[2];
        else if (Asd.split(":")[0] === 'D') return Asd.split(":")[1] + Asd.split(":")[2] + Asd.split(":")[3];
      },
      SetShow(Bool) {
        let that = this;
        if (Bool === 0) myMongoDBPost("getAddrPs", {N: '中国'}, function (data) {
          that.Ps = data;
          that.Show = 0;
        });
        if (Bool === 1) myMongoDBPost("getAddrCs", {P:that.AreaStr.split(":")[1]}, function (data) {
          that.CurrCs = data;
          that.Show = 1;
        });
        if (Bool === 2) myMongoDBPost("getAddrDCs", {C:that.AreaStr.split(":")[2]}, function (data) {
          that.CurrDCs = data;
          that.Show = 2;
        });
      },
      SetAreaStr(Val, Bool) {//要对Val的四种返回值情况，都要做处理
        let X=this.AreaStr.split(":");
        if (Val !== '无' && Val !== '') {
          if (Bool === 0) this.AreaStr = 'P:' + Val + ':C:D';//某省
          if (Bool === 1) {
            if (Val === '全国') this.AreaStr = 'N:P:C:D'; else this.AreaStr = 'C:' + X[1] + ":" + Val + ':D';//某市
          }
          if (Bool === 2) {
            if (Val === '全省') this.AreaStr = 'P:' + X[1] + ':C:D'; else this.AreaStr = 'D:' + X[1] + ":" + X[2] + ":" + Val;//某区县
          }
          this.SaveAreaStr();
        }
        this.Show = -1;
          console.log("2.SetAreaStr")
        this.$emit("FromFYArea", this.FYAreaShow())
      }
    }
  }
</script>

<style scoped>

</style>
