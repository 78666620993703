<template>
    <div class="XLR YC BoxGray1 TG" @click="Show=3">
        <div class="PA2">{{SiteShortName}}</div>
        <el-icon><ArrowDown/></el-icon>
    </div>
    <MySele1 v-if="Show===3" :Opts="MySiteShortNames" :ColN="3" AddAN="更多..." @FromMySele1="GetSiteDoc"></MySele1>
    <PopTitle v-if="Show===2" Title="请配置您的喜约场地" @FromPopUp="SetValidMySites">
        <SetMySites :ItemStr="ItemStr"></SetMySites>
    </PopTitle>
</template>

<script>
    import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import MySele1 from "../SharedVues/MySele1";
    import PopTitle from "../MyPopWin/PopTitle";
    import SetMySites from "./SetMySites";

    export default {
        name: "SetGetOneMySite",//内含如果没有设置相应的喜约项目，则同时增加
        components: {SetMySites, PopTitle, MySele1},
        props: ['ItemStr'],
        data() {
            return {
                Show: 0,
                SiteShortName: '?　?　?',
                MySiteShortNames: []
            }
        },
        mounted() {
            let that = this, aObj = {UserName: that.$store.state.UserName, ItemStr: that.ItemStr};
            myMongoDBPost("GetDocs/byset0", aObj, function (Docs) {
                if (Docs.length === 0) myMongoDBPost("PutDoc/byset0", aObj, function (res) {
                    that.SetValidMySites();
                }); else that.SetValidMySites();
            });
        },
        methods: {
            SetValidMySites() {
                let that = this;
                that.MySiteShortNames = [];
                myMongoDBPost("GetValidMySites", {UserName: this.$store.state.UserName, ItemStr: that.ItemStr}, function (Docs1) {
                    for (let i = 0, len = Docs1[0].MySites.length; i < len; i++)
                        that.MySiteShortNames.push(Docs1[0].MySites[i].SiteShortName);
                    that.Show = 0;
                })
            },
            GetSiteDoc(Val) {
                let that = this;
                if (Val === '') this.Show = 0;
                else if (Val === '更多...' || Val === '无') this.Show = 2; else {
                    that.SiteShortName = Val;
                    myMongoDBPost("GetDocs/fySites", {SiteShortName: Val}, function (Docs) {
                        if (Docs.length === 0) that.$emit("FromSetGetOneMySite", '场地简称数据对不上');
                        else that.$emit("FromSetGetOneMySite", Docs[0]);
                    });
                    this.Show = 0;
                }
            },
        }
    }
</script>

<style scoped>

</style>