<template>
    <div v-if="CurrSubActType==='团体对抗赛'" class="BottO XLR YC TB MP4 BGLightGreen" @click="Show=6">
        积分名次排行榜
        <div>
            <el-icon><ArrowRight/></el-icon>
        </div>
    </div>
    <div v-if="Show===-1">
        <div v-for="(Act1,index) in Act1s" :key="index" class="PA3 BottGray MT2">
            <div v-if="$store.state.UserName===$store.state.HTM0 || $store.state.UserName===Act1.UserName" class="XLR YC"> 　
                <div class="TE BDCircle BoxBR WH77 XYC" @click="Delete(Act1._id,index)">删</div>
                <div class="TE BDCircle BoxBR WH77 XYC" @click="SeleI = index,Show=2" style="margin: 0 1.5mm">&nbsp;改&nbsp;</div>
                <div class="W50 TG" @click="SeleI=index">{{index+1}}.{{Act1.Title}}</div>
                <div class="AlignC T2">
                    <div v-if="Act1.SubActType.indexOf('团体')>=0">
                        <div v-if="Act1.Victor==='平局' || Act1.Victor==='未定'">
                            <div class="TC NoWrap">{{Act1.Victor}}</div>
                            <div class="TF BoxT4 NoWrap">&nbsp;&nbsp;{{Act1.Second}}&nbsp;&nbsp;</div>
                        </div>
                        <div v-else>
                            <div class="TB NoWrap">胜：{{Act1.Victor}}</div>
                            <div class="TF BoxT4 NoWrap">&nbsp;&nbsp;负：{{Act1.Second}}&nbsp;&nbsp;</div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="TB NoWrap">冠军：{{Act1.Victor}}</div>
                        <div class="TF BoxT4 NoWrap">&nbsp;&nbsp;亚军：{{Act1.Second}}&nbsp;&nbsp;</div>
                    </div>
                </div>
            </div>
            <div v-else class="XLR YC TG" @click="SeleI=index">
                <div>{{index+1}}.{{Act1.Title}}
                    <div class="TF T2">类型:{{Act1.SubActType}}</div>
                </div>
                <div class="AlignC T2">
                    <div v-if="Act1.SubActType.indexOf('团体')>=0">
                        <div v-if="Act1.Victor==='平局' || Act1.Victor==='未定'">
                            <div class="TC NoWrap">{{Act1.Victor}}</div>
                            <div class="TF BoxT4 NoWrap">&nbsp;&nbsp;{{Act1.Second}}&nbsp;&nbsp;</div>
                        </div>
                        <div v-else>
                            <div class="TB NoWrap">胜：{{Act1.Victor}}</div>
                            <div class="TF BoxT4 NoWrap">&nbsp;&nbsp;负：{{Act1.Second}}&nbsp;&nbsp;</div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="TB NoWrap">冠军：{{Act1.Victor}}</div>
                        <div class="TF BoxT4 NoWrap">&nbsp;&nbsp;亚军：{{Act1.Second}}&nbsp;&nbsp;</div>
                    </div>
                </div>
                <div>
                    <el-icon><ArrowRight/></el-icon>
                </div>
            </div>
        </div>
        <div class="XR YC MT2">
            <div class="AN ANBlue" @click="Show=4">参赛名单</div>
            <div v-if="OKBool" class="MP4 TG XR YC" @click="Show=1">
                <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77"> 创建子活动
            </div>
            <div v-else class="MLR4 AN3" @click="Show=3">网上报名</div>
        </div>
        <p class="TF T3">注：参赛名单为预报名表，实际参赛人员及出场顺序以子活动信息为准。</p>
    </div>
    <PopTitle v-if="Show===1" Title="子活动信息创建" @FromPopUp="Show=-1">
        <Act1Create OP="新增" :Msg="{ActId:ActDoc._id}" @Act1Create="Act1sRefresh()"></Act1Create>
    </PopTitle>
    <PopTitle v-if="Show===2" Title="子活动信息修改" @FromPopUp="Show=-1">
        <Act1Create OP="修改" :Msg="Act1s[SeleI]" @Act1Create="Act1sRefresh()"></Act1Create>
    </PopTitle>
    <PopTitle v-if="Show===3 " :Title="ActDoc.Title.substr(0,10)+'...预报名'" @FromPopUp="Show=-1">
        <Act1BM :ActDoc="ActDoc" :Act1s="Act1s"></Act1BM>
    </PopTitle>
    <PopTitle v-if="Show===4 " :Title="ActDoc.Title.substr(0,10)+'...报名表'" @FromPopUp="Show=-1">
        <Act1BMList v-if="OKBool" :ActId="ActDoc._id" OP="录入"></Act1BMList>
        <Act1BMList v-else :ActId="ActDoc._id" OP="查看"></Act1BMList>
    </PopTitle>
    <PopTitle v-if="Show===6" Title="当前积分名次排行榜" @FromPopUp="Show=-1">
        <table class="MT2">
            <thead>
            <tr>
                <th>名次</th>
                <th>参赛队</th>
                <th>胜</th>
                <th>负</th>
                <th>积分</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(PHB,index) in PHBs" :key="index">
                <td>{{index+1}}</td>
                <td>{{PHB.TeamName}}</td>
                <td>{{PHB.V1n}}</td>
                <td>{{PHB.F1n}}</td>
                <td>{{PHB.JF}}</td>
            </tr>
            </tbody>
        </table>
        <p class="TF T2 PA2">备注：按照国际惯例,每个队胜1场得3分,打平得2分,负1场得1分,不参加不得分。</p>
    </PopTitle>
    <Act1JFQ1 v-if="Show===-1 && SeleI>-1 && Act1s[SeleI].SubActType.indexOf('循环')>=0" :ActDoc="ActDoc" :Act1Doc="Act1s[SeleI]"
              @FromJFQ1="Act1sRefresh()"></Act1JFQ1>
    <Act1JFQ2 v-if="Show===-1 && SeleI>-1 && Act1s[SeleI].SubActType==='团体对抗赛'" :ActDoc="ActDoc" :Act1Doc="Act1s[SeleI]" @FromJFQ2="Act1sRefresh()"></Act1JFQ2>
    <Act1JFQ3 v-if="Show===-1 && SeleI>-1 && Act1s[SeleI].SubActType==='团体淘汰赛'" :ActDoc="ActDoc" :Act1Doc="Act1s[SeleI]" @FromJFQ3="Act1sRefresh()"></Act1JFQ3>
    <FYQ Title="以上活动关联微信群"></FYQ>
    <WXLink :WLandTo="'Act_'+ItemStr+'Act_' + ActDoc._id" Pic="UserUpload/static/SY/PHB/syNo0.jpg" :WTitle="ActDoc.Title"
            :WText="'计分员：'+ActDoc.UserName+'，点击可查看详情。'"></WXLink>
</template>

<script>
    import {myMongoDBPost,Compare} from '@/components/SharedVues/Shared0.js'
    import PopTitle from "../MyPopWin/PopTitle";
    import FyArea from "../SharedVues/FYArea";
    import MyInput from "../SharedVues/MyInput";
    import OpenOneAct from "./OpenOneAct";
    import CreateAct from "./CreateAct";
    import SeleOneItem from "../FrontPage/SeleOneItem";
    import Act0Main from "./Act0Main";
    import PopOneAN from "../MyPopWin/PopOneAN";
    import Act1Create from "./Act1Create";
    import Act1JFQ1 from "./Act1JFQ1";
    import Act1JFQ2 from "./Act1JFQ2";
    import Act1JFQ3 from "./Act1JFQ3";
    import WXLink from "../IndependentVues/WXLink";

    import SubEntrance from "../SharedVues/SubEntrance";
    import Act1BM from "./Act1BM";
    import Act1BMList from "./Act1BMList";
    import FYQ from "../IndependentVues/FYQ";

    export default {
        name: "Act1Main",
        components: {
            FYQ,
            Act1BMList,
            Act1BM,
            SubEntrance,
            WXLink,
            Act1JFQ3,
            Act1JFQ2,
            Act1JFQ1,
            Act1Create, PopOneAN, Act0Main, SeleOneItem, CreateAct, OpenOneAct, MyInput, FyArea, PopTitle
        },
        props: ['ActDoc', 'ItemStr'],
        data() {
            return {
                OKBool: this.$store.state.UserName === this.$store.state.HTM0 || this.$store.state.UserName === this.ActDoc.UserName,
                Act1s: [],
                SeleI: -1,
                Show: -1,
                CurrSubActType: '',
                PHBs: []
            }
        },
        mounted() {
            this.Act1sRefresh();
        },
        methods: {
            Act1sRefresh() {
                let that = this, SubActTypeN1 = 0, SubActTypeN2 = 0, PHBNames = [];
                that.Act1s = [];
                that.PHBs = [];
                console.log(this.ActDoc);
                myMongoDBPost("GetDocs/act1", {ActId: this.ActDoc._id}, function (Docs) {
                    that.Act1s = Docs;//如果其中有超过3个的团体对抗赛,就可认为是一个大型的"团体对抗赛".
                    for (let i = 0; i < Docs.length; i++) {
                        if (Docs[i].SubActType === '团体对抗赛') SubActTypeN1 += 1;
                        if (Docs[i].SubActType === '团体淘汰赛') SubActTypeN2 += 1;
                    }
                    if (SubActTypeN1 > 3) that.CurrSubActType = '团体对抗赛';
                    if (SubActTypeN2 > 3) that.CurrSubActType = '团体淘汰赛';

                    if (that.CurrSubActType === '团体对抗赛') {
                        for (let i = 0; i < Docs.length; i++) {//1.先构造好that.PHBs
                            if (Docs[i].SubActType === '团体对抗赛' && Docs[i].Victor !== '') {//检测到一个已结束的对抗比赛
                                if (PHBNames.indexOf(Docs[i].A) === -1) {
                                    PHBNames.push(Docs[i].A);
                                    //console.log(Docs[i])
                                    that.PHBs.push({TeamName: Docs[i].A, V1n: 0, F1n: 0, JF: 0})
                                }
                                if (PHBNames.indexOf(Docs[i].B) === -1) {
                                    PHBNames.push(Docs[i].B);
                                    that.PHBs.push({TeamName: Docs[i].B, V1n: 0, F1n: 0, JF: 0})
                                }
                            }
                        }
                        //以下采取按胜场次记分法,胜1场得3分,打平得2分,负1场得1分,不参加不得分
                        for (let i = 0; i < Docs.length; i++) {//2.再填写数据
                            if (Docs[i].SubActType === '团体对抗赛') {
                                let Index1 = PHBNames.indexOf(Docs[i].A), Index2 = PHBNames.indexOf(Docs[i].B);
                                if (Docs[i].Victor === '平局') {//V1n,F1n无变化
                                    that.PHBs[Index1].JF += 2;
                                    that.PHBs[Index2].JF += 2;
                                }
                                if (Docs[i].Victor === Docs[i].A) {//检测到一个已结束A队获胜的对抗比赛
                                    that.PHBs[Index1].V1n += 1;
                                    that.PHBs[Index1].JF += 3;
                                    that.PHBs[Index2].F1n += 1;
                                    that.PHBs[Index2].JF += 1;
                                }
                                if (Docs[i].Victor === Docs[i].B) {//检测到一个已结束B队获胜的对抗比赛
                                    that.PHBs[Index2].V1n += 1;
                                    that.PHBs[Index2].JF += 3;
                                    that.PHBs[Index1].F1n += 1;
                                    that.PHBs[Index1].JF += 1;
                                }
                            }
                        }
                        that.PHBs.sort(Compare('JF')).reverse();
                    }
                });
                that.Show = -1;//注意:这两行在里面,就不行
                that.SeleI = -1;
            },
            Delete(Id, index) {
                let that = this;
                if (Id !== undefined) myMongoDBPost("RemoveDoc/Act1", {_id: Id}, function () {
                    that.Act1s.splice(index, 1);
                })
            },
        }
    }
</script>

<style scoped>
</style>

