<template>
    <div>
        <div v-if="IsLoading">IsLoading...</div>
        <div class="BottO">
            <TimeSexAge :BYs="BYs" CallFrom="2" @FromChild="RefreshBYs"></TimeSexAge>
        </div>
        <div v-if="!IsLoading">
            <div v-for="(x,index) in BYs" :key="index" class="BottGray">
                <div v-show="x.ShowBool" :class="'XLR YC PA2 T3 '+(x.SelectedBool ? 'TG':'TF')">
                    <img v-if="x.UserLogo!==''" :src="'UserUpload/1/S'+x.UserLogo" @click="SeleI=index" class="BDCircle WHAA"/>
                    <img v-else :src="'UserUpload/static/After2021/Head.jpg'" class="BDCircle WHcc"/>
                    <div>
                        <div class="TC XLR">
                            <div>
                                <div style="position:absolute;width: 0;height: 0;overflow: visible">
                                    <div v-if="x.AppearanceFee>0" class="LeftTop">
                                        <el-badge :value="x.AppearanceFee.toString()"></el-badge>
                                    </div>
                                </div>
                                {{x.fyUserName}}
                                <UserFace3 :Certified="x.Certified"></UserFace3>
                            </div>
                            <ShowTimeWin :TW="x.TimeWinState"></ShowTimeWin>
                        </div>
                        <div class="DispIB T2">{{x.sex}}&nbsp;&nbsp;{{x.age}}&nbsp;&nbsp;人气:{{x.PopularityCredit.toFixed(2)}}</div>
                    </div>
                    <ShowPVT :PVT="{P:x.fyUserPic,V:x.fyUserVideo,T:x.fyUserText,Update:false}"></ShowPVT>
                    
                    <div class="AlignC">
                        <input type="checkbox" class="aui-switch" v-model="x.SelectedBool" @change="bySelect(x.SelectedBool,x.fyUserName,x.AppearanceFee)"/>
                    </div>
                
                </div>
            </div>
            
            <img v-if="SeleI!==-1" :src="'UserUpload/1/'+BYs[SeleI].UserLogo" @click="SeleI=-1" class="FullScr2"/>
        
        </div>
    </div>
</template>

<script>
    import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import TimeSexAge from "./TimeSexAge";
    import ShowTimeWin from "./ShowTimeWin";
    import ShowPVT from "@/components/MyPVTs/ShowPVT";
    import mixinsBySelected from '@/components/ListYY/mixinsBySelected'
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import UserFace3 from "../SharedVues/UserFace3";

    export default {
        name: "TM2bySele",
        components: {
            UserFace3,
            PopTitle,
            TimeSexAge,
            ShowTimeWin,
            ShowPVT
        },
        mixins: [mixinsBySelected],
        data() {
            return {
                Show: -1,
                fyUserName: this.$store.state.UserName,
                BYs: [],
                SelectedBYs: [],
                T2Bool: true,
                SeleI: -1
            }
        },
        mounted() {
            this.GetBYs();
            console.log("TM2bySele")
        },
        methods: {
            GetBYs() {//注意:不带入口参数
                this.IsLoading = true;
                let that = this;
                let aOBJ = {fyUserName: this.$store.state.UserName, ItemStr: this.$store.state.CurrItemStr, AreaStr: this.$store.state.FYArea};
                myMongoDBPost("LDFWReadByListData", aOBJ, function (byList) {
                    that.SelectedBYs = [];
                    that.BYs = byList;//console.log("TM2bySele:that.BYs="+that.BYs);
                    if (that.BYs.length === 0) that.$emit("eventFromTM", 'NoBY'); else that.$emit("eventFromTM", that.SelectedBYs);
                    that.IsLoading = false;
                });
            },
            RefreshBYs(Val) {
                this.BYs = Val;
            }
        }
    }
</script>

<style scoped>
    
    .RightTop3 {
        position: relative;
        height: 15px;
        top: -1.6rem;
        left: -1rem;
        }
    
    .LeftTop {
        position: absolute;
        width: 3rem;
        height: 15px;
        left: -2.6rem;
        top: -0.5rem
        }
    
    .WHAA {
        width: 1cm;
        height: 1cm
        }
    
    .Css1 {
        width: 10vw;
        text-align: center
        }
    
    .Css4 {
        width: 20vw;
        text-align: left;
        overflow: hidden;
        }
    
    .Css3 {
        width: 5vw;
        text-align: center
        }
</style>
