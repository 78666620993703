<template>
    <div v-if="ToLandTo">
        <div class="MP4 BottGray">亲爱的{{$store.state.UserName}}，{{CurrSiteUser.SiteName}}欢迎您！</div>
        <OneYKSite :CurrSiteUser="CurrSiteUser"></OneYKSite>
    </div>
    <div v-else>
        <div v-if=" Sites.length>0" class="MP4">请在以下列表中，点击选中项右侧箭头：</div>
        <div v-else class="MP4 XL YC"> 搜索区域：
            <FYArea @FromFYArea="Refresh0"></FYArea>
        </div>
        <div v-if="Sites.length===0" class=" MP4 AlignC MTB8">搜索区域内暂无课表数据!</div>
        <div v-else class="demo-collapse">
            <el-collapse accordion>
                <el-collapse-item v-for="(Site,index) in Sites" :key="index">
                    <template #title>
                        <div class="XL YC  PA3 LH300 ">
                            <input v-if="$store.state.UserName==='决不放弃'" class="DispIB W12" type="number" v-model.number="Site.Index"
                                   @blur="IndexChanged2(index,Site.Index)"/>
                            <div class="WH99">
                                <ShowPFromThumb v-if="Site.Pic!==''" :Msg="{Pic:Site.Pic,Update:false}"></ShowPFromThumb>
                            </div>
                            <div class="ML2 LH120">
                                <h5>{{Site.SiteName}}</h5>
                                <div class="XLR TC T2 W60">
                                    {{Site.Province}}{{Site.City}}
                                    <div>简称：{{Site.SiteShortName}}</div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <OneYKSite :CurrSiteUser="Site"></OneYKSite>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>

<script>
    import FYArea from "../SharedVues/FYArea";
    import ShowPFromThumb from "../MyPVTs/ShowPFromThumb";
    import ShowVFromThumb from "../MyPVTs/ShowVFromThumb";
    import {myMongoDBPost, Compare} from '@/components/SharedVues/Shared0.js'
    import OneYKSite from "./OneYKSite";

    export default {
        name: "YKQSetup0",
        components: {OneYKSite, ShowVFromThumb, ShowPFromThumb,   FYArea},
        data() {
            return {
                fyAreaBegin: '',
                Sites: [],
                CurrSiteUser: {},
                ToLandTo: false,
            }
        },
        mounted() {
            let that = this, ASD = this.$store.state.LandTo.split("YKQFromWXLink");
            if (ASD.length > 1) myMongoDBPost("GetDocs/SiteUsers", {_id: ASD[1]}, function (Docs) {
                if (Docs && Docs.length > 0) {
                    that.CurrSiteUser = Docs[0];
                    that.ToLandTo = true;
                } else {
                    alert('您所点击的链接已失效！');
                    that.$store.commit('ChangeLandTo', undefined);
                    that.Refresh0();
                }
            }); else this.Refresh0();
        },
        methods: {
            Refresh0() {
                //此处应注意添加判断，如果是课程管理员 或 来自指定 课表的链接，则不应再显示其它课表宣传
                let asd = this.$store.state.FYArea.split(":"), TJ = {DocBool: '课程管理员'};
                if (asd[0] === 'P') TJ.Province = asd[1];
                else if (asd[0] === 'C') TJ.Province = asd[1], TJ.City = asd[2];
                else if (asd[0] === 'D') TJ.Province = asd[1], TJ.City = asd[2], TJ.DC = asd[3];
                console.log(TJ);
                this.getSites(TJ);//默认先在省的范围内查找
            },
            getSites(TJ) {
                let that = this;
                this.Coachess = [];
                myMongoDBPost("GetDocs/SiteUsers", TJ, function (res) {
                    if (res.length > 0) that.Sites = res.sort(Compare('Index'));
                })
            },
            IndexChanged2(index, value) {
                let that = this, asd = {UPTJ: {_id: this.Sites[index]._id}, UPObj: {Index: value}};
                myMongoDBPost("UpdateDoc/SiteUsers", asd, function () {
                    that.getSites({DocBool: '课程管理员'});
                })
            }

        }
    }
</script>

<style scoped>

</style>
