<template>
    <div class="PA2">
        <div class="XL YC">真实姓名：
            <input type="text" class="PA3 W60" v-model="RealName"/>
        </div>
        <div class="XL YC MT2">身份证号：
            <input type="text" class="PA3 W60" v-model="IDNumber"/>
        </div>
        <div class="BD_Radius1 BGLightBlue MP4">
            <div class="DispIB BottGray">&nbsp;&nbsp;认证方式1：提交可视认证资料&nbsp;&nbsp;</div>
            <ShowUpdatePV :PV="{P:Pic,V:Video,PT:'身份证正面照片：',VT:'手持身份证短视频：'}" @ShowUT="CallBack"></ShowUpdatePV>
        </div>
        <div class="BD_Radius1 BGLightBlue MP4">
            <div class="DispIB BottGray MT2">&nbsp;&nbsp;认证方式2：通过诚信担保人&nbsp;&nbsp;</div>
            <p class="T3 TF">如果要通过诚信担保人进行实名认证，需要将“泛约昵称、真实姓名、身份证号”三项信息告诉您的担保人，并同时向担保人支付20个泛约币。</p>
        </div>
        <div class="PA2 BD_Radius1 BGBlue TW AlignC" @click="Save()">提交</div>
    </div>

</template>

<script>

    import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import ShowUpdatePV from "../MyPVTs/ShowUpdatePV";

    export default {
        name: "AskRealName",
        components: {ShowUpdatePV},
        data() {
            return {
                Pic: '',
                Video: '',
                RealName: '',
                IDNumber: ''
            }
        },
        mounted() {
            let that = this;
            that.RealName = this.$store.state.RealName;
            myMongoDBPost("GetDocs/fyuser0", {UserName: this.$store.state.UserName}, function (Docs) {
                that.Pic = Docs[0].Pic;
                that.Video = Docs[0].Video;
                that.IDNumber = Docs[0].IDNumber;
            })
        },
        methods: {
            Save() {
                let that = this;
                let TJ = {UserName: this.$store.state.UserName};
                let aObj1 = {RealName: that.RealName, IDNumber: that.IDNumber, Pic: this.Pic, Video: this.Video, Certified: '待验证'};
                let aObj2 = {RealName: that.RealName, IDNumber: that.IDNumber, Pic: this.Pic, Video: this.Video};
                if (that.RealName === '') return alert('您的实名尚未输入，无法进行实名认证!');
                else if (that.IDNumber === '') return alert('您的身份证号尚未输入，无法进行实名认证!');
                if (that.Pic !== '' && that.Video !== '') myMongoDBPost("UpdateDoc/fyuser0", {UPTJ: TJ, UPObj: aObj1}, function () {
                    that.$store.commit('ChangeCertified', '待验证');
                    alert('您的认证资料已提交, 泛约后台将会尽快回复!');
                    that.$emit('FromAskRealName');
                }); else myMongoDBPost("UpdateDoc/fyuser0", {UPTJ: TJ, UPObj: aObj2}, function () {
                    alert('请线下联系担保人，完成实名认证！');
                    that.$emit('FromAskRealName');
                })
            },
            CallBack(Val) {
                if (Val.P) this.Pic = Val.P;
                if (Val.V) this.Video = Val.V;
            }
        }
    }
</script>

<style scoped>

</style>