<template>
    <div class="MP4" v-for="(AS,index1) in AllSites" :key="index1">
        <div class="BottGray W26 PA3">{{AS.P}}：</div>
        <div class="DispIB PA3" v-for="(SS,index2) in AS.Sites" :key="index2">
            <div v-if="SS.SelectedBool" @click="SetMySite(index1,index2,false)" class="MT2 BoxBR TE">
                {{SS.SiteShortName}}
                <el-icon><Check/></el-icon>
            </div>
            <div v-else @click="SetMySite(index1,index2,true)" class="MT2 ML2 TG">{{SS.SiteShortName}}</div>
        </div>
    </div>
    <div class="XR MT2 H3">提示：以上按拼音顺序排列。</div>
</template>

<script>
    import {myMongoDBPost, LocaleCompare, CloneObject} from '@/components/SharedVues/Shared0.js'
    import SiteCreate from "./SiteCreate";

    export default {
        name: "SetMySites",
        components: {SiteCreate},
        props: ['ItemStr'],
        data() {
            return {
                ItemName: this.ItemStr.split(':')[1],
                AllSites: [],
                MySites: []
            }
        },
        mounted() {
            let that = this;
            myMongoDBPost("GetValidMySites", {UserName: this.$store.state.UserName, ItemStr: this.ItemStr}, function (Docs1) {
                let MySiteShortNames = [];
                for (let i = 0, len = Docs1[0].MySites.length; i < len; i++) MySiteShortNames.push(Docs1[0].MySites[i].SiteShortName);
                myMongoDBPost("GetDocs/fysites", {'Items.ItemName': that.ItemName}, function (Docs) {//数组查询 数组元素查询 数组元素匹配
                    let Ps = [], Cs = [];
                    for (let i = 0, len = Docs.length; i < len; i++) {
                        Docs[i].SelectedBool = (MySiteShortNames.indexOf(Docs[i].SiteShortName) >= 0);//新增一个选中标志字段
                        if (Ps.indexOf(Docs[i].Province) === -1) Ps.push(Docs[i].Province), Cs.push(Docs[i].City);
                    }
                    for (let i = 0, len = Ps.length; i < len; i++) {
                        that.AllSites.push({P: Ps[i], City: Cs[i], Sites: []});
                        for (let j = 0, len = Docs.length; j < len; j++) if (Ps[i] === Docs[j].Province) that.AllSites[that.AllSites.length - 1].Sites.push(Docs[j]);
                    }
                    for (let i = 0; i < that.AllSites.length; i++) that.AllSites[i].Sites.sort(LocaleCompare('SiteShortName'));
                })
            })
        },
        methods: {
            SetMySite(index1, index2, Bool) {
                let asd = CloneObject(this.AllSites[index1].Sites[index2]);
                asd.SelectedBool = Bool;
                this.AllSites[index1].Sites.splice(index2, 1, asd);
                let aObj = {
                    UserName: this.$store.state.UserName,
                    ItemStr: this.ItemStr,
                    Bool: Bool,//如果为true,则表示设为喜好
                    SiteId: asd._id,
                    JF: 0
                };
                myMongoDBPost("UpdateMySite", aObj, function () {
                })
            }
        }
    }
</script>

<style scoped>

</style>
