<template>
    <div class="XLR YC BGDeepGreen .BDLightGray PA3 TY">
        {{CurrSiteUser.SiteName}}
        <div class="YC TW MTB2" @click="SetCancel(CurrSiteUser._id)">
            辞职注销&nbsp;<el-icon>
            <ArrowRight/>
        </el-icon>
        </div>
    </div>
    <h5 class="MP1">每周允许排课时段预设：</h5>
    <div class="XLR BottGray">
        <div class=" W16 ">
            <div class="XLR YC H6vw T1">时段
                <div>\</div>
                星期
            </div>
            <div v-for="(item,index) in MyClassTWs" :key="index">
                <div class="Cell XYC H10vw T3">
                    <div>{{item.TW1}}</div>
                    <div>{{item.TW2}}</div>
                </div>
            </div>
        </div>
        <div class="W84 ">
            <div class="XLR  H6vw BottGray">
                <div v-for="(item,index) in ['一','二','三','四','五','六','日']" :key="index">
                    <div class="Cell W12 T4">{{item}}</div>
                </div>
            </div>
            <div class="XLR" v-for="(CellR,index1) in MyClassTWs" :key="index1">
                <div v-for="(CellRC,index2) in CellR.Days" :key="index2">
                    <div class="Cell W12 H10vw" @click="CellClick(index1,index2)">
                        <div v-if="CellRC" class="FullCell BGBlue"></div>
                        <div v-if="index2>4 && !CellRC" class="FullCell BGLightGray"></div>
                        <div v-if="index2<5 && !CellRC" class="FullCell BGWhite"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="MP4">
        <div v-if="Changed" class="BD_Radius1 BGBlue T5 TW XYC H3 MT2" @click.once="ChangeKBStatus">提 交</div>
        <div v-else class="BD_Radius1 BGLightGray T5 TW XYC H3 MT2">提 交</div>
    </div>
</template>

<script>
    import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import YKQSetup0 from "./YKQSetup0";

    export default {
        name: "YKQSetup2",
        components: {YKQSetup0},
        props: ['CurrSiteUser'],
        data() {
            return {
                MyClassTWs: [],
                YkSeleI: -1,
                ShowYkSeleI: false,
                ToSetup0: false,
                Changed: true
            }
        },
        mounted() {
            let that = this;
            if (that.MyClassTWs === []) myMongoDBPost("GetDocs/SiteUsers", {
                CAdminUser: that.CurrSiteUser.CAdminUser,
                DocBool: '课程管理员'
            }, function (Docs) {  //可能是多条记录了
                if (Docs[0].KBStatus === '新建') alert('        课程管理员尚未完成课表初始化，请耐心等待一下！');
                if (Docs[0].KBStatus === '已初始化') {
                    that.MyClassTWs = Docs[0].ClassTWs;
                    that.InitMyClassTWs();
                }
            }); else {
                that.MyClassTWs = that.CurrSiteUser.ClassTWs;
                that.InitMyClassTWs();
            }
        },
        methods: {
            InitMyClassTWs() {
                let NewClassTWs = [];
                if (this.MyClassTWs[0].Days === undefined) {
                    for (let i = 0; i < this.MyClassTWs.length; i++)
                        NewClassTWs.push({
                            TW1: this.MyClassTWs[i].TW1,
                            TW2: this.MyClassTWs[i].TW2,
                            Days: [false, false, false, false, false, false, false]
                        });
                    this.MyClassTWs = NewClassTWs;
                }
            },
            CellClick(index1, index2) {
                this.MyClassTWs[index1].Days[index2] = !this.MyClassTWs[index1].Days[index2];
                myMongoDBPost("UpdateDoc/SiteUsers", {UPTJ: {_id: this.CurrSiteUser._id}, UPObj: {ClassTWs: this.MyClassTWs}}, function () {

                })
            },
            SetCancel() {
                let that = this;
                myMongoDBPost("RemoveDoc/SiteUsers", {_id: this.CurrSiteUser._id}, function () {
                    that.$emit('FromYKQSetup2', 'ToSetup0')
                })
            },
            ChangeKBStatus() {
                let that = this, Obj = {UPTJ: {_id: this.CurrSiteUser._id}, UPObj: {KBStatus: '预设完毕'}};
                myMongoDBPost("UpdateDoc/SiteUsers", Obj, function () {
                    that.$emit('FromYKQSetup2', '预设完毕')
                })
            }
        }
    }
</script>

<style scoped>
    .ML10 {
        margin-left: 10vw;
    }

    .W45 {
        width: 45vw;
        box-sizing: border-box;
    }
</style>
