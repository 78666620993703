<template>
    <div class="XLR YC BGY H4 BDLightGray" style="margin-top: -1px">&nbsp;
        <div class="TG" @click="Show = 4">泛约项目场地</div>
        <div @click="NewSite"><img :src="'UserUpload/static/After2021/GreenAddAN.png'" class="WH99"></div>&nbsp;
    </div>
    <PopTitle v-if="Show===1" Title="注册新场地(场地管理员)" @FromPopUp="Show=0">
        <SiteCreate :Recommend="false" @FromSiteCreate="Show=0"></SiteCreate>
    </PopTitle>
    <PopTitle v-if="Show===2" Title="平台管理员提取用户推荐场地信息" @FromPopUp="Show=0">
        <SitesGetTJ></SitesGetTJ>
    </PopTitle>
    <PopTitle v-if="Show===3" Title="用户向平台推荐场地" @FromPopUp="Show=0">
        <SiteCreate :Recommend="true" @FromSiteCreate="Show=0"></SiteCreate>
    </PopTitle>
    <PopTitle v-if="Show===4" Title="项目场地列表维护" @FromPopUp="Show=0">
        <SitesList></SitesList>
    </PopTitle>
</template>

<script>
    import SitesLook from "./SitesLook";
    import SiteCreate from "./SiteCreate";
    import SitesGetTJ from "./SitesGetTJ";
    import PopTitle from "../MyPopWin/PopTitle";
    import SitesList from "./SitesList";
    import PopOneAN from "../MyPopWin/PopOneAN";

    export default {
        name: "SiteManage",
        components: {PopOneAN, SitesList, PopTitle, SitesGetTJ, SiteCreate, SitesLook},
        data() {
            return {
                Role: '',
                Show: 0,
            }
        },
        mounted() {
            if (this.$store.state.UserName === this.$store.state.HTM0) this.Role = '平台管理员';
            else if (this.$store.state.UserBools.indexOf('场地管理员') >= 0) this.Role = '场地管理员';
        },
        methods: {
            NewSite() {
                if (this.Role === '场地管理员') this.Show = 1;
                else if (this.Role === '平台管理员') this.Show = 2; else this.Show = 3;
            }
        }
    }
</script>

<style scoped>

</style>