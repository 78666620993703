<template>
    <div style="height: auto;border: solid #d6d6d6;border-width: 0 0 1px 0;">
        <MyDivider :ShowStr="Title"></MyDivider>
        <div class="XLR MLR2" style="margin-top: 1mm">&nbsp;
            <div v-for="(Item,index) in Lists" :key="index">
                <transition v-if="SeleI===index">
                    <div v-if="SeleI===index" class="SCss SCss2">{{Item}}</div>
                </transition>
                <div v-else class="SCss SCss1" @click="Selected(index)">{{Item}}</div>
            </div>
                                                      &nbsp;
        </div>
    </div>
    <SideMenu :Menus="Menus" :Begin="0" @SideMenuR="SetCurrSideSele">
        <div v-for="(Item, index2) in Items[CurrSideSele].itemList2" :key="index2" class="DispIB">
            <div class="AlignC MP4 TB" @click="SetItemStr(Item)">{{Item.split(':')[1]}}</div>
        </div>
    </SideMenu>
</template>

<script>
    import SideMenu from "@/components/SharedVues/SideMenu";
    import {Compare, myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import MyDivider from "../SharedVues/MyDivider";

    export default {
        name: "SeleOneItem",
        components: {MyDivider, SideMenu},
        props: ['Title'],
        data() {
            return {
                CurrSideSele: 0,
                Lists: ['对抗竞技', '休闲娱乐', '技能服务', '特邀'],
                SeleI: 0,
                index1: 0,
                Menus: ["第一项", "第二项"],
                Items: [{itemList2: []}],//请注意这里的初始化方法
            }
        },
        mounted() {
            this.RefreshItems('对抗竞技');
        },
        methods: {
            Selected(index) {
                this.SeleI = index;
                this.CurrSideSele = 0;
                this.RefreshItems(this.Lists[index])
            },
            SetCurrSideSele(Val) {
                this.CurrSideSele = Val;
            },
            RefreshItems(Channel) {
                let that = this, aObj = {ChannelName: Channel, UserName: this.$store.state.UserName};
                myMongoDBPost("GetChannelItems", aObj, function (data) {
                    data.sort(Compare('_id'));
                    that.Items = data;
                    that.Menus = [];
                    that.IsLoading = false;
                    for (let i = 0, len = data.length; i < len; i++) that.Menus.splice(i, 1, data[i].ItemType);
                });
            },
            SetItemStr(asd) {
                this.$store.commit('ChangeCurrItemStr', asd);
                this.$emit("FromSeleOneItem", asd);
            }
        }
    }
</script>
