<template>
    <div :class="(Show>0 || DefaultItemed===1) ? 'MaskCss':''"><!--以下 是 主页显示内容 -->
        <div class="Header">
            <div class="XLR YC BGLightGray PA2">
                <img :src="'UserUpload/static/After2021/ListAN1.png'" @click="Show=1" class="WHaa">
                <div class="BoxGray1 PA3 XL YC BD_Radius1 W60" @click="Show=2">
                    <img src="../../static/img/ButtonPic/Magnifier.png" class="WH77 MR4">
                    <div class="DispIB">
                        <MyMarquee :ShowItems="['用户昵称','项目名称','模糊关键字']" Width="45"></MyMarquee>
                    </div>
                </div>
                <img src="../../static/img/ButtonPic/CustomerService.png" @click="Show=3" class="WH99 BD_Radius1 BDGreen">
                <!--‌Robots协议中的Disallow: /static/表示禁止所有搜索引擎抓取网站根目录下名为static的目录中的内容,因此
                <img :src="'static/img/SY/Map1.png'" 不被允许，但<img :src="'UserUpload/ 则没有问题-->
            </div>
            <div class="XLR MLR2" style="margin-top: 1mm">&nbsp;
                <div v-for="(M,index) in Menus" :key="index">
                    <transition v-if="SeleI===index">
                        <div v-if="SeleI===index" class="SCss SCss2">{{M}}</div>
                    </transition>
                    <div v-else class="SCss SCss1" @click="Selected(index)">{{M}}</div>
                </div> &nbsp;
            </div>
        </div>
        <div id="PageBegin" class="H6"></div>
        <div v-if="$store.state.UserName==='游客'" class="MT2 XLR YC">&nbsp;
            <SubEntrance @FromSubEntrance="ProcessFromSubEntrance"></SubEntrance>&nbsp;
        </div>
        <SeleItem v-if="SeleI===0" Channel="对抗竞技" Url="GetChannelHopeItems"></SeleItem>
        <SeleItem v-if="SeleI===1" Channel="休闲娱乐" Url="GetChannelHopeItems"></SeleItem>
        <SeleItem v-if="SeleI===2" Channel="技能服务" Url="GetChannelItems"></SeleItem>
        <SeleItem v-if="SeleI===3" Channel="特邀" Url="GetChannelItems"></SeleItem>
        <SiteManage></SiteManage>
        <div v-if="Show===-1 && DefaultItemed!==1">
            <div class="TC BD_Radius1 BGLightGray PA2">
                <el-divider> 通知 • 公告</el-divider>
                <div v-if="aTip!==null" class="XLR YC LH120 BGWhite PA2" @click="ClearTip">
                    <div class="NoWrap">
                        {{aTip.Time1}}<br>{{aTip.Time2}}
                    </div>
                    <img src="../../static/gif2/ArrowR.gif" class="W12"/>
                    <div class="TE">{{aTip.Tip}}</div>
                </div>
                <div class="TC MLR2 BottGray">
                    <div v-if="$store.state.WXId==='未关注'">
                        <img :src="'UserUpload/static/CZSM/Two9kb.jpg'" class="ML2 W26" style="float: right">
                        <p class="MR4">☞请通过微信公众号“泛约大厅”访问使用，长按右侧二维码并关注置顶。</p>
                    </div>
                    <div class="AlignM" style="line-height: 1.8rem">
                        <div class="DispIB" style="margin-left: 1.6rem;"> &nbsp;☞使用共享卡要求预存&nbsp;</div>
                        <div class="DispIB AN ANBlue" @click="Show=21">泛约币</div>
                        ；泛约币不够用时，须通过
                        <div class="DispIB AN ANGreen" @click="SetShow(23)">时间商店</div>
                        、
                        <div class="DispIB AN ANGreen" @click="SetShow(24)">视频代理</div>
                        、
                        <div class="DispIB TB" @click="Show=22">诚信担保</div>
                        、
                        对抗竞技、技能服务等平台内应用挣取，也可以直接充值。
                    </div>
                    <p>
                        ☞实名：<img src="../../static/img/IDed.png" width="20">=已认证&nbsp;
                        <img src="../../static/img/ID2.png" width="20">=认证失败&nbsp;
                        <img src="../../static/img/ID3.png" width="20">=待认证
                    </p>
                    <p>
                        ☞本平台当前正在天津通过乒乓球项目试用中，如有问题会借助“泛约天津乒乓球友群”发布信息，因此建议新注册用户通过手机微信号：18622582303，加入本群。
                    </p>
                </div>
                <!--NewsToMe v-if="$store.state.UserName!=='游客'"></NewsToMe-->
            </div>
            <br>
            <div class="AlignC">&nbsp;&nbsp;
                                ★★★<a target="_" href="https://beian.miit.gov.cn/">津ICP备16007353号</a>★★★
                                &nbsp;&nbsp;
            </div>
            <div class="MT2 TE AlignC" style="font-size:48px"> ️🌏️</div>
            <br><br><br><br><br><br>
        </div>
        <div v-if="$store.state.UserName!=='游客'" class="MyFooter">
            <div class="PA3 XLR YC NoWrap TW">
                <div class="ANGray BD_Radius1 WHbb  XYC LH120 T2" @click="Show=11">
                    寻人<br>启示
                </div>
                <div class="ANGray BD_Radius1 WHbb  XYC LH120 T2" @click="Show=10">
                    共享<br>相册
                </div>
                
                <div class="AlignC T3 TB">
                    <div class="XL YC">
                        <HeadShot></HeadShot>
                        <div>
                            <img v-if="$store.state.Certified==='成功'" src="../../static/img/IDed.png" width="20">
                            <div v-else>
                                <img v-if="$store.state.Certified==='失败'" src="../../static/img/ID2.png" width="20">
                                <img v-else src="../../static/img/ID3.png" width="20">
                            </div>
                            <div class="TE T1 NoWrap">&nbsp;{{$store.state.ZX}}&nbsp;</div>
                        </div>
                    </div>
                    {{$store.state.UserName}}
                </div>
                <div class="ANGray BD_Radius1 WHbb  XYC LH120 T2" @click="Show=12">
                    泛约<br>帮办
                </div>
                <div class="ANGray BD_Radius1 WHbb  XYC LH120 T2" @click="Show=13">
                    设置<br>管理
                </div>
            </div>
        </div>
    </div>
    <PopTitle v-if="Show===1" Title="我的常用邀约模板" @FromPopUp="Show=-1">
        <MBSelect></MBSelect>
    </PopTitle>
    <FYSearch v-if="Show===2" @FromFYSearch="Show=-1"></FYSearch>
    <PopOneAN v-if="Show===3" @FromPopUp="Show=-1">
        <CustomerService></CustomerService>
    </PopOneAN>
    <PopBott v-if="DefaultItemed===1">
        <SeleOneItem Title="请先选择缺省打开项目" @FromSeleOneItem="UpdateDefaultItem"></SeleOneItem>
    </PopBott>
    <PopTitle v-if="Show===4 && DefaultItemed===2" Title="请选择一个项目场地" @FromPopUp="Show=-1">
        <SDPZ :ItemStr="$store.state.DefaultItem"></SDPZ>
    </PopTitle>
    <PopMenu v-if="Show === 5 && DefaultItemed===2" :Menus="Menus2" :BeginN="0" @FromPopMenu="PopMenuVal">
        <XMYY v-if="SelectedN===0" :ItemStr="$store.state.DefaultItem"></XMYY>
        <ZY v-if="SelectedN===1"></ZY>
        <BY v-if="SelectedN===2"></BY>
    </PopMenu>
    <PopBlank v-if="Show === 31">
        <ZY @FromZY="Show=-1"></ZY>
    </PopBlank>
    <PopBlank v-if="Show === 32">
        <BY @FromBY="Show=-1"></BY>
    </PopBlank>
    <PopTitle v-if="Show===33" Title="历史邀约订单" @FromPopUp="Show=-1">
        <OpenHistoryZY :CurrYY="CurrYY"></OpenHistoryZY>
    </PopTitle>
    <PopTitle v-if="Show===34" Title="历史邀约订单" @FromPopUp="Show=-1">
        <OpenHistoryBY :CurrYY="CurrYY"></OpenHistoryBY>
    </PopTitle>
    <PopOneAN v-if="Show===6 && DefaultItemed===2" @FromPopUp="Show=-1">
        <DLQ :ItemStr="$store.state.DefaultItem"></DLQ>
    </PopOneAN>
    <PopOneAN v-if="Show===7 && DefaultItemed===2" @FromPopUp="Show=-1">
        <GXCard :ItemStr="$store.state.DefaultItem"></GXCard>
    </PopOneAN>
    <PopOneAN v-if="Show===8 && DefaultItemed===2" @FromPopUp="Show=-1">
        <ActMain :ItemStr="$store.state.DefaultItem"></ActMain>
    </PopOneAN>
    <PopOneAN v-if="Show===81 " @FromPopUp="Show=-1">
        <ActMain :ItemStr="ItemStr"></ActMain>
    </PopOneAN>
    <PopBlank v-if="Show===9 && DefaultItemed===2">
        <YKQ :ItemStr="$store.state.DefaultItem" @FromYKQ="Show=-1"></YKQ>
    </PopBlank>
    <PopOneAN v-if="Show===10" @FromPopUp="Show=-1">
        <GXXC></GXXC>
    </PopOneAN>
    <PopTitle v-if="Show===11" Title="寻人启事=快捷找人" @FromPopUp="Show=-1">
        <KJZR></KJZR>
    </PopTitle>
    
    <PopTitle v-if="Show===12" Title="请把困难换算成泛约币" @FromPopUp="Show=-1">
        <FYBB></FYBB>
    </PopTitle>
    
    <PopTitle v-if="Show===13" Title="设置管理" @FromPopUp="Show=-1">
        <SetupMain @FromSetupMain="Show=-1"></SetupMain>
    </PopTitle>
    
    
    <PopTitle v-if="Show===15" Title="泛约项目场地列表" @FromPopUp="Show=-1">
        <SitesList></SitesList>
    </PopTitle>
    
    <PopTitle v-if="Show===16" Title="授权操作 • 用户注销" @FromPopUp="Show=-1">
        <QXZG></QXZG>
        <QuitFY v-if="$store.state.UserName!=='游客'"></QuitFY>
    </PopTitle>
    
    <!--以下来自微信链接-->
    <PopTitle v-if="Show===19" Title="操作说明" @FromPopUp="Show=-1">
        <CZSM></CZSM>
    </PopTitle>
    
    <PopTitle v-if="Show===20" :Title="'泛约共享卡场地：'+GXCardObj.SiteShortName" Pos="ltr" @FromPopUp="Show=-1">
        <GXCardOpen :Id="GXCardObj.Id" :aLinkKey="GXCardObj.SiteShortName"
                    :ItemStr="GXCardObj.ItemStr" :CardType="GXCardObj.CardType" @GXCardOpen="Refresh()"></GXCardOpen>
    </PopTitle>
    
    <PopTitle v-if="Show===21" :Title="$store.state.UserName+'的泛约币'" @FromPopUp="Show=-1">
        <ZXGL></ZXGL>
    </PopTitle>
    <PopTitle v-if="Show===22" Title="诚信担保" @FromPopUp="Show=-1">
        <CXDB></CXDB>
    </PopTitle>
    <PopTitle v-if="Show===23" Title="时间交易步骤规则" @FromPopUp="Show=-1">
        <TimeShop></TimeShop>
    </PopTitle>
    <PopOneAN v-if="Show===24" @FromPopUp="Show=-1">
        <SPDL></SPDL>
    </PopOneAN>
    <PopTitle v-if="Show===25" Title="缘分的窗口" @FromPopUp="Show=-1">
        <OpenOneQY :CallFrom="QYCallFrom" :Msg="QYDoc" @OpenOneQY="Show=-1"></OpenOneQY>
    </PopTitle>
    <PopTitle v-if="Show===26" :Title="'您正在访问“'+FindedUser+'”的用户空间'" @FromPopUp="Show=-1">
        <OpenUser :He="FindedUser"></OpenUser>
    </PopTitle>
    <PopTitle v-if="Show===27" :Title="FindedUser+'的时间商品柜台'" @FromPopUp="Show=-1">
        <OpenUser2 :He="FindedUser"></OpenUser2>
    </PopTitle>
</template>

<script>
    import {UrlParse, FillUserlogs, myMongoDBPost} from './SharedVues/Shared0.js'
    import MyDivider from "./SharedVues/MyDivider";
    import NewsToMe from "./FrontPage/NewsToMe";
    import FYSearch from "./FrontPage/FYSearch";
    import MBSelect from "./FrontPage/MBSelect";
    import PopOneAN from "./MyPopWin/PopOneAN";
    import FJXX from "./SetupManage/FJXX";
    import DLandReg from "./SetupManage/DLandReg";
    import QXZG from "./SetupManage/QXZG";
    import QuitFY from "./SetupManage/QuitFY";
    import SitesList from "./M4_Sites/SitesList";
    import FYDT from "./SetupManage/FYDT";
    import SubEntrance from "./SharedVues/SubEntrance";
    import PopTitle from "./MyPopWin/PopTitle";
    import SeleItem from "./FrontPage/SeleItem";
    import GXCardOpen from "./GXCardsandZXGL/GXCardOpen";
    import MyMarquee from "./SharedVues/MyMarquee";
    import SetupMain from "./SetupManage/SetupMain";
    import DLQ from "./DLQ/DLQ";
    import ActMain from "./M3_Acts/ActMain";
    import YKQ from "./YKQ/YKQ";
    import GXCard from "./GXCardsandZXGL/GXCard";
    import PopUpDown from "./MyPopWin/PopUpDown";
    import SeleOneItem from "./FrontPage/SeleOneItem";
    import KJZR from "./QYs/KJZR";
    import FYBB from "./QYs/FYBB";
    import PopMenu from "./MyPopWin/PopMenu";
    import SiteManage from "./M4_Sites/SiteManage";
    import GXXC from "./GXXCandSPDL/GXXC";
    import XMYY from "./ListYY/XMYY";
    import ZY from "./ListYY/ZY";
    import BY from "./ListYY/BY";
    import CZSM from "./FrontPage/CZSM";
    import PopBlank from "./MyPopWin/PopBlank";
    import PopBott from "./MyPopWin/PopBott";
    import ShowPFromThumb from "./MyPVTs/ShowPFromThumb";
    import ShowUpdateP from "./MyPVTs/ShowUpdateP";
    import HeadShot from "./SharedVues/HeadShot";
    import SDPZ from "./FrontPage/SDPZ";
    import CustomerService from "./FrontPage/CustomerService";
    import ZXGL from "./GXCardsandZXGL/ZXGL";
    import SPDL from "./GXXCandSPDL/SPDL";
    import CXDB from "./FrontPage/CXDB";
    import TimeShop from "./FrontPage/TimeShop";
    import OpenHistoryZY from "./ListYY/OpenHistoryZY";
    import OpenHistoryBY from "./ListYY/OpenHistoryBY";
    import OpenOneQY from "./QYs/OpenOneQY";
    import OpenUser from "./FrontPage/OpenUser";
    import OpenUser2 from "./FrontPage/OpenUser2";

    export default {
        name: "FrontPage",
        components: {
            OpenUser2,
            OpenUser,
            OpenOneQY,
            OpenHistoryBY,
            OpenHistoryZY,
            TimeShop,
            CXDB,
            SPDL,
            ZXGL,
            CustomerService,
            SDPZ,
            HeadShot,
            ShowUpdateP,
            ShowPFromThumb,
            PopBott,
            PopBlank,
            CZSM,
            BY,
            ZY,
            XMYY,
            GXXC,
            SiteManage,
            PopMenu, KJZR, FYBB,
            SeleOneItem,
            PopUpDown,
            GXCard,
            YKQ,
            ActMain,
            DLQ,
            SetupMain,
            MyMarquee,
            GXCardOpen,
            SeleItem,

            MyDivider,

            SubEntrance,
            FYDT,
            SitesList,
            QuitFY,
            QXZG,
            DLandReg,
            FJXX,
            PopOneAN,
            MBSelect,
            FYSearch,
            PopTitle,
            NewsToMe
        },
        data() {
            return {
                aTip: null,
                Menus: ['对抗竞技', '休闲娱乐', '技能服务', '特邀'],
                Menus2: ["邀约发起", "主约信息", "被约信息"],
                Show: -1,
                DefaultItemed: 0,
                Show4: false,
                Show5: false,
                SeleI: -1,
                BeginVal: 0,
                SelectedN: 0,
                XYZ: {},
                InputI: -1,
                SStr: '',
                ItemStr: '',
                WXLinkPic: '',
                WXLinkVideo: '',
                WXLinkText: '',
                GXCardObj: {Id: '', SiteShortName: '', ItemStr: '', CardType: ''},
                CurrYY: null,

                QYCallFrom: '',
                QYDoc: {},

                FindedUser: ''
            }
        },
        watch: {
            Show(Val) {
                if (Val === -1) document.getElementById('TitleId').innerText = '泛约活动服务平台 > 首页';
                else document.getElementById('TitleId').innerText = this.$store.state.UserName + "/" + this.$store.state.ZX + "个币";
            }
        },
        mounted() {
            let that = this;

            //容错处理
            let asd = this.$store.state.FYArea.split(":");
            if (['N', 'P', 'C', 'D'].indexOf(asd[0]) === -1 || asd[1] === undefined) this.$store.commit("ChangeFYArea", 'P:天津:C:D');
            this.SeleI = 0;
            console.log("FrontPageMounted");

            this.Show = -1;
            let LandTo = this.$store.state.LandTo;//凡是来自微信的访问都会有LandTo，来自浏览器的访问则LandTo==undefined
//alert(LandTo)
            if (this.$store.state.UserName !== '游客') {//以下来自首页自定义菜单
                if (LandTo.indexOf('MBYY') >= 0) this.Show = 1;//常用邀约
                if (LandTo.indexOf('SearchYY') >= 0) this.Show = 2;//自定义菜单过来的尊享管理
                let ListYY = LandTo.indexOf('ListYY') >= 0, DLQ = LandTo.indexOf('DLQ') >= 0, GXCard = LandTo.indexOf('FYGXK') >= 0,
                    YKQ = LandTo.indexOf('YKQ') >= 0, SGQ = LandTo.indexOf('SGQ') >= 0, SDPZ = LandTo.indexOf('SDPZ') >= 0;
                if (ListYY || DLQ || GXCard || YKQ || SGQ || SDPZ) {
                    this.DefaultItemed = (this.$store.state.DefaultItem === '' || this.$store.state.DefaultItem === undefined) ? 1 : 2;
                    if (SDPZ) this.Show = 4;//时间地点碰撞
                    if (ListYY) this.Show = 5;//列表式邀约
                    if (DLQ) this.Show = 6;//打擂器
                    if (GXCard) this.Show = 7;//共享卡
                    if (SGQ) this.Show = 8;//赛事管理器
                    if (YKQ) this.Show = 9;//约课器
                    if (this.DefaultItemed === 2) {
                        document.getElementById('TitleId').innerText = '项目 > ' + this.$store.state.DefaultItem;
                        that.$store.commit("ChangeCurrItemStr", this.$store.state.DefaultItem);
                    }
                }

                if (LandTo.indexOf('GXXC') >= 0) this.Show = 10;//共享相册
                if (LandTo.indexOf('XRQS') >= 0) this.Show = 11;//寻人启事
                if (LandTo === 'FYBB') this.Show = 12;//泛约帮办
                if (LandTo.indexOf('MySet') >= 0) this.Show = 13;//设置
                if (LandTo.indexOf('XMCD') >= 0) this.Show = 15;//项目场地
                if (LandTo.indexOf('QXZG') >= 0) this.Show = 16;//权限资格

                //以下来自微信链接
                if (LandTo === 'ZXGL') this.Show = 21;//尊享管理
                if (LandTo === 'ZY' || LandTo === 'ZY2') this.Show = 31;//主约信息
                if (LandTo === 'BY' || LandTo === 'BY2') this.Show = 32;//被约信息
                if (LandTo === 'QY' || LandTo === 'FYBBSendMsg') myMongoDBPost("GetDocs/QYs", {_id: UrlParse().QYId}, function (Docs) {
                    that.QYCallFrom = UrlParse().CallFrom;
                    that.QYDoc = Docs[0];
                    that.Show = 25;
                });
                if (LandTo.indexOf('UserName2') >= 0) this.FindedUser = LandTo.split("Name2")[1], this.Show = 27;
                else if (LandTo.indexOf('UserName') >= 0) this.FindedUser = LandTo.split("Name")[1], this.Show = 26;
                if (LandTo === 'HistoryZY') myMongoDBPost("GetDocs/yy-" + UrlParse().ItemCode, {_id: UrlParse().CurrYYId}, function (Docs) {
                    that.CurrYY = Docs[0];
                    that.Show = 33;
                }); //查看一条历史主约信息
                if (LandTo === 'HistoryBY') myMongoDBPost("GetDocs/yy-" + UrlParse().ItemCode, {_id: UrlParse().CurrYYId}, function (Docs) {
                    that.CurrYY = Docs[0];
                    for (let i = 0; i < Docs[0].by.length; i++)
                        if (Docs[0].by[i]._id === that.$store.state.UserName) that.CurrYY.by = Docs[0].by[i];
                    that.Show = 34;
                }); //查看一条历史被约信息
                if (LandTo.indexOf("Act_") >= 0) {
                    that.ItemStr = LandTo.split("Act_")[1];
                    that.$store.commit("ChangeCurrItemStr", that.ItemStr);
                    that.Show = 81;
                }//参加比赛
                myMongoDBPost("GetDocs/fyuser0", {UserName: this.$store.state.UserName}, function (Docs) {
                    if (LandTo.indexOf('REG') >= 0) {
                        if (Docs[0]) alert('        您已注册过。若要再注册，须先申请注销当前用户。'); else that.$emit('FromFrontPage', 'ToReg');
                    }
                    if (LandTo.indexOf('Login') >= 0) {
                        if (Docs[0]) alert('        自动登录，请开始您的泛约体验之旅吧。'); else that.$emit('FromFrontPage', 'ToLogin');
                    }
                    if (Docs[0] && Docs[0].XYZ && Docs[0].XYZ.Tip) that.aTip = Docs[0].XYZ;
                })
                if (LandTo.indexOf('CZSM') >= 0) this.Show = 19;//操作说明

                if (LandTo.split("TJXM")[0] === "WXInput") {　//微信里输入了项目类型名称时
                    myMongoDBPost("GetItemStrByItemCode", {ItemCode: LandTo.split("TJXM")[1]}, function (data) {
                        that.ItemStr = data;
                        that.$store.commit("ChangeCurrItemStr", that.ItemStr);
                        that.$store.commit('ChangeDefaultItem', data.DefaultItem);
                        this.DefaultItemed = 2;
                        that.Show = 5;
                    });
                }
                if (LandTo.split("XYXM")[0] === "WXInput") {　//微信里输入了喜好项目名称时
                    that.ItemStr = LandTo.split("XYXM")[1];
                    that.$store.commit("ChangeCurrItemStr", that.ItemStr);
                    that.$store.commit('ChangeDefaultItem', data.DefaultItem);
                    this.DefaultItemed = 2;
                    that.Show = 5;
                }//直接邀约
            } else {
                if (LandTo.indexOf('REG') >= 0) that.$emit('FromFrontPage', 'ToReg');
                if (LandTo.indexOf('Login') >= 0) that.$emit('FromFrontPage', 'ToLogin');
            }
        },
        methods: {
            SetShow(Show) {
                this.Show = Show;
                document.getElementById('PageBegin').scrollIntoView({behavior: 'smooth'});
            },
            ClearTip() {
                let that = this;
                myMongoDBPost("UpdateDoc/fyuser0", {
                    UPTJ: {UserName: this.$store.state.UserName},
                    UPObj: {XYZ: {}}
                }, function () {
                    that.aTip = null;
                    //that.Show = 4;
                })
            },
            ProcessFromSubEntrance(Val) {
                console.log("RefreshFrontPage=", Val);
                if (Val === 'ToReg') this.$emit('FromFrontPage', 'ToReg');
                if (Val === 'ToLogin') this.$emit('FromFrontPage', 'ToLogin');
            },
            Selected(index) {
                this.SeleI = index;
                this.Show4 = false;
                this.Show = -1;
                FillUserlogs(this, this.Menus[index]);
            },
            PopMenuVal(Val) {
                if (Val === -1) this.Show = -1; else this.SelectedN = Val;
            },
            UpdateDefaultItem(Val) {
                let that = this;
                this.Show = -1;
                this.SeleI = 3;
                that.$store.commit('ChangeDefaultItem', Val);
                that.DefaultItemed = 2;
                myMongoDBPost("AddbySetsItem", {UserName: this.$store.state.UserName, ItemStr: Val}, function () {
                    that.SeleI = 0;
                });
                myMongoDBPost("UpdateDoc/fyuser0", {UPTJ: {UserName: this.$store.state.UserName}, UPObj: {DefaultItem: Val}}, function () {

                });
            },

            MyInputVal(Val) {
                let that = this;
                if (Val && this.InputI === 1 && Val !== '') {
                    this.SStr = Val;
                    myMongoDBPost("GetDocs/fyuser0", {UserName: {$regex: Val}}, function (Docs) {
                        that.UserNames = [];
                        let len = Math.min(Docs === undefined ? 0 : Docs.length, 50);
                        for (let i = 0; i < len; i++)
                            if (Docs[i].UserName !== that.$store.state.UserName) that.UserNames.push({
                                UserName: Docs[i].UserName,
                                UserLogo: Docs[i].UserLogo
                            });
                        myMongoDBPost("GetDocsByItemName", {ItemName: Val}, function (data) {
                            that.aList = data;
                            that.Show = 2;
                        })
                    });
                }
                this.InputI = 0;
            }
        }
    }
</script>

<style scoped>
    .demo-tabs > .el-tabs__content {
        padding: 32px 0 1px 0;
        color: #6b778c;
        }
    
    .RightTop0 {
        display: inline-block;
        position: absolute;
        width: 0;
        height: 0
        }
    
    .RightTop1 {
        position: relative;
        height: 15px;
        top: -1.5rem;
        left: 0.6rem;
        overflow: visible;
        white-space: nowrap;
        }
    
    .MyFooter {
        position: fixed;
        bottom: 0;
        width: 100vw;
        min-height: 2.8rem;
        border: solid #bbbbbb;
        border-width: 1px 0 0 0;
        background-color: #e6e6e6;
        }
</style>

