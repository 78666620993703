<template>
  <div>
    <div v-if="IsLoading">IsLoading...</div>
    <div v-else>
      <table  style="background-color:#fff;">
        <thead>
        <tr class="TF">
          <th>itemChannel</th>
          <th>ItemType</th>
          <th>itemList</th>
          <th>详情</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(Item,index) in Items" :key="index">
          <td>{{Item.itemChannel}}</td>
          <td>{{Item.ItemType}}</td>
          <td>{{Item.itemList.length}}个</td>
          <td>
            <div class="TG T5" @click="SeleI=index"> ></div>
          </td>
        </tr>
        </tbody>
      </table>
      <br>
      <div class="MP4 XLR YC BoxDot">
        <div>新项目类型：<input type="text" class="MP4 W50" v-model="NewItemType"/></div>
        <div class="TB" @click="Append0()">追加</div>
      </div>
      <p>特别注意：</p>
      <p>1.增加新的项目类型后，还需修改Shared0.js中的GetItemType()</p>
      <p>2.增加新的项目后，还需修改相应的图标</p>
      <PopTitle v-if="SeleI !== -1" :Title="Items[SeleI].itemChannel+':'+Items[SeleI].ItemType" @FromPopUp="SeleI=-1">
        <div class="MP4 T3">
          <div v-for="(ITS,index2) in Items[SeleI].itemList" :key="index2" class="DispIB">
            <input type="text" class="W28 MT2 " v-model="Items[SeleI].itemList[index2]"
                   @blur="ReSet(SeleI,index2,ITS)"/>
          </div>
        </div>
        <div class="MP4 XLR YC BoxDot">
          <div>新项目：<input type="text" class="MP4 W50" v-model="NewItemStr"/></div>
          <div class="TB" @click="Append(SeleI,NewItemStr)">确认追加</div>
        </div>
        <div class="MP4 XLR YC BoxDot">
          <div>项目代码：<input type="text" class="MP4 W28" v-model="ItemCode"/></div>
          <div class="TB" @click="Delete(SeleI,ItemCode)">确认删除</div>
        </div>
        <div class="MP4 XLR YC BoxDot">
          <div>项目代码：<input type="text" class="MP4 W16" v-model="ItemCode2"/></div>
          <div>移至：<input type="text" class="W16" v-model="ItemCode"/></div>
          <div class="TB" @click="Move(SeleI,ItemCode,ItemCode2)">之前</div>
        </div>
        <div v-if="Items[SeleI].ItemType.charAt(1)==='1'" class="MP4">达标项目排序处理,直接统一放到function setendBool</div>
      </PopTitle>
    </div>
  </div>
</template>

<script>
  import {myMongoDBPost,  GetC_N, Compare} from '@/components/SharedVues/Shared0.js'
  import PopTitle from "@/components/MyPopWin/PopTitle";

  export default {
    name: "HTItems",
    components: { PopTitle},
    data() {
      return {
        Items: [],
        IsLoading: true,
        SeleI: -1,
        NewItemStr: '',
        NewItemType: '',
        ItemCode: '',
        ItemCode2: ''
      }
    },
    mounted() {
      let that = this;
      myMongoDBPost("GetDocs/fyitems", {}, function (data) {
        data.sort(Compare('ItemType'));
        that.Items = data;

        that.IsLoading = false;
        console.log(that.Items)
      });
    },
    methods: {
      Append0() {
        if (this.NewItemType.indexOf(":") === -1 || GetC_N(this.NewItemType) === -1) return alert( '格式错误!');
        let that = this, aObj = {
          _id: this.Items.length + Math.floor(Math.random() * 100),
          itemChannel: GetC_N(this.NewItemType),
          ItemType: this.NewItemType,
          itemList: []
        };
        myMongoDBPost("PutDoc/fyitems", aObj, function (data3) {
          that.Items.push(aObj);
        })
      },
      Append(SeleI, NewItemStr) {
        if (NewItemStr.indexOf(":") === -1) return alert( '格式错误!');
        this.Items[SeleI].itemList.push(NewItemStr)
        myMongoDBPost("UpdateDoc/fyitems", {UPTJ: {_id: this.Items[SeleI]._id},UPObj: {itemList: this.Items[SeleI].itemList}}, function () {

        })
      },
      Delete(SeleI, ItemCode) {
        for (let i = 0, len = this.Items[SeleI].itemList.length; i < len; i++)
          if (this.Items[SeleI].itemList[i].split(":")[0] === ItemCode) {
            this.Items[SeleI].itemList.splice(i, 1);
            break
          }
        myMongoDBPost("UpdateDoc/fyitems", {PTJ: {_id: this.Items[SeleI]._id},UPObj: {itemList: this.Items[SeleI].itemList}}, function () {

        })
      },
      Move(SeleI, ItemCode, ItemCode2) {
        let asd = "", asd2 = [];
        for (let i = 0, len = this.Items[SeleI].itemList.length; i < len; i++)
          if (this.Items[SeleI].itemList[i].split(":")[0] === ItemCode2) {
            asd = this.Items[SeleI].itemList[i];
            this.Items[SeleI].itemList.splice(i, 1);
            break
          }
        for (let i = 0, len = this.Items[SeleI].itemList.length; i < len; i++) {
          if (this.Items[SeleI].itemList[i].split(":")[0] === ItemCode) asd2.push(asd);
          asd2.push(this.Items[SeleI].itemList[i])
        }
        this.Items[SeleI].itemList = asd2;
        myMongoDBPost("UpdateDoc/fyitems", {UPTJ: {_id: this.Items[SeleI]._id},UPObj: {itemList: this.Items[SeleI].itemList} }, function () {

        })
      },
      ReSet(SeleI, index2, ITS) {
        console.log(SeleI, index2, ITS, this.Items[SeleI].itemList[index2]);
        myMongoDBPost("UpdateDoc/fyitems", {
          UPTJ: {_id: this.Items[SeleI]._id},
          UPObj: {itemList: this.Items[SeleI].itemList}
        }, function () {

        })
      }
    }
  }
</script>

<style scoped>
  .W28 {
    width:28vw
  }
  .W16 {width:16vw}
  .W50 {
    width:45vw
  }
</style>
