<template>
    <div class="BottGray">
        <div class="XR MP4 YC TF">
            区域范围：
            <FYArea @FromFYArea="Refresh0"></FYArea>
        </div>
    </div>
    <SideMenu v-if="BeginN>-1" :Menus="SideMenus" :Begin="BeginN" @SideMenuR="SetCurrSideSele">
        <div v-if="Laoded">
            <div v-if="QYs.length===0" class="AlignC TF">( 暂无信息 )</div>
            <div v-else v-for="(QY,index) in QYs" :key="index">
                <div :class="'PA2 XLR YC '+(QY.UserName===$store.state.UserName ? 'BGLightGreen':'')">
                    <div>
                        {{index+1}}.{{QY.Title}}<sup class="TE T2">{{QY.LookedTimes}}</sup><sub class="TE T2">{{QY.DYn}}</sub>
                    </div>
                    <div v-if="QY.UserName===$store.state.UserName" class="TE BDCircle BoxBR WH77 XYC"
                         @click="Delete(QY._id)">删
                    </div>
                    <div class="W6 AlignC" @click="SeleI=index,Laoded=false">
                        <el-icon><ArrowRight/></el-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="XR YC MP4 TG" @click="SetShow1">
            <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">&nbsp;发布信息
        </div>
    </SideMenu>
    <MyDivider ShowStr="缘是天意 份乃人为"></MyDivider>
    <div v-if="$store.state.UserName !== '游客'">
        <div v-for="(SM,index) in SideMenus" :key="index">
            <div v-if="BeginN===index && Laoded">
                <WXLink v-if="Channel==='缘分空间'" WText="不经意间在这里留下的一条信息，就让你的贵人或情投意合的人看到了你，这就是缘分。"
                        :WLandTo="'YYFWN9YYFWN'+NSeleM.SeleM+'YYFWN'+BeginN+'YYFWN'+$store.state.FYArea"
                        WTitle="寻找一个人才或期望中的人是那么难。也许..." Pic="UserUpload/static/WXLink/SJZR.png"></WXLink>
                <WXLink v-else WTitle="泛约社交平台➤快捷找人" :WText="FYAreaStr+'➤'+Channel+'➤'+TopKey+'➤'+SideMenus[BeginN]"
                        :WLandTo="'YYFWN'+NSeleM.N+'YYFWN'+NSeleM.SeleM+'YYFWN'+BeginN+'YYFWN'+$store.state.FYArea"
                        Pic="UserUpload/static/WXLink/SJZR.png"></WXLink>
            </div>
        </div>
    </div>
    <CreateQY v-if="Show1" :Msg="Curr" @CreateQY="Refresh1"></CreateQY>
    <PopTitle v-if="!Laoded && SeleI !== -1" :Title="QYs[SeleI].Title" @FromPopUp="Laoded=true,SeleI=-1">
        <OpenOneQY CallFrom="QYs" :Msg="QYs[SeleI]" @OpenOneQY="Refresh2"></OpenOneQY>
    </PopTitle>
</template>

<script>
    import SideMenu from "@/components/SharedVues/SideMenu";
    import {Compare, myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import CreateQY from "./CreateQY";
    import OpenOneQY from "./OpenOneQY";
    import MyInput from "../SharedVues/MyInput";
    import ShowUpdatePVT from "../MyPVTs/ShowUpdatePVT";
    import FYArea from "../SharedVues/FYArea";
    import WXLink from "../IndependentVues/WXLink";
    import MyDivider from "../SharedVues/MyDivider";

    export default {
        name: "QYsSele",
        components: {
            MyDivider,
            WXLink,
            FYArea,
            ShowUpdatePVT,
            MyInput,
            OpenOneQY,
            CreateQY,
            PopTitle,
            SideMenu
        },
        props: ['Channel', 'TopKey', 'NSeleM', 'SideMenus'],
        data() {
            return {
                Laoded: false,
                BeginN: -1,
                Show1: false,
                SeleI: -1,
                Curr: {Channel: this.Channel, TopKey: this.TopKey, SideKey: this.SideMenus[0], EndBool: 1},
                QYs: [],
                FYAreaStr: ''
            }
        },
        mounted() {
            let LandTo = this.$store.state.LandTo;
            if (LandTo && LandTo.split("YYFWN").length > 3) {
                this.$store.commit("ChangeFYArea", LandTo.split("YYFWN")[4]);
                this.BeginN = parseInt(LandTo.split("YYFWN")[3]);
                this.Curr.SideKey = this.SideMenus[this.BeginN];
            } else this.BeginN = 0;
        },
        methods: {
            SetCurrSideSele(Val) {
                this.BeginN = Val;
                this.Curr.SideKey = this.SideMenus[Val];
                this.QYsRefresh();
            },

            GetTJ() {
                let Asd = this.$store.state.FYArea;
                let TJ = {
                    Channel: this.Curr.Channel,
                    TopKey: this.Curr.TopKey,
                    SideKey: this.Curr.SideKey,
                    Province: Asd.split(":")[1],
                    City: Asd.split(":")[2],
                    DC: Asd.split(":")[3],
                    EndBool: 1
                };
                if (Asd.split(":")[0] === 'N') {
                    delete TJ.Province;
                    delete TJ.City;
                    delete TJ.DC;
                    this.FYAreaStr = '全国';
                } else if (Asd.split(":")[0] === 'P') {
                    delete TJ.City;
                    delete TJ.DC;
                    this.FYAreaStr = TJ.Province;
                } else if (Asd.split(":")[0] === 'C') {
                    delete TJ.DC;
                    this.FYAreaStr = TJ.Province + TJ.City;
                } else this.FYAreaStr = TJ.Province + TJ.City + TJ.DC;
                return TJ
            },
            QYsRefresh() {
                let that = this;
                this.Laoded = false;
                that.QYs = [];
                console.log(this.GetTJ())
                myMongoDBPost("GetDocs/QYs", this.GetTJ(), function (Docs) {//始终按发布时认缴的泛约币数量进行排序
                    for (let i = 0; i < Docs.length; i++) that.QYs.push(Docs[i]);
                    that.QYs.sort(Compare('ZXn')).reverse();
                    console.log(that.QYs);
                    that.Laoded = true;
                })
            },
            Delete(Id) {
                let that = this;
                myMongoDBPost("RemoveDoc/QYs", {_id: Id}, function () {
                    myMongoDBPost("RemoveDoc/QYs", {QYId: Id}, function () {
                        that.QYsRefresh();
                    })
                })
            },//bug:删除照片和视频问题
            SetShow1() {
                let that = this;
                let TJ = this.GetTJ();
                TJ.UserName = this.$store.state.UserName;
                if (that.$store.state.UserName === '游客')
                    alert('操作无效!，游客用户无权发布!');
                else myMongoDBPost("GetDocs/QYs", TJ, function (Docs) {
                    if (Docs.length > 0) alert('发布无效!，同一分类下，每个用户只允许发布一次。'); else that.Show1 = true;
                });
            },
            Refresh0(Val) {
                this.FYAreaStr = Val;
                this.QYsRefresh();
            },
            Refresh1() {
                this.Show1 = false;
                this.QYsRefresh();
            },
            Refresh2() {
                this.SeleI = -1;
                this.QYsRefresh();
            }
        }
    }
</script>
