<template>
    <div :class="Show>0 ? 'MaskCss':''">
        <div class="XLR YC TG BottGray ">&nbsp;
            <div></div>
            <el-radio-group v-model="selectedButton" size="small" @change="SetCurrEndBool(selectedButton)">
                <el-radio :value="1" border>当前</el-radio>
                <el-radio :value="2" border>过期</el-radio>
            </el-radio-group>
            <div @click="SetShow1" class="AlignC PA3">
                <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">
            </div>
        </div>
        <div class="XL YC BottO PA3">
            活动范围：
            <FYArea @FromFYArea="SetActArea"></FYArea>
        </div>
        <div v-if="Show===-1 && Acts.length>0" class="MT2 ">
            <div v-for="(Act,index) in Acts" :key="index" class="MP4 BottGray">
                <div v-if="$store.state.UserName===$store.state.HTM0 || $store.state.UserName===Act.UserName"
                     class="XLR YC">
                    <div class="TE BDCircle BoxBR WH77 XYC" @click="Delete(Act._id,index,Act.Title)">删</div>
                    <div class="TE BDCircle BoxBR WH77 XYC" @click="SeleI = index,Show=2" style="margin: 0 1.5mm">改</div>
                    <div class="ANBlue BD_Radius1 WHbb  XYC LH120 T2" @click="SeleI=index,Show=4">活动<br>规则</div>
                    &nbsp;
                    <div class="W75 TG" @click="SetSeleI(index)">
                        {{Act.Title}}<!--sup class="T1 TE">{{Act.LookedTimes}}</sup-->
                        <div v-if="Act.HoldStyle==='封闭式'" class="TF T2">内含多个子活动</div>
                        <div v-else class="TF T2">类型:{{Act.ActStyle}}</div>
                    </div>
                </div>
                <div v-else>
                    <div v-if="Act.Scale==='凭密参加' || Act.HoldStyle==='封闭式' && Act.ScaleVal!==null" class="XLR YC">
                        <div class="XLR YC">
                            <div class="ANBlue BD_Radius1 WHbb  XYC LH120 T2" @click="SeleI=index,Show=3">活动<br>规则</div>&nbsp;
                            <div :class="Act.HoldStyle==='封闭式' ? 'TB' :'TG'">
                                {{Act.Title}}<!--sup class="T1 TE">{{Act.LookedTimes}}</sup-->
                                <div v-if="Act.HoldStyle==='封闭式'" class="TF T2">内含多个子活动</div>
                                <div v-else class="TF T2">类型:{{Act.ActStyle}}</div>
                            </div>
                            <div></div>
                        </div>
                        <div class="XLR YC TG" @click="SeleI = index,InputI=1">
                            <div></div>
                            <div class="W12" style="white-space: nowrap;">口令</div>
                            <div class="W2">
                                <el-icon><ArrowRight/></el-icon>
                            </div>
                        </div>
                    </div>
                    <div v-else class="XLR YC TG">
                        <div class="XLR YC">
                            <div class="ANBlue BD_Radius1 WHbb  XYC LH120 T2" @click="SeleI=index,Show=3">活动<br>规则</div>&nbsp;
                            <div :class="Act.HoldStyle==='封闭式' ? 'TB' :'TG'" @click="SetSeleI(index)">
                                {{Act.Title}}<!--sup class="T1 TE">{{Act.LookedTimes}}</sup-->
                                <div v-if="Act.HoldStyle==='封闭式'" class="TF T2">内含多个子活动</div>
                                <div v-else class="TF T2">类型:{{Act.ActStyle}}</div>
                            </div>
                            <div></div>
                        </div>
                        <!--div>
                          <div v-if="Scale==='不限人数'">不限人数</div>
                          <div v-if="Scale==='额满为止'" class="TF">限:{{Act.Scale}}人</div>
                          <div class="DispIB CRW" style="position: relative;left: -0.3rem;top:-0.5rem">{{Act.Joinn}}☺</div>
                        </div-->
                        <div class="W2" @click="SetSeleI(index)">
                            <el-icon><ArrowRight/></el-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <PopTitle v-if="Show===1" Title="新增【赛事•活动】信息" @FromPopUp="Show=-1">
        <CreateAct OP="新增" :Msg="Curr" @CreateAct="Refresh1"></CreateAct>
    </PopTitle>
    <PopTitle v-if="Show===2" Title="【赛事•活动】信息变更" @FromPopUp="Show=-1">
        <CreateAct OP="修改" :Msg="Acts[SeleI]" @CreateAct="Refresh1"></CreateAct>
    </PopTitle>
    <PopOneAN v-if="SeleI>-1 && Show4.indexOf('循环')>=0" @FromPopUp="Refresh2">
        <Act0Main :ActDoc="Acts[SeleI]" :ItemStr="ItemStr"></Act0Main>
    </PopOneAN>
    <PopTitle v-if="Show===3" :Title="CurrActDoc.Title" @FromPopUp="Refresh2">
        <Act1Main :ActDoc="CurrActDoc" :ItemStr="ItemStr"></Act1Main>
    </PopTitle>
    <PopTitle v-if="SeleI>-1 && Show4==='其它活动'" :Title="Acts[SeleI].Title" @FromPopUp="SeleI=-1">
        <OpenOneAct :Msg="Acts[SeleI]" @OpenOneAct="Refresh2"></OpenOneAct>
    </PopTitle>
    <PopTitle v-if="Show===4" :Title="Acts[SeleI].Title.substr(0,6)+'...活动规则'" @FromPopUp="ActSave(SeleI)"><br>
        <div v-if="Acts[SeleI].UserName===$store.state.UserName" class="AlignC">
            <textarea class="W90 H90" wrap="soft" v-model="Acts[SeleI].Text"></textarea>
            <WXLink :WLandTo="'Act_'+ItemStr+'Act_' + Acts[SeleI]._id+'Act_Rule'"
                    Pic="UserUpload/static/Img/Award.png"
                    :WTitle="Acts[SeleI].Title" WText="当前已有27人预报名。"></WXLink>
        </div>
        <div v-else class="MLR2">
            <div v-for="(W,index) in Acts[SeleI].Text.split('\n')" :key="index">
                <h3 v-if="['一','二','三','四','五','六','七','八','九'].indexOf(W.charAt(0))>=0" class="ML2Rem LH200">{{W}}</h3>
                <div v-else>
                    <h4 v-if="['1','2','3','4','5','6','7','8','9'].indexOf(W.charAt(0))>=0" class="ML2Rem LH200">{{W}}</h4>
                    <p v-else>{{W}}</p>
                </div>
            </div>
        </div>
    </PopTitle>
    <MyInput v-if="InputI===1" Title="授权口令（纯数字）" Type="number" :Begin="null" @FromMyI="MyInputVal"></MyInput>
    <WXLink :WLandTo="'Act_'+ItemStr" Pic="UserUpload/static/SY/Advise.png" WTitle="泛约大厅赛事活动总入口"
            WText="内含：活动规则、操作说明与活动目录信息"></WXLink>
</template>

<script>
    import {myMongoDBPost, Compare} from '@/components/SharedVues/Shared0.js'


    import PopTitle from "../MyPopWin/PopTitle";
    import MyInput from "../SharedVues/MyInput";
    import OpenOneAct from "./OpenOneAct";
    import CreateAct from "./CreateAct";
    import SeleOneItem from "../FrontPage/SeleOneItem";
    import Act0Main from "./Act0Main";
    import PopOneAN from "../MyPopWin/PopOneAN";
    import Act1Main from "./Act1Main";
    import FYReg from "../M0_RegLogin/FYReg";
    import SubEntrance from "../SharedVues/SubEntrance";
    import PopUpDown from "../MyPopWin/PopUpDown";
    import WXLink from "../IndependentVues/WXLink";
    import FYArea from "../SharedVues/FYArea";

    export default {
        name: "ActMain",
        components: {
            FYArea,
            WXLink,
            PopUpDown,
            SubEntrance,
            FYReg,
            Act1Main,
            PopOneAN,
            Act0Main,
            SeleOneItem,
            CreateAct,
            OpenOneAct,
            MyInput,
            PopTitle
        },
        props: ['ItemStr'],
        data() {
            return {
                Show: -1,
                Show4: '',
                SeleI: -1,
                InputI: -1,
                Password: -1,
                Acts: [],
                Scale: '',
                Curr: {ItemStr: this.ItemStr, EndBool: 1},
                Title: '最新活动信息',
                Title3: '',
                CurrActDoc: {},
                selectedButton: 1, // 默认选中 value 为 1的按钮
            }
        },
        mounted() {
            let that = this;
            document.getElementById('TitleId').innerText = "[" + this.ItemStr.split(":")[1] + "]项目活动管理器";
            this.SetActArea();
            if (that.$store.state.LandTo.split('Act_').length > 2) this.ActsRefresh(function () {
                let aId = that.$store.state.LandTo.split("Act_")[2];
                if (aId !== "Menu") {
                    for (let i = 0; i < that.Acts.length; i++) if (that.Acts[i]._id === aId) that.SeleI = i;
                    if (that.SeleI === -1) {
                        alert('该项链接已失效!');
                        that.$store.commit('ChangeLandTo', undefined);
                    }
                    else {
                        that.SetSeleI(that.SeleI);
                        if (that.$store.state.LandTo.indexOf('Act_Rule') >= 0) that.Show = 3;
                    }
                }
                that.$store.commit('ChangeLandTo', '');
            });
        },
        methods: {
            SetActArea() {
                let Val = this.$store.state.FYArea;
                this.Curr.Province = Val.split(":")[1];
                this.Curr.City = Val.split(":")[2];
                this.Curr.DC = Val.split(":")[3];
                if (Val.split(":")[0] === 'N') {
                    delete this.Curr.Province;
                    delete this.Curr.City;
                    delete this.Curr.DC;
                } else if (Val.split(":")[0] === 'P') {
                    delete this.Curr.City;
                    delete this.Curr.DC;
                } else if (Val.split(":")[0] === 'C') delete this.Curr.DC;
                this.ActsRefresh(function () {
                });
            },

            SetCurrEndBool(EndBool) {
                this.Curr.EndBool = EndBool;
                this.ActsRefresh(function () {
                });
            },
            ActsRefresh(CallBack) {
                let that = this;
                that.Acts = [];
                myMongoDBPost("ActsRefresh", {Curr: this.Curr}, function (Docs) {
                    that.Acts = Docs.sort(Compare('Index'));
                    CallBack();
                });
            },

            SetSeleI(index) {
                this.SeleI = index;
                this.Show4 = this.Acts[this.SeleI].ActStyle;
                if (this.Acts[index].HoldStyle === '封闭式') {
                    this.Show = 3;
                    this.CurrActDoc = this.Acts[this.SeleI];
                    this.Show4 = '';
                }
            },
            MyInputVal(Val) {//凭密进入
                if (this.$store.state.UserName === '游客') alert('游客操作无效，请先登录!');
                else if (this.InputI === 1) {
                    this.Password = Val;
                    if (this.Password === this.Acts[this.SeleI].ScaleVal) {
                        this.Show4 = this.Acts[this.SeleI].ActStyle;
                        if (this.Acts[this.SeleI].HoldStyle === '封闭式') this.SetSeleI(this.SeleI);
                    } else alert('口令不对，您无权介入本活动！');
                }
                this.InputI = -1;
            },
            SetShow1() {//此时强制只显示自己创建的活动
                this.Curr.UserName = this.$store.state.UserName;
                this.Show = 1;
            },
            ActSave(SeleI) {
                let that = this;
                myMongoDBPost("UpdateDoc/acts", {UPTJ: {_id: this.Acts[SeleI]._id}, UPObj: {Text: this.Acts[SeleI].Text}}, function () {
                    that.Show = -1;
                })
            },
            Refresh1(Val) {
                let that = this;
                if (Val !== '') {
                    that.CurrActDoc = Val;
                    if (Val.HoldStyle === '封闭式' && Val !== 'SetEndbool') that.Show = 3;
                    this.ActsRefresh(function () {
                        that.Show = -1;
                    });
                } else that.Show = -1;
            },
            Refresh2() {
                this.SeleI = -1;
                this.Show = -1;
                this.Show4 = '';
                this.ActsRefresh(function () {

                })
            },
            Delete(Id, index, Title) {
                let that = this;
                let YN = confirm(Title + "===》确认要删除吗？");
                if (YN && Id !== undefined) myMongoDBPost("RemoveDoc/Acts", {_id: Id}, function () {//待优化,本活动下相关视频都应删除
                    myMongoDBPost("RemoveDoc/Joins", {ActId: Id}, function () {
                        myMongoDBPost("RemoveDoc/Act0", {ActId: Id}, function () {
                            myMongoDBPost("RemoveDoc/Act1", {ActId: Id}, function () {
                                myMongoDBPost("RemoveDoc/MsgDetails", {MsgId: Id}, function () {
                                    that.Acts.splice(index, 1);
                                })
                            })
                        })
                    })
                })
            }
        }
    }
</script>

<style scoped>
</style>
