<template>
  <div>
    <div v-if="Show===3 && CurrNode.NodeBool.indexOf('有效叶子')>=0"></div>
    <div v-else>
      <div v-if="Show===2 && ['根重复用户','非根重复用户','非泛约用户','未设喜好项目用户','无效分支节点','无效节点'].indexOf(CurrNode.NodeBool)>=0"></div>
      <div v-else>
        <div class="AlignC" style="line-height: 0.6rem">
          <div v-if="CurrNode.ParentNode==='根'" class="XLR">&nbsp;
            <div class="BottO PA3 TC">
              {{CurrNode.NodeName}}：&nbsp;
              <div class="DispIB AN3">{{CurrNode.JF}}</div>
            </div>&nbsp;
          </div>
          <div v-else class="AlignC">
            <div class="XLR">
              &nbsp;
              <div class="BDLeft Vertical1px"></div>
              &nbsp;
            </div>
            <div class="DispIB NodeWH" style="padding: 0 1px">
              <div v-if="CurrNode.NodeBool.indexOf('有效')>=0" class="TC">
                {{CurrNode.NodeName}}
                <div v-if="CurrNode.NodeBool.indexOf('分支')>=0" class="AN5 AN54" style="font-size: 0.8rem">1+{{CurrNode.JF-1}}</div>
                <div v-if="CurrNode.NodeBool.indexOf('叶子')>=0" class="AN5 AN54" style="font-size: 0.8rem">+{{CurrNode.JF}}</div>
              </div>
              <div v-else class="TF">
                {{CurrNode.NodeName}}
                <div class="AN5 AN53 T2" @click="Note(CurrNode.NodeBool)">无效</div>
              </div>
            </div>
          </div>
          <div v-if="CurrNode.NodeBool === '有效分支节点'" class="DispIB BDLeft Vertical1px"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 

  export default {
    name: "JFNode",
    props: ['CurrNode', 'Show'],
    mounted() {
      console.log(this.CurrNode)
    },
    methods: {
      Note(Bool) {
          alert(Bool+ '不参与积分!')
      }
    }
  }
</script>

<style scoped>
  .OneCol {
    line-height: 1rem;
    width: 1rem;
    overflow: hidden;
  }

  .NodeWH {
    width: 30px;
    height: 80px;
    line-height: 1rem;
    border: 1px solid #002727;

  }

  .W1rem {
    width: 1rem;
    max-height: 4rem;
    padding: 1mm;
    overflow: visible;
  }

  .Vertical1px {
    width: 1px;
    height: 4mm;
  }
</style>
