<template>
    <div>
      <table  class="MT2" style="background-color:#fff;">
        <thead>
        <tr class="TF">
          <th>序号</th>
          <th>项目</th>
          <th>表演展示</th>
          <th>喜好时间</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(CB,index) in Bysets" :key="index" style="line-height:1.8rem;text-align: left">
          <td>{{index+1}}</td>
          <td>
            <div class="BottGray T3 TF">{{ItemTypeName(CB.ItemStr)[0]}}</div>
            {{ItemTypeName(CB.ItemStr)[1]}}
          </td>
          <td>
            <ShowPVT :PVT="{P:CB.Pic,V:CB.Video,T:CB.Text,Update:false}"></ShowPVT>
          </td>
          <td>
            <div v-if="CB.TimeWinStrs && CB.TimeWinStrs.length>0" class="TB">
              <div v-if="SeleI===-1" @click="SeleI=index">
                <el-icon><ArrowUp /></el-icon>
              </div>
              <div v-else @click="SeleI=-1">
                <el-icon><ArrowDown /></el-icon>
              </div>
            </div>
            <div v-else class="TF">
              未设置
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <PopTitle v-if="SeleI!==-1" Title="喜好时间窗" @FromPopUp="SeleI=-1">
        <TimeWindowShow :TimeWinStrs="Bysets[SeleI].TimeWinStrs"></TimeWindowShow>
      </PopTitle>
    </div>
</template>

<script>
  import {myMongoDBPost, GetItemType} from '@/components/SharedVues/Shared0.js'

  import PopTitle from "../MyPopWin/PopTitle";
  import TimeWindowShow from "../SharedVues/TimeWindowShow";
  import ShowPVT from "../MyPVTs/ShowPVT";
    export default {
        name: "MyBySet",
      components: {ShowPVT, TimeWindowShow, PopTitle},
      data(){
          return {
            CurrU:this.$store.state.UserName,
            SeleI:-1,
            Bysets:[],
            IsLoading:true
          }
      },
      mounted() {
        let that = this;
        myMongoDBPost("GetDocs/byset0", {UserName: that.CurrU}, function (Docs2) {
          for (let i = 0, len = Docs2.length; i < len; i++)
            that.Bysets.push({
              ItemStr: Docs2[i].ItemStr,
              Pic: Docs2[i].Pic,
              Video: Docs2[i].Video,
              Text: Docs2[i].Text,
              TimeWinStrs: Docs2[i].TimeWinStrs
            })
          that.IsLoading = false;
        })
      },
      methods:{
        ItemTypeName(ItemStr) {
          let Code = ItemStr.split(":")[0];
          return [GetItemType(Code), ItemStr.split(":")[1].substr(0, 4)]
        },
      }
    }
</script>

<style scoped>

</style>
