<template>
  <div>
    <TopMenu2 :Menus="Menus" :BVal="0" @TopMenu2="TopMenu2">
      <div class="PA3 TF">
        <div v-if="TypeSele===0">
          <table  style="background-color:#fff;">
            <thead>
            <tr class="TF">
              <th>时段</th>
              <th v-for="(w,index) in ['一','二','三','四','五','六','日']" :key="index">
                {{w}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(HS,index1) in HourSegs" :key="index1">
              <td>&nbsp;{{HS}}&nbsp;</td>
              <td v-for="(Week,index2) in Weeks" :key="index2">

                <img v-if="HSs[(index1*7)+index2]" src="../../../static/img/XMYY/TimeWindowOpen.png" class="WH64">

                <img v-else src="../../../static/img/XMYY/TimeWindowClose.png" class="WH64">

              </td>
            </tr>
            </tbody>
          </table>
          备注：
          <div class="T2 XLR PA3">
            <div>
              <div class="NoWrap" v-for="(H,index) in HourSeg1" :key="index">
                {{index+1}}. {{H}}
              </div>
            </div>
            <div>
              <div class="NoWrap" v-for="(H,index) in HourSeg2" :key="index">
                {{index+5}}. {{H}}
              </div>
            </div>
          </div>
        </div>
        <div v-if="TypeSele===1">
          <table  style="background-color:#fff;">
            <thead>
            <tr class="TF">
              <th>年份</th>
              <th v-for="(M,index) in ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十']" :key="index">
                {{M}}
              </th>
              <th class="T3">十一</th>
              <th class="T3">十二</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(Y,index1) in Years" :key="index1">
              <td>{{Y}}</td>
              <td v-for="(M,index2) in Months" :key="index2">
                <img v-if="YMs[(index1*12)+index2]" src="../../../static/img/XMYY/TimeWindowOpen.png" class="WH55">
                <img v-else src="../../../static/img/XMYY/TimeWindowClose.png" class="WH55">
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-if="TypeSele===2" class="XLR MP4 BoxGray1">&nbsp;
          <div>
            <div class="AlignC TF BottGray">&nbsp;&nbsp;全体公民假&nbsp;&nbsp;</div>
            <div class="TG" v-for="(x,index) in Hs1" :key="index">
              <div v-if="Holidays2[index]" class="MT2 BoxBR TE">
                {{x}}
                <el-icon><Check /></el-icon>
              </div>
              <div v-else class="MT2 ML2">{{x}}</div>
            </div>
          </div>
          <div>
            <div class="AlignC TF BottGray">&nbsp;&nbsp;部分公民假&nbsp;&nbsp;</div>
            <div class="TG" v-for="(x,index) in Hs2" :key="index">
              <div v-if="Holidays2[index+Hs1.length]" class="MT2 BoxBR TE">
                {{x}}
                <el-icon><Check /></el-icon>
              </div>
              <div v-else class="MT2 ML2">{{x}}</div>
            </div>
          </div>
          <div>
            <div class="AlignC TF BottGray">&nbsp;&nbsp;周末&nbsp;&nbsp;</div>
            <div class="TG" v-for="(x,index) in Hs3" :key="index">
              <div v-if="Holidays2[index+Hs1.length+Hs2.length]" class="MT2 BoxBR TE">
                {{x}}
                <el-icon><Check /></el-icon>
              </div>
              <div v-else class="MT2 ML2">{{x}}
              </div>
            </div>
          </div>&nbsp;
        </div>
      </div>
    </TopMenu2>
  </div>
</template>

<script>

  import SideMenu2 from "./SideMenu2";
  import {Weeks, HourSegs, Years, Months, Holidays} from '@/components/SharedVues/Shared0.js';
  import PopTitle from "../MyPopWin/PopTitle";
  import TopMenu2 from "@/components/SharedVues/TopMenu2";


  export default {
    name: "TimeWindowShow",

    props: ['TimeWinStrs'],
    components: {

      TopMenu2,
      PopTitle,
      SideMenu2
    },
    data() {
      return {
        Menus: ['某日时段', '某年某月', '节假日'],
        Weeks: Weeks,
        HourSegs: HourSegs,
        Years: Years,
        Months: Months,
        Holidays: Holidays,

        Show3: false,
        Show4: false,
        TypeSele: 0,

        HourSeg1: ["晨练=5:00～7:00", "早点=7:30～8:30", "上午=8:30～11:30", "午餐=11:30～13:30"],
        HourSeg2: ["下午=15:00～16:30", "课外=16:30～18:30", "晚餐=18:30～20:00", "晚上=20:00～23:00"],
        HSs: [],
        YMs: [],
        Holidays2: [],
        Hs1: ['元旦', '春节', '清明节', '五一劳动节', '端午节', '中秋节', '十一国庆节'],
        Hs2: ['妇女节', '青年节', '儿童节', '建军节'],
        Hs3: ['星期六', '星期日'],
        TWSs1: [],
        TWSs2: [],
      }
    },
    computed: {
      CurrItemType() {
        return this.Menus[this.TypeSele];
      }
    },
    mounted() {//解析当前时间窗字符串
      for (let i = 0; i < 7 * 8; i++) this.HSs.push(false);
      for (let i = 0; i < 12 * Years.length; i++) this.YMs.push(false);//这样是不行的==>this.Years2=(new Array(Years.length));
      for (let i = 0, len = Holidays.length; i < len; i++) this.Holidays2.push(false);

      let that = this;
      for (let HS = 0; HS < 8; HS++) //1.先解析时段
        for (let Week = 0; Week < 7; Week++)
          if (that.TimeWinStrs.split(";").indexOf("HS:" + Week + "," + HS) >= 0) that.HSs[HS * 7 + Week] = true;

      for (let Yn = 0; Yn < Years.length; Yn++) //2.年月
        for (let Mn = 0; Mn < 12; Mn++)
          if (that.TimeWinStrs.split(";").indexOf("YM:" + Mn + "," + Yn) >= 0) that.YMs[Yn * 12 + Mn] = true;

      that.Holidays2.forEach(function (x, index) {//3.节假日
        that.Holidays2[index] = (that.TimeWinStrs.indexOf("H:" + index + ";") >= 0);
      });
    },
    methods: {
      TopMenu2(Val) {
        this.TypeSele = Val;
      },
    }
  }
</script>

<style scoped>
  .WH55 {
    width: 5mm;
    height: 5mm;
  }
</style>
