<template>
    <div>
        <div class="XLR YC">
            <div>
                <img v-if="Pic.length===0" src='../../../static/img/Media/PicColor.png' class="WHaa" @click="SetPVTActive('1')">
                <ShowPFromThumb v-else :Msg="{Pic:Pic,Update:true}" @FromChildP="SetPVTActive('1')"></ShowPFromThumb>
            </div>
            <div class="MLR15">
                <img v-if="Video.length===0" src='../../../static/img/Media/VideoColor.png' class="WHaa" @click="SetPVTActive('2')">
                <ShowVFromThumb v-else :Msg="{Video:Video,Update:true}" @FromChildV="SetPVTActive('2')"></ShowVFromThumb>
            </div>
            <div>
                <img v-if="Text.length===0" src='../../../static/img/Media/TextColor.png' class="WHaa" @click="SetPVTActive('ShowT')">
                <img v-else src='../../../static/img/Media/TextRect.jpg' class="WHaa" @click="SetPVTActive('ShowT')">
            </div>
        </div>
        <MyInput v-if="ShowT" :Title="PVTF.TextTitle" Type="textarea" :Begin="Text" @FromMyI="MyInputVal"></MyInput>
        <UpFiles1 v-if="Active" :VP="VPBool" CallFrom="PVT" @FromU1="UpEnd"></UpFiles1>
    </div>
</template>

<script>
    import ShowVFromThumb from '@/components/MyPVTs/ShowVFromThumb'
    import ShowPFromThumb from '@/components/MyPVTs/ShowPFromThumb'

    import PopTitle from "@/components/MyPopWin/PopTitle";
    import MyInput from "../SharedVues/MyInput";
    import UpFiles1 from "./UpFiles1";
    // style="width: 3cm;height:0.9cm;padding: 1mm"
    export default {
        name: "ShowUpdatePVT",
        components: {UpFiles1, MyInput, PopTitle, ShowVFromThumb, ShowPFromThumb},
        props: ['PVTF'],
        data() {
            return {
                ShowT: false,
                Pic: '',
                Video: '',
                Text: '',
                Active: false,
                VPBool: ''
            }
        },
        mounted() {
            if (this.PVTF.P !== undefined) this.Pic = this.PVTF.P;
            if (this.PVTF.V !== undefined) this.Video = this.PVTF.V;
            if (this.PVTF.T !== undefined) this.Text = this.PVTF.T;
        },
        methods: {
            UpEnd(VAL) {
                let that = this;
                this.Active = false;
                if (VAL === '未选择') {
                    if (that.VPBool === '1') that.$emit("ShowUT", {P: that.Pic});
                    if (that.VPBool === '2') that.$emit("ShowUT", {V: that.Video});
                } else {
                    if (that.VPBool === '1') {
                        that.Pic = VAL;
                        that.$emit("ShowUT", {P: VAL});
                    }
                    if (that.VPBool === '2') {
                        that.Video = VAL;
                        that.$emit("ShowUT", {V: VAL});
                    }
                }
            },
            SetPVTActive(VPBool) {
                if (this.$store.state.UserName === '游客') alert('无效操作，请先注册登录！'); else {
                    if (VPBool === 'ShowT') this.ShowT = true; else {
                        this.VPBool = VPBool;
                        this.Active = true;
                    }
                }
            },
            MyInputVal(Val) {
                this.ShowT = false;
                if (Val===undefined) return;
                this.Text = Val;
                this.$emit("ShowUT", {T: Val});
            },
        }
    }
</script>

<style scoped>
    .MLR15 {
        margin-left: 1.5mm;
        margin-right: 1.5mm;
        }
    
    .PA2 {
        padding: 1mm 2mm;
        }
</style>
