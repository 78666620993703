<template>

    <div v-if="IsLoading">IsLoading....</div>
    <div v-else>
      <el-divider/>
      <div v-if="MyQYs.length>0" class="XLR MP4 TB" @click="Show27 = !Show27">
        <div style="color:#685d04;">您发布的求缘信息有人响应了</div>
        <div v-if="Show25"><el-icon><ArrowDown/></el-icon></div>
        <div v-else><el-icon><ArrowUp/></el-icon></div>
      </div>
      <div v-if="Show27">
        <div v-for="(QY,index) in MyQYs" :key="index" @click="SeleI=index" class="DispIB TB" style="width: 50vw">
          {{index+1}}.{{QY.UserName}}
        </div>
        <OpenOneQY v-if="SeleI !== -1" :Msg="MyQYs[SeleI]" @OpenOneQY="ReGetMyQYs"></OpenOneQY>
      </div>
      <el-divider/>
      <div v-if="TJY0s.length>0" class="XLR MP4 TB" @click="Show25 = !Show25">
        <div style="color:#685d04;">
          您所提建议的反馈信息<el-badge style="vertical-align:top;" :value="TJY0s.length"></el-badge></div>
        <div v-if="Show25"><el-icon><ArrowDown/></el-icon></div>
        <div v-else><el-icon><ArrowUp/></el-icon></div>
      </div>
      <div v-if="Show25">
        <div class="MP4 Box5 LH150">
          <div v-for="(CurrJY,index) in TJY0s" :key="index" class="TC">
            {{index+1}}.{{CurrJY.SClass}}： {{CurrJY.Key1}}>{{CurrJY.Key2}}
            <div class="XLR YC MP4">
              <div class="BottGray TF">后台反馈信息：</div>
              <div class="XLR YC">
                <div class="TB" @click="DoMyTJY('TJY0s',CurrJY,2)">不认可</div>
                <div class="TG ML4" @click="DoMyTJY('TJY0s',CurrJY,4)">认可</div>
              </div>
            </div>
            <p class="TF">
              {{CurrJY.fyRespond}}
            </p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import { myMongoDBPost,UpdatelocalStorage} from '@/components/SharedVues/Shared0.js'
  import PopTitle from "@/components/MyPopWin/PopTitle";
  import OpenOneQY from "../QYs/OpenOneQY";
  import PopOneAN from "../MyPopWin/PopOneAN";
  import ZY from "../ListYY/ZY";
  import BY from "../ListYY/BY";

  export default {
    name: "NewsToMe",

    components: {BY, ZY, PopOneAN, OpenOneQY,  PopTitle},
    data() {
      return {
        IsLoading: true,
        Show25: false,
        Show26: false,
        Show27: false,
 

        TJY0s: [],
        SeleI: -1,

        MyQYs: [],
      }
    },

    mounted() {
      let that = this;
      myMongoDBPost("GetNewsToMe", {UserName: that.$store.state.UserName}, function (Ret) {

        that.TJY0s = Ret.TJY0s;//获取用户建议

        that.MyQYs = Ret.MyQYs;
        that.IsLoading = false;

      });
    },
    methods: {
      ReGetMyQYs() {
        let that = this;
        myMongoDBPost("GetDocs/QYs", {
          UserName: this.$store.state.UserName,
          NewDY: true
        }, function (Docs) {
          that.MyQYs = Docs;
        })
      },//我的求缘信息

      GetMySuggestions1(callback) {
        let that = this;
        myMongoDBPost("GetDocs/USuggests", {UserName: this.$store.state.UserName, EndBool: 1}, function (data3) {
          that.TJY0s = data3;
          callback();
        });
      },
      DoMyTJY(X, Y, Z) {
        let that = this;
        that.Show25 = false;
        if (this.$store.state.UserName !== '游客') {
          if (X === 'TJY0s') myMongoDBPost("UpdateDoc/USuggests", {
            UPTJ: {_id: Y._id},
            UPObj: {EndBool: Z}
          }, function () {
            that.GetMySuggestions1(function () {
            })
          });
        }
      }
    }
  }
</script>

<style scoped>
</style>
