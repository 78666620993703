<template>
    <video id="MyVideo" :controls="true" :src="VideoSrc" webkit-playsinline="" playsinline="" x5-playsinline="" width="100%" height="auto"></video>
</template>

<script>
    export default {
        name: "ShowVFullScreen",
        props: ['VideoSrc'],
        mounted() {
            let that = this;
            let Video = document.getElementById('MyVideo');
            Video.play();
            Video.addEventListener('play', function () {
                requestFullScreen(Video);
            });
            Video.addEventListener('pause', function () {
                that.$emit('FromShowV')
            });
            Video.addEventListener('click', function () {
                that.$emit('FromShowV')
            });
            Video.addEventListener('ended', function () {
                that.$emit('FromShowV')
            });
            function requestFullScreen(element) {// 判断元素是否支持请求全屏的方法
                let requestMethod =
                    element.requestFullScreen || //W3C
                    element.webkitRequestFullScreen || //Chrome等
                    element.mozRequestFullScreen || //FireFox
                    element.msRequestFullscreen; //IE11
                if (requestMethod) { // 执行请求全屏的方法
                    requestMethod.call(element);
                } else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
                    element.ActiveXObject("Microsoft.XMLHTTP");// 使用ActiveXObject的方式进行全屏请求（不推荐，已经不再支持）
                }
            }
        }
    }
</script>

<style scoped>

</style>