<template>
  <div>
    <div v-if="IsLoading" class="T8 AlignC MT2">IsLoading...</div>
    <div v-else>
      <div v-if="yytemplats.length===0" class="MP4 TF">
        <p>经常性邀约模板，需要完成至少一个普通邀约流程才能产生，您当前尚无常用邀约模板可用！</p>
      </div>
      <div v-else v-for="(x,index) in yytemplats" :key="index">
        <div class="XLR YC BottGray MP4">
          <div class="XL YC">
            <div class="TC">{{index+1}}.&nbsp;{{MBStr(x.ItemStr)}}</div>&nbsp;&nbsp;
            <img :src="'UserUpload/static/Img/ArrowUP.png'" class="WH44" @click="SetTop(index)">
          </div>
          <div @click="ChangeYY(x.ItemStr)">
            <el-icon><ArrowRight /></el-icon>
          </div>
        </div>
      </div>
    </div>
    <PopTitle v-if="ShowMBYYPopup" :Title="MBStr(CurrItemStr)" @FromPopUp="ShowMBYYPopup=false">
      <MBYY @FromMBYY="ShowMBYYPopup=false"></MBYY>
    </PopTitle>
  </div>
</template>

<script>
  import {myMongoDBPost, GetC_I, Compare, GetItemType} from '@/components/SharedVues/Shared0.js'

  import PopTitle from "../MyPopWin/PopTitle";
  import MBYY from "./MBYY";

  export default {
    name: "MBSelect",
    components: {MBYY, PopTitle},
    data() {
      return {
        IsLoading: true,//数据准备不就绪,就不让它显示
        yytemplats: [],
        CurrYYDoc: 0.12,
        CurrYYDoc0: {},
        ShowMBYYPopup:false
      }
    },
    computed: {
      CurrItemStr() {
        return this.$store.state.CurrItemStr;
      }
    },
    watch: {
      CurrItemStr(Val) {
        if (Val === 'Deleted') this.ReSetyytemplats();
      }
    },
    mounted() {
      this.ReSetyytemplats();
    },
    methods: {
      ReSetyytemplats() {
        let that = this;
        if (this.$store.state.UserName === '游客')
          that.IsLoading = false;
        else
          myMongoDBPost("GetDocs/YYMBs", {zyUserName: this.$store.state.UserName}, function (data) {
            data.sort(Compare('Ni'));
            that.yytemplats = data;
            that.IsLoading = false;
          });
      },
      MBStr(ItemStr) {//模板字符串
        let Code = ItemStr.split(":")[0], CHs = ['对抗竞技：', '休闲娱乐：', '技能服务：', '特邀：'];
        return CHs[GetC_I(Code)] + GetItemType(Code) + "﹥" + ItemStr.split(":")[1]
      },
      ChangeYY(CurrItemStr) {
        this.$store.commit('ChangeCurrItemStr', CurrItemStr);
        this.ShowMBYYPopup=true;
      },
      SetTop(index) {
        let that = this, Ni;
        if (index > 0) Ni = this.yytemplats[index - 1].Ni - 1;
        myMongoDBPost("UpdateDoc/YYMBs", {
          UPTJ: {zyUserName: this.$store.state.UserName, ItemStr: this.yytemplats[index].ItemStr},
          UPObj: {Ni: Ni}
        }, function () {
          that.ReSetyytemplats();
        })
      }
    }
  }
</script>

<style scoped>
  .W9 {
    width: 9mm
  }
</style>
