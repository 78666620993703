<template>
    <div>
        <div class="XLR YC BottO MP4">
            <div class="XL YC"><input id="fs011" type="radio" class="AlignT" name="fyServer01" v-model="OP"
                                      value='无格式查看'>
                <label for="fs011">查看</label></div>
            <div class="XL YC"><input id="fs012" type="radio" class="AlignT" name="fyServer01" v-model="OP"
                                      value="FromJson">
                <label for="fs012">FromJson</label></div>
            <div class="XL YC"><input id="fs013" type="radio" class="AlignT" name="fyServer01" v-model="OP"
                                      value="ToJson">
                <label for="fs013">ToJson</label></div>
            <div class="XL YC"><input id="fs014" type="radio" class="AlignT" name="fyServer01" v-model="OP" value="删除">
                <label for="fs014">删除</label></div>
        </div>
        <div v-for="(xs,index1) in CollNameShows" :key="index1" class="BottO MP4">
            <div class="XLR MT2">
                <!--div class="TE" @click="doCollname('删除本组',xs)">删除本组</div-->
                <div>{{xsTips[index1]}}:</div>
                <div v-if="Show2!==index1" class="TB T7" @click="Show2=index1">
                    <el-icon>
                        <ArrowDown/>
                    </el-icon>
                </div>
                <div v-else class="TB T7" @click="Show2=-1">
                    <el-icon>
                        <ArrowUp/>
                    </el-icon>
                </div>
            </div>
            <div v-if="Show2===index1" class="XL">
                <div v-for="(x,index2) in xs" :key="index2">
                    <div class="AlignC BoxGray1 BD_Radius1" @click="doCollname(OP,x)" style="margin: 1mm;padding: 1mm">
                        <el-badge :value="CollLengthShows[index1][index2]"></el-badge>
                        <div>{{x}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="XLR YC MP4">
            服务器端照片和视频文件：
            <!--div class="TB" @click="AKeyBackupRestore('Backup')">一键<br>备份</div>
            <div class="TG" @click="AKeyBackupRestore('Restore')">一键<br>恢复</div-->
            <div class="TE" @click="DeleteAllUnusedVPs">清理垃圾</div>&nbsp;
        </div>
        <PopTitle v-if="Show1" :Title="'当前集合:'+CurrCollName" @FromPopUp="Show1=false">
            <div v-if="CurrCollName==='userlogs'" class="MLR4 MT2">
                <div v-for="(X,index) in dataForLook" :key="index" class="BottGray">
                    <div>{{StampToTimeStr(X.LoginTime)}}☞{{X.UserName}}</div>
                    <div class="XR">
                        {{X.LandTo}}
                        <span v-if="X.LandTo.indexOf('WXLink')===-1"> {{X.Looked}}</span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-for="(XX,index3) in dataForLook" :key="index3" class="BoxDot">
                    <div class="XLR BottO YC">
                        <div class="TE PA2" @click="DeleteCurrDoc(XX._id)">删除</div>
                        <div v-if="['byset0','act0'].indexOf(CurrCollName)>=0">
                            <div v-if="CurrCollName==='byset0'" class="TB MT2" @click="Show=index3">
                                {{XX.UserName}}/{{XX.ItemStr}}
                            </div>
                            <div v-if="CurrCollName==='act0'" class="TB MT2" @click="Show=index3">{{XX.TimeStr}} >
                                {{XX.SiteShortName}}
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="XX.UserName" class="TB MT2 MR4" @click="Show=index3">{{index3}}:{{XX.UserName}}
                            </div>
                            <div v-else class="TB MT2" @click="Show=index3">{{index3}}:{{XX._id}}</div>
                        </div>
                    </div>
                    <div v-if="index3===Show">
                        <div v-for="(y,index) in ObjAttrName(XX)" :key="index">
                            <div v-if="y!=='_id'">{{y}} :{{XX[y]}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="CurrCollName.split('-')[0]==='fymc'" class="XLR MLR4 MT2">
                <div></div>
                <div class="TB MT2" @click="Repair(CurrCollName)">修复</div>
            </div>
        </PopTitle>
    </div>
</template>

<script>
    import {
        myMongoDBPost,
        myDataFileGet,
        ObjAttrName,
        StampToTimeStr
    } from '@/components/SharedVues/Shared0.js'
    import PopTitle from "@/components/MyPopWin/PopTitle";

    export default {
        name: "HTDatabases",
        components: { PopTitle},
        data() {
            return {
                Show: -1,
                FuzzyId: '',
                xsTips: [],
                CollNameShows: [],
                CollLengthShows: [],
                CurrCollName: '',
                dataForLook: [],
                OP: '无格式查看',
                Show1: false,
                Show2: -1
            }
        },
        mounted() {
            this.Refresh();
        },

        methods: {
            Refresh() {
                let that = this;
                myMongoDBPost("GetCollections", {}, function (data) {
                    that.xsTips = data.Classfies;
                    that.CollNameShows = data.CollNameShows;
                    that.CollLengthShows = data.CollLengthShows;
                });
            },
            doCollname(op, collName) {
                let that = this;
                /*
                if (op === '删除本组') { //此时collName是一个数组
                  let YN = confirm("您确认要删除本组集合吗?");
                  if (YN) myMongoDBPost("DeleteCollections", collName, function (data) {
                    if (data.indexOf("!") >= 0) alert(data); else that.Refresh()
                  });
                }*/

                if (op === '无格式查看') myMongoDBPost("MongoFind", {collName: collName, FuzzyId: "全部"}, function (data) {
                    that.dataForLook = data;
                    that.CurrCollName = collName;
                    if (data.length > 0) that.Show1 = true;
                });

                if (op === 'FromJson') myMongoDBPost("JsonToMongo", {collName: collName}, function (data) {
                    alert(data)
                });
                if (op === 'ToJson') myMongoDBPost("MongoToJson", {collName: collName}, function (data) {
                    alert(data)
                });
                if (op === '删除') {
                    let YN = confirm("您确认要删除" + collName + "中的全部数据吗?");
                    if (YN) myMongoDBPost("RemoveDoc/" + collName, {}, function () {
                        that.Refresh()
                    })
                }
            },
            Repair(collName) {
                myMongoDBPost("Repair_fymc", {ItemCode: collName.split('-')[1]}, function (data) {
                    alert(data)
                });
            },
            ObjAttrName(x) {
                return ObjAttrName(x)
            },
            StampToTimeStr(x) {
                return StampToTimeStr(x).split(" ")[0].split("20")[1] + " " + StampToTimeStr(x).split(" ")[1].split(":")[0] + ":" + StampToTimeStr(x).split(" ")[1].split(":")[1];
            },
            DeleteCurrDoc(id) {
                let that = this;
                if (id !== undefined) myMongoDBPost("RemoveDoc/" + that.CurrCollName, {_id: id}, function () {
                    myMongoDBPost("GetDocs/" + that.CurrCollName, {}, function (data) {
                        that.dataForLook = data;
                        that.Show1 = false;
                        that.Refresh();
                    });
                })
            },
            AKeyBackupRestore(Bool) {
                if (Bool === 'Backup') myDataFileGet("AKeyBackupRestore/?OP=Backup", function (data) {
                    alert(data+ '残留垃圾需手动清理!');
                });
                if (Bool === 'Restore') myDataFileGet("AKeyBackupRestore/?OP=Restore", function (data) {
                    alert(data+ '残留垃圾需手动清理!');
                });
            },//一键备份
            DeleteAllUnusedVPs() {
                myDataFileGet("DeleteAllUnusedVPs", function (data) {
                    alert('提示：照片视频垃圾清理完毕!');
                });
            }
        }
    }
</script>

<style scoped>
    .RightTop {
        position: relative;
        height: 15px;
        top: -0.5rem;
        left: -0.6rem;
        margin-right: -1.2rem
    }

    .Css2 {
        display: inline-block;
        width: 29vw;
        text-align: center;
        color: #04597d;
        padding: 10px;
        margin: 10px;
        border: 1px solid #f1f4fa;
    }
</style>
