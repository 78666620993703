<template>
    <div class="MaskCss" :style="'z-index:'+MZindex"></div>
    <transition>
        <div v-if="Show7" class="PopBottWin" :style="'z-index:'+(Zindex+1)">
            <slot></slot>
        </div>
    </transition>
</template>
<script>
    export default {
        name: "PopBott",
        data() {
            return {
                Show7: false,
                MZindex: 0,
                Zindex: 0,
            }
        },
        mounted() {
            this.$store.commit('ChangeCurrZindex', this.$store.state.CurrZindex + 50);
            this.Zindex = this.$store.state.CurrZindex;
            this.MZindex = this.$store.state.CurrZindex - 2;
            this.Show7 = true;
        },
        beforeUnmount() {
            this.$store.commit('ChangeCurrZindex', this.$store.state.CurrZindex - 50);//切记10000和2000改小了,还不行
        },
    }
</script>
<style>
    .PopBottWin {
        background-color: #ffffff;
        position: fixed;
        left: 0;
        bottom: 0;
        min-width: 100vw;
        max-height:80vh;
        overflow: scroll;
    }
</style>

