<template>
    <div class="XLR YC BottGray">
        <div></div>
        <FYArea @FromFYArea="Refresh()"></FYArea>
        <div v-if="$store.state.UserName!=='游客'" class="WHcc XYC" @click="Active=2">
            <div class="LH100 AlignC WHaa BDCircle BGWhite TB BDBlue">
                <div class="T5">+</div>
                <div class="T2">视频</div>
            </div>
        </div>
    </div>
    <table>
        <thead>
        <tr>
            <th rowspan="2">视频<br>标识</th>
            <th rowspan="2">上传者<br>上传时间</th>
            <th colspan="2">需付泛约币</th>
            <th rowspan="2">状态<br>信息</th>
        </tr>
        <tr>
            <th>播放</th>
            <th>引用</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(SPDL,index) in SPDLs" :key="index">
            <td>
                <div v-if="SPDL.UserName===$store.state.UserName">
                    <ShowVFromThumb :Msg="{Video:SPDL.Video,Update:false}"></ShowVFromThumb>
                </div>
                <div v-else-if="SPDL.PlayedUsers.indexOf($store.state.UserName)<0">
                    <ShowVFromThumb :Msg="{Video:SPDL.Video,Update:false}" @FromChildV2="SPDLChanged('AddNewPlayer',index,null)"></ShowVFromThumb>
                </div>
                <div v-else-if="SPDL.PlayedTimes[SPDL.PlayedUsers.indexOf($store.state.UserName)]>2">
                    <ShowVFromThumb :Msg="{Video:SPDL.Video,Bool:'ShowThumbOnly'}"></ShowVFromThumb>
                </div>
                <div v-else>
                    <ShowVFromThumb :Msg="{Video:SPDL.Video,Update:false}" @FromChildV2="SPDLChanged('PlayedTimesInc',index,null)"></ShowVFromThumb>
                </div>
                <input v-if="$store.state.UserName===SPDL.UserName" class="W12" type="text" v-model="SPDL.VKey"
                       @focus="SPDL.VKey=null" @blur="SPDLChanged('VKey',index,SPDL.VKey)"/>
                <div v-else class="PA3 BDLightGray W12">{{SPDL.VKey}}</div>
            </td>
            <td class="AlignC">{{SPDL.UserName}}<br>{{SPDL.UpTime}}</td>
            <td><input v-if="$store.state.UserName===SPDL.UserName" class="W12" type="number" v-model.number="SPDL.PlayFYB"
                       @focus="SPDL.PlayFYB=null" @blur="SPDLChanged('PlayFYB',index,SPDL.PlayFYB)"/>
                <div v-else>{{SPDL.PlayFYB}}个<br>
                    <el-button type="primary" @click="SPDLChanged('PlayVideo',index,null)">播放</el-button>
                </div>
            </td>
            <td><input v-if="$store.state.UserName===SPDL.UserName" class="W12" type="number" v-model.number="SPDL.QuoteFYB"
                       @focus="SPDL.QuoteFYB=null" @blur="SPDLChanged('QuoteFYB',index,SPDL.QuoteFYB)"/>
                <div v-else>{{SPDL.QuoteFYB}}个
                    <div v-if="SPDL.QuoteUsers.indexOf($store.state.UserName)>=0" class="BDLightGray ">
                        <div class="BottGray">引用码：</div>
                        {{SPDL.CurrQuoteCode}}
                    </div>
                    <div v-else>
                        <el-button type="primary" @click="SeleI=index">引用码</el-button>
                    </div>
                </div>
            </td>
            <td>
                <div v-if="SPDL.CanDelete" class="XLR">&nbsp;
                    <div class="TE BDCircle BoxBR WH77 XYC" @click="Delete(index)">删</div>&nbsp;
                </div>
                <div class="NoWrap">播放：{{SPDL.PlayedUsers.length}}<br>引用：{{SPDL.QuoteCodes.length}}</div>
            </td>
        </tr>
        </tbody>
    </table>
    <br><br>
    <MyInput v-if="SeleI>=0" Title="请牢记您在此输入的引用码" Type="text" Begin="引用码" @FromMyI="MyInputVal"></MyInput>
    <UpFiles1 v-if="Active===2" VP="2" CallFrom="SP" @FromU1="AddV"></UpFiles1>
    <div v-if="CurrVideo!==''">
        <ShowVFullScreen :VideoSrc="CurrVideo" @FromShowV="CurrVideo=''"></ShowVFullScreen>
    </div>
</template>

<script>
    import {myMongoDBPost, myVodpost, KeepZX, StampToDate, Compare} from '@/components/SharedVues/Shared0.js'
    import FYArea from "../SharedVues/FYArea";
    import UpFiles1 from "../MyPVTs/UpFiles1";
    import ShowVFromThumb from "../MyPVTs/ShowVFromThumb";
    import ShowVFullScreen from "../MyPVTs/ShowVFullScreen";
    import MyInput from "../SharedVues/MyInput";
    import PopTop from "../MyPopWin/PopTop";
    //<input class="W12" type="number" v-model="SPDL.CurrQuoteCode" @blur="SPDLChanged('QuoteCode',SPDL.CurrQuoteCode)"/>
    export default {
        name: "SPDL",
        components: {PopTop, MyInput, ShowVFullScreen, ShowVFromThumb, UpFiles1, FYArea},
        data() {
            return {
                SeleI: -1,
                Active: 0,
                SPDLs: [],
                CurrVideo: ''
            }
        },
        methods: {
            VideoTryPlay() {
                console.log("VideoTryPlay");
            },
            Refresh() {
                let that = this;
                that.SPDLs = [];
                let TJ = {}, X = that.$store.state.FYArea, Y = X.split(":");
                if (X.split(":")[0] === 'P') TJ = {Province: Y[1]};
                if (X.split(":")[0] === 'C') TJ = {Province: Y[1], City: Y[2]};
                myMongoDBPost("GetDocs/SPDLs", TJ, function (Docs) {
                    console.log(Docs);
                    if (Docs !== '网络出错啦!' && Docs.length > 0) {
                        Docs.sort(Compare('Stamp')).reverse();
                        for (let i = 0; i < Docs.length; i++) {//为每一条文档增加三个临时字段CurrQuoteCode和CanDelete和UpTime
                            Docs[i].CurrQuoteCode = Docs[i].QuoteCodes[Docs[i].QuoteUsers.indexOf(that.$store.state.UserName)];
                            Docs[i].CanDelete = false;
                            let X = 0, L = Docs[i].QuoteBools.length;
                            for (let j = 0; j < L; j++) if (Docs[i].QuoteBools[j]) X++;
                            if (that.$store.state.UserName === Docs[i].UserName && (X === L || L === 0)) Docs[i].CanDelete = true;
                            Docs[i].UpTime = StampToDate(Docs[i].Stamp);
                        }
                        that.SPDLs = Docs;
                    }
                });
            },
            AddV(VideoId) {
                let that = this, SPDLDoc = {
                    UserName: this.$store.state.UserName,
                    Video: VideoId,
                    VKey: '',
                    Province: this.$store.state.UserP,
                    City: this.$store.state.UserC,
                    DC: this.$store.state.UserDC,
                    Stamp: (new Date()).getTime(),
                    PlayedUsers: [],
                    PlayedTimes: [],
                    PlayFYB: 0,
                    QuoteFYB: 0,
                    QuoteUsers: [],
                    QuoteCodes: [],
                    QuoteBools: []
                };
                that.Active = 0;
                if (VideoId === '未选择') alert("无效操作！"); else {
                    myMongoDBPost("PutDoc/SPDLs", SPDLDoc, function (res) {
                        that.Refresh();
                    })
                }

            },
            MyInputVal(Val) {
                if (Val === '引用码') alert('引用码不能是“引用码”这三个汉字！');
                else this.SPDLChanged('AddNewQuote', this.SeleI, Val);
            },
            SPDLChanged(Bool, index, Val) {
                let that = this, SPDL = this.SPDLs[index];
                if (Bool === 'VKey') SPDL.VKey = Val;
                if (Bool === 'PlayFYB') SPDL.PlayFYB = Val;
                if (Bool === 'QuoteFYB') SPDL.QuoteFYB = Val;
                if (Bool === 'QuoteCode') SPDL.QuoteCodes[SPDL.QuoteUsers.indexOf(that.$store.state.UserName)] = Val;
                if (Bool === 'AddNewQuote') {
                    SPDL.QuoteUsers.push(that.$store.state.UserName);
                    SPDL.QuoteCodes.push(Val);
                    SPDL.QuoteBools.push(false);
                    SPDL.CurrQuoteCode=Val;
                }
                if (Bool === 'AddNewPlayer') {
                    SPDL.PlayedUsers.push(that.$store.state.UserName);
                    SPDL.PlayedTimes.push(1);
                }
                if (Bool === 'PlayedTimesInc') {
                    SPDL.PlayedTimes[SPDL.PlayedUsers.indexOf(that.$store.state.UserName)]++;
                }
                if (Bool === 'PlayVideo') {
                    SPDL.PlayedTimes[SPDL.PlayedUsers.indexOf(that.$store.state.UserName)]++;
                    myVodpost("getMediaInfos", {FileId: SPDL.Video}, function (Obj) {
                        that.CurrVideo = Obj.MediaUrl;
                    });
                    if (SPDL.PlayFYB > 0) KeepZX(that, that.$store.state.UserName, '支', SPDL.PlayFYB, '播放收费视频', function () {
                    });
                }
                that.SPDLs.splice(index, 1, SPDL);
                myMongoDBPost("DocPutBack/SPDLs", SPDL, function () {
                    that.SeleI = -1;
                })
            },
            Delete(index) {
                let that = this;
                myVodpost("DeleteMedia", {FileId: that.SPDLs[index].Video}, function () {
                    myMongoDBPost("RemoveDoc/SPDLs", {_id: that.SPDLs[index]._id}, function () {
                        that.SPDLs.splice(index, 1);
                    })
                })
            }
        }
    }
</script>

<style scoped>

</style>