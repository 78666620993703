<template>
    <div>
        <div v-for="(x,index) in Msg2" :key="index">
            <transition name="slide-fade">
                <div v-if="Show===index" class="PA2 TG" @click="SetShow()">{{x}}</div>
            </transition>
        </div>
        <PopUpDown v-if="Show1" :Title="'取值范围管理器'" Pos="btt" :ANs="1" @FromPop5="SetOk">
            <div class="MP4 TF">{{Tips}}范围： {{N1}}-{{N2}}</div>
            <div class="XLR MP4">&nbsp;
                <div class="AlignC">
                    下限<br>
                    <input type="range" v-model="N1" min="0" :max="Joint" step="1" :style="'width:'+Joint+'vw'">
                </div>
                <div style="width: 0">{{Joint}}</div>
                <div class="AlignC">
                    上限<br>
                    <input type="range" v-model="N2" :min="Joint" max="99" step="1" :style="'width:'+ (99-Joint)+'vw'">
                </div>&nbsp;
            </div>
        </PopUpDown>
    </div>
</template>

<script>
    import PopTitle from "../MyPopWin/PopTitle";
    import PopUpDown from "../MyPopWin/PopUpDown";
    import {CloneObject} from "./Shared0"

    export default {
        name: "MySelectRange",
        components: {PopUpDown, PopTitle},
        props: ['Msg'],
        data() {
            return {
                Msg2:null ,
                Show: 0,
                Show1: false,
                N1: 15,
                N2: 70,
                Joint: 40,
                Tips: ''
            }
        },
        mounted() {
            this.Msg2=CloneObject(this.Msg);
            if (this.Msg[0] === '年龄不限') this.Tips = "当前年龄"; else this.Tips = "当前数值"
        },
        watch: {
            N1(Val) {
                if (Val >= this.Joint - 6) this.Joint += 6;
                if (this.Joint > 60) this.Joint = 60;
            },
            N2(Val) {
                if (Val <= this.Joint + 6) this.Joint -= 6;
                if (this.Joint < 8) this.Joint = 10;
            }
        },
        methods: {
            SetShow() {
                this.Show++;
                if (this.Show === 1) this.Show1 = true;
                //console.log("this.Show="+this.Show+"  this.Msg.length="+this.Msg.length+"   this.Show1="+this.Show1)
                if (this.Show === this.Msg.length) {
                    this.Show = 0;
                    this.$emit("eventFromChild", this.Msg[0]);
                }
            },
            SetOk() {
                this.Show1 = false;
                this.Msg2[1] = '' + this.N1 + "-" + this.N2;
                this.$emit("eventFromChild", '' + this.N1 + "-" + this.N2);
            },
        }
    }
</script>

<style scoped>


    input[type=range] {
        margin-top: 8px;
        outline: none;
        -webkit-appearance: none; /*清除系统默认样式 width:56% !important;*/

        background: -webkit-linear-gradient(#979797, #5d5d5d) no-repeat, #ddd;
        /*设置左右宽度比例background-size: 30% 100%;*/
        height: 1px; /*横条的高度*/
    }

    /*拖动块的样式*/
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none; /*清除系统默认样式*/
        height: 24px; /*拖动块高度*/
        width: 24px; /*拖动块宽度*/
        background: #fff; /*拖动块背景*/
        border-radius: 50%; /*外观设置为圆形*/
        border: solid 1px #ddd; /*设置边框*/
    }

</style>
