<template>
    <div class="MT2">
        <MyDivider v-if="ActDoc.EndBool===3" :ShowStr="'『'+ActDoc.JFPool+'』约球碰撞报名表'"></MyDivider>
        <MyDivider v-else :ShowStr="ActDoc.Title"></MyDivider>
        <div v-if="ActDoc.ActStyle.indexOf('单场地') >= 0" class="XLR YC MP1">
            积分场地：{{ActDoc.JFPool}}
            <div>
                当前积分：{{MyCurrJF}}
            </div>
        </div>
        <table class="MT2">
            <thead>
            <tr class="TC LH150">
                <th>日期</th>
                <th>星期</th>
                <th>上午</th>
                <th>下午</th>
                <th>晚上</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(CD,index1) in CurrDays" :key="index1">
                <td>{{CD.ThisDate}}</td>
                <td>{{CD.Week}}</td>
                <td v-for="(Noon,index2) in CD.Noons" :key="index2">
                    <div @click="MyBM1(index1,index2)" class="PA1 LH120">
                        <div v-if="Noon==='―'" class="AN ANGray">报 名</div>
                        <div v-else class="AN ANGreen">{{Noon}}</div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <p class="TF T3 PA2">备注：每个时间窗的具体开打时间参见活动规则。</p>
        <br>
        <PopTitle v-if="I1>-1 && NoonIndex>-1"
                  :Title="CurrDays[I1].ThisDate+NoonIndexes[NoonIndex]+'报名框'" @FromPopUp="RefreshCurrDays()">
            <div v-if="MatchSites.length>0">
                <div class="MT2" v-for="(MS,index1) in MatchSites" :key="index1">
                    <div v-if="MS.SiteShortName==='内部'" class="BottGray W50 PA3 TF T2">
                        <div v-if="ActDoc.ActForm==='单打'">已报名人员：</div>
                        <div v-if="ActDoc.ActForm==='双打'">已报名组合：</div>
                        <div v-if="ActDoc.ActForm==='团体'">已报名队别：</div>
                    </div>
                    <div v-else class="BottGray W50 PA3 XLR YC">
                        <img src="../../../static/gif2/location.gif" @click="ShowMapI1=index1" class="WH99 MT2">
                        <div>{{MS.SiteShortName}}：</div>
                        &nbsp;
                        <div></div>
                    </div>
                    <div v-if="ActDoc.ActForm==='单打'" class="XLR YC MT2">&nbsp;
                        <div class="XL YC">
                            <div class="XL YC PA2" v-for="(BM,index2) in MS.BMs" :key="index2">
                                <img v-if="MS.UserLogos[index2] && MS.UserLogos[index2].length>0"
                                     :src="'UserUpload/1/'+MS.UserLogos[index2]" @click="ShowUserLogo=MS.UserLogos[index2]"
                                     class="AvatarWH"/>
                                <img v-else :src="'UserUpload/static/After2021/Head.jpg'" class="AvatarWH"/>
                                <div class="ML2">
                                    {{BM}}<br>
                                    <div class="TF T3">{{MS.Sexes[index2]}}&nbsp;&nbsp;{{MS.Ages[index2]}}岁</div>
                                    <div v-if="$store.state.UserName===BM || $store.state.UserName===ActDoc.UserName" class="AlignC TG"
                                         @click="CancelBM(index1,index2)">
                                        [ <span class="TE">撤</span> ]
                                    </div>
                                </div>
                            </div>
                            <div v-if="CanAdd" class="AN ANBlue T9 MT2" @click="MyBM2(index1)" style="font-weight: bolder">报名</div>
                        </div>
                                                                         &nbsp;
                    </div>
                    <div v-if="ActDoc.ActForm==='双打' || ActDoc.ActForm==='团体'" class="MT2">
                        <table :cell-bordered="true" :content-bordered="true" style="background-color:#fff;">
                            <thead>
                            <tr class="TF">
                                <th>序号</th>
                                <th>记分代表</th>
                                <th>{{ActDoc.ActForm==='双打'?'双打组合':'团体队别'}}名称</th>
                                <th>操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(BM,index2) in MS.BMs" :key="index2">
                                <td>{{index2+1}}</td>
                                <td>{{BM}}</td>
                                <td>{{MS.BM2s[index2]}}</td>
                                <td>
                                    <div v-if="$store.state.UserName===BM || $store.state.UserName===ActDoc.UserName" class="AlignC TG"
                                         @click="CancelBM(index1,index2)">
                                        <span class="TE">撤</span>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-if="CanAdd" class="XLR YC MP4">
                            <div>
                                {{ActDoc.ActForm==='双打'?'双打组合':'团体队别'}}：
                                <input type="text" class="W26" v-model="MS.TempStr"/>
                            </div>
                            <div class="AN ANBlue" @click="MyBM2(index1)" style="font-weight: bolder">报名</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="MP4 TC">
                <p>亲，当前找不到合适的PK对手。您可以设置您的喜好比赛场地，然后，再回到这里发布您的等待PK状态。具体方法步骤如下：</p>
                <p>1.返回到首页，寻找［添加与设置］按钮，点击进入。</p>
                <p>2.找到您的喜好项目，如：乒乓球。然后点击［喜好场地］按钮进入喜好场地设置页面。</p>
                <p>3.圈选您喜欢光临的场地后返回。</p>
            </div>
            <FYMap v-if="ShowMapI1>-1"
                   :Msg="{Name:MatchSites[ShowMapI1].SiteName,Addr:MatchSites[ShowMapI1].Address,Lati:MatchSites[ShowMapI1].Lati,Longi:MatchSites[ShowMapI1].Longi}"
                   OP="Look" @FromFYMap2="ShowMapI1=-1"></FYMap>
            <div v-if="ShowUserLogo!==''">
                <img :src="'UserUpload/1/'+ShowUserLogo" @click="ShowUserLogo=''"
                     style="position:fixed;left:0;top:0;width: 100vw;height:auto"/>
            </div>
        </PopTitle>
    </div>
</template>

<script>
    import {myMongoDBPost, StampToDate} from '@/components/SharedVues/Shared0.js'


    import PopTitle from "../MyPopWin/PopTitle";
    import FYMap from "../SharedVues/FYMap";
    import MyDivider from "../SharedVues/MyDivider";
    import SetGetOneMySite from "../M4_Sites/SetGetOneMySite";
//v-if="ActDoc.ActStyle.indexOf('单场地') >= 0 && MyCurrJF>=0 || ActDoc.ActStyle.indexOf('单场地')===-1"
    export default {
        name: "Act0BM",
        components: {SetGetOneMySite, MyDivider, FYMap, PopTitle,},
        props: ['ActDoc'],
        data() {
            return {
                MyCurrJF: 0,
                CurrDays: [],
                MatchSites: [],
                Weeks: ['日', '一', '二', '三', '四', '五', '六'],
                I1: -1,
                NoonIndex: -1,
                NoonIndexes: ['上午', '下午', '晚上'],
                MyCurrSite: '―',
                CanAdd: true,
                ShowMapI1: -1,
                ShowUserLogo: ''
            }
        },
        mounted() {
            let Today = (new Date()).getTime();
            for (let i = 0; i < 10; i++) {
                let asd = Today + i * 24 * 3600 * 1000;
                this.CurrDays.push({
                    ThisDate: StampToDate(asd),
                    Week: this.Weeks[(new Date(asd)).getDay()],
                    Noons: ['―', '―', '―']
                });
            }
            this.RefreshCurrDays();
            this.GetMyCurrJF();

        },
        methods: {
            GetMyCurrJF() {
                let that = this,
                    aOBJ = {UserName: that.$store.state.UserName, ItemStr: this.ActDoc.ItemStr, "MySites.SiteShortName": this.ActDoc.JFPool};
                myMongoDBPost("GetDocs/byset0", aOBJ, function (Docs) {
                    if (Docs.length > 0) for (let i = 0; i < Docs[0].MySites.length; i++)
                        if (Docs[0].MySites[i].SiteShortName === that.ActDoc.JFPool) that.MyCurrJF = Docs[0].MySites[i].JF;
                })
            },
            RefreshCurrDays() {
                let that = this;
                myMongoDBPost("Act0RefreshCurrDays", {
                    ActId: that.ActDoc._id,
                    CurrDays: that.CurrDays,
                    UserName: that.$store.state.UserName
                }, function (Docs) {
                    that.CurrDays = Docs;
                    that.I1 = that.NoonIndex = -1;
                });
            },
            MyBM1(I1, NoonIndex) {
                if (this.$store.state.UserName === '游客') alert('游客报名无效，请返回后注册或登录!'); else {
                    let that = this, I = this.$store.state.UserName,
                        TimeStr = this.CurrDays[I1].ThisDate + ":" + this.CurrDays[I1].Week + ":" + NoonIndex;
                    //以下开始获取有效场地
                    myMongoDBPost("Act0GetMatchSites", {//其中包含写入act0的操作
                        ActId: this.ActDoc._id,
                        TimeStr: TimeStr,
                        UserName: this.$store.state.UserName,
                        ItemStr: this.ActDoc.ItemStr,
                        NoonIndex: NoonIndex,
                        SiteBool: this.ActDoc.ActStyle.substr(0, 3)
                    }, function (Docs) {
                        that.MatchSites = Docs;
                        console.log(that.MatchSites)
                        that.MyCurrSite = '―';
                        that.CanAdd = true;
                        for (let i = 0; i < that.MatchSites.length; i++)
                            for (let j = 0; j < that.MatchSites[i].BMs.length; j++)
                                if (that.MatchSites[i].BMs.indexOf(I) >= 0) that.CanAdd = false, that.MyCurrSite = that.MatchSites[i].SiteShortName;
                        that.I1 = I1;
                        that.NoonIndex = NoonIndex;
                    })
                }
            },
            UpdateBM(index1, aOBJ) {
                let that = this;
                that.MatchSites[index1].BMs = [];
                that.MatchSites[index1].BM2s = [];
                that.MatchSites[index1].UserLogos = [];
                that.MatchSites[index1].Sexes = [];
                that.MatchSites[index1].Ages = [];
                myMongoDBPost("Act0BM", aOBJ, function (Doc) {
                    that.CanAdd = !that.CanAdd;
                    that.MatchSites[index1].BMs = Doc.BMs;
                    that.MatchSites[index1].BM2s = Doc.BM2s;
                    that.MatchSites[index1].UserLogos = Doc.UserLogos;
                    that.MatchSites[index1].Sexes = Doc.Sexes;
                    that.MatchSites[index1].Ages = Doc.Ages;
                });
            },//此处，隐含着并发访问问题的解决
            MyBM2(index1) {
                if (this.MyCurrSite === '―') this.MyCurrSite = this.MatchSites[index1].SiteShortName;
                else if (this.MyCurrSite !== this.MatchSites[index1].SiteShortName) return alert('特别提醒：在同一时间窗下不同场地上报名无效!');
                this.UpdateBM(index1, {
                    Bool: '追加',
                    ActForm: this.ActDoc.ActForm,
                    BM2: this.MatchSites[index1].TempStr,
                    _id: this.MatchSites[index1].Act0Id,
                    BM: this.$store.state.UserName,
                    UserLogo: this.$store.state.UserLogo,
                    Sex: this.$store.state.Sex,
                    Age: this.$store.state.Age
                })
            },
            CancelBM(index1, index2) {
                this.UpdateBM(index1, {
                    Bool: '删除',
                    ActForm: this.ActDoc.ActForm,
                    _id: this.MatchSites[index1].Act0Id,
                    BM: this.MatchSites[index1].BMs[index2],
                    BM2: this.MatchSites[index1].BM2s[index2],
                    UserLogo: this.MatchSites[index1].UserLogos[index2],
                    Sex: this.MatchSites[index1].Sexes[index2],
                    Age: this.MatchSites[index1].Ages[index2],
                })
            }
        }
    }
</script>

<style scoped>
</style>

