import {createApp} from 'vue'
import App from './App.vue'
//import router from './router'
import store from './vuex/store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App);
app.config.errorHandler = (err, vm, info) => {
    console.error('main.js>Error:', err);
    console.error('Vue instance:', vm);
    console.error('Info:', info)
};
app.use(store);
//app.use(router);
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app');