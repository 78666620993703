<template>
    <div v-if="IsLoading">IsLoading...</div>
    <div class="BottO">
        <TimeSexAge :BYs="BYs" CallFrom="3" @FromChild="RefreshBYs"></TimeSexAge>
    </div>
    <div v-if="!IsLoading">
        <div v-for="(x,index) in BYs" :key="index" class="BottO MT2">
            <div v-if="x.ShowBool" :class="'T3 '+(x.SelectedBool ? 'TG':'TF')">
                <div class="XLR YC">
                    <img v-if="x.UserLogo" :src="'UserUpload/1/S'+x.UserLogo" @click="SeleI=index" class="AvatarWH"/>
                    <img v-else :src="'UserUpload/static/After2021/Head.jpg'" class="AvatarWH"/>
                    <div>
                        <div class="TC T4 NoWrap">{{x.fyUserName}}</div>
                        <div class="XLR YC">
                            <el-badge v-if="x.AppearanceFee>0" :value="x.AppearanceFee"></el-badge>
                            <div>{{x.sex}}&nbsp;&nbsp;{{x.age}}</div>
                            <UserFace3 :Certified="x.Certified"></UserFace3>
                        </div>
                    </div>
                    <div>
                        <div>{{x.domicile}}<br> 人气:{{x.PopularityCredit.toFixed(2)}}</div>
                        <ShowPVT :PVT="{P:x.fyUserPic,V:x.fyUserVideo,T:x.fyUserText,Update:false}"></ShowPVT>
                    </div>
                    <div class="AlignC">
                        <input type="checkbox" class="aui-switch" v-model="x.SelectedBool" @change="bySelect(x.SelectedBool,x.fyUserName,x.AppearanceFee)"/>
                        <br><br>
                        <ShowTimeWin :TW="x.TimeWinState"></ShowTimeWin>
                    </div>
                </div>
                <div v-if="1===1">头衔:{{x.Honor}}<br>擅长:{{x.GoodAt}}</div>
            </div>
        </div>
    </div>
    <img v-if="SeleI!==-1" :src="'UserUpload/1/'+BYs[SeleI].UserLogo" @click="SeleI=-1" class="FullScr2"/>
</template>

<script>
    import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import TimeSexAge from "./TimeSexAge";
    import ShowTimeWin from "./ShowTimeWin";
    import ShowPVT from "@/components/MyPVTs/ShowPVT";
    import mixinsBySelected from '@/components/ListYY/mixinsBySelected'
    import PopTitle from "@/components/MyPopWin/PopTitle";

    import UserFace3 from "../SharedVues/UserFace3";

    export default {
        name: "TM3bySele",
        components: {
            UserFace3,
            PopTitle,
            TimeSexAge,
            ShowTimeWin,
            ShowPVT
        },
        mixins: [mixinsBySelected],
        data() {
            return {
                Show: -1,
                IsLoading: true,//数据准备不就绪,就不让它显示
                fyUserName: this.$store.state.UserName,
                BYs: [],
                SelectedBYs: [],
                T2Bool: true,
                SeleI: -1
            }
        },
        mounted() {
            this.GetBYs();
        },
        methods: {
            GetBYs() {//注意:不带入口参数
                this.IsLoading = true;
                let that = this;
                let aObj = {
                    fyUserName: this.$store.state.UserName,
                    ItemStr: this.$store.state.CurrItemStr,
                    AreaStr: this.$store.state.FYArea
                };
                myMongoDBPost("TBYQReadByListData", aObj, function (byList) {
                    that.SelectedBYs = [];
                    that.BYs = byList;
                    if (that.BYs.length === 0) that.$emit("eventFromTM", 'NoBY'); else that.$emit("eventFromTM", that.SelectedBYs);
                    that.IsLoading = false;
                });
            },
            RefreshBYs(Val) {
                this.BYs = Val;
            }
        }
    }
</script>

<style scoped>
    
    .Css1 {
        width: 10vw;
        text-align: center
        }
    
    .Css4 {
        width: 20vw;
        text-align: left;
        overflow: hidden;
        }
    
    .Css3 {
        width: 5vw;
        text-align: center
        }
</style>
