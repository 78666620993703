import {myMongoDBPost} from "../SharedVues/Shared0";

function GetEndBool3Act(SiteShortName, ItemStr, CurrFYArea, CallBack) {
    myMongoDBPost("GetDocs/Acts", {JFPool: SiteShortName, EndBool: 3}, function (Docs) {//约球碰撞
        if (Docs.length > 0) CallBack(Docs[0]);else {
            let ActDoc = {
                Title: SiteShortName + "日常碰撞式约球时间表",//活动名称或标题
                HoldStyle: '开放式',
                ActForm: '单打',
                Scale: '不限人数',//活动规模
                ScaleVal: -1,// 活动参数:不限人数型=-1;额满为止型=限定人数;特许参加型=纯数字的特许口令
                ActStyle: '单场地单循环',
                ItemStr: ItemStr,
                JFPool: SiteShortName,
                BMList: [],
                Province: CurrFYArea.split(":")[1],
                City: CurrFYArea.split(":")[2],
                DC: CurrFYArea.split(":")[3],
                TimeStamp: 0,
                EndBool: 3
            };
            myMongoDBPost("PutDoc/Acts", ActDoc, function (data) {
                ActDoc._id = data._id;
                CallBack(ActDoc);
            })
        }
    })
}//

export {GetEndBool3Act}