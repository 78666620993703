<template>
    <MyDivider ShowStr="场地基本信息"></MyDivider>
    <div class="LH150 MLR2">
        <div class="XL YC">
            简称：
            <input type="text" class="MP1 W26" v-model="CurrSite.SiteShortName" @blur="SaveCurrSiteDoc"/><span class="TF">（最长５个汉字）</span>
        </div>
        <div>属地：<span class="TF">{{CurrSite.Country}}{{CurrSite.Province}}{{CurrSite.City}}{{CurrSite.DC}}</span></div>
        <div class="XL YC">
            详址：
            <input type="text" class="MP4 W60" v-model="CurrSite.Address" @blur="SaveCurrSiteDoc"/>
        </div>
        <div class="XLR YC MT2">
            <div class="XLR YC" @click="ShowMap=1">地图定位：<img src="../../../static/img/SY/Map1.png"
                                                             class="WHcc BD_Radius1 BDGreen">
            </div>
            <div class="AN ANBlue" @click="ShowMap=2">重新<br>定位</div>&nbsp;
        </div>
        
        <table class="MT2">
            <tr>
                <th rowspan="2">场地管理员</th>
                <th colspan="2">约课器管理</th>
            </tr>
            <tr>
                <th>开通</th>
                <th>删除</th>
            </tr>
            <tr v-for="(CS,index) in CurrSite.SiteAdmins" :key="index">
                <td><input type="text" v-model="CurrSite.SiteAdmins[index]" @blur="UpdateSiteAdmin(CS,index)" class="W26"/></td>
                
                <td class="W26 AlignC">
                    <div class="AN ANGreen" @click="AddSiteUsers(CS)">开通</div>
                </td>
                <td class="W26">
                    <div class="TE MP4" @click="DelSiteUsers(CS)">删除</div>
                </td>
            </tr>
        </table>
        
        <div class="MT2">
            实时滚动信息：
            <input class="W90 MT2" type="text" v-model="CurrSite.ScrollTexts[0]"
                   @focus="CurrSite.ScrollTexts[0]=null"/><br>
            <input class="W90 MT2" type="text" v-model="CurrSite.ScrollTexts[1]"
                   @focus="CurrSite.ScrollTexts[1]=null"/><br>
            <input class="W90 MT2" type="text" v-model="CurrSite.ScrollTexts[2]" @focus="CurrSite.ScrollTexts[2]=null"/>
        </div>
        <table :cell-bordered="true" :content-bordered="true" style="background-color:#fff;">
            <thead>
            <tr class="TF H3">
                <th>
                    <div class="T3 NoWrap">门脸照片&nbsp;步行路线&nbsp;场地概况</div>
                </th>
                <th>电话</th>
            </tr>
            </thead>
            <tbody>
            <tr class="H3">
                <td>
                    <div class="MLR4 MT2">
                        <ShowUpdatePVT
                                :PVTF="{P:CurrSite.Pic,V:CurrSite.Video,T:CurrSite.Remark, TextTitle:'场地情况简要介绍'}"
                                @ShowUT="CallBack0"></ShowUpdatePVT>
                    </div>
                </td>
                <td>
                    <input type="text" class="W32" v-model="CurrSite.Telephone" @blur="SaveCurrSiteDoc()"/>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <FYMap v-if="ShowMap===1" OP="Look"
           :Msg="{Name:CurrSite.SiteName,Addr:CurrSite.Address,Lati:CurrSite.Lati,Longi:CurrSite.Longi}"
           @FromFYMap2="ShowMap=-1"></FYMap>
    <FYMap v-if="ShowMap===2" OP="Edit" :Msg="{Name:CurrSite.SiteName,Addr:CurrSite.Address}"
           @FromFYMap2="SaveCurrPosi"></FYMap>
    
    
    <MyDivider ShowStr="本场地可开展项目"></MyDivider>
    <table v-if="CurrSite.Items.length>0" :cell-bordered="true" :content-bordered="true" style="background-color:#fff;">
        <thead>
        <tr class="TF">
            <th rowspan="2">序号</th>
            <th rowspan="2">项目名称</th>
            <th rowspan="2">场地情况</th>
            <th rowspan="2">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(Item,index) in CurrSite.Items" :key="index" style="height: 3.5rem">
            <td class="MT2">{{index+1}}</td>
            <td class="MT2">{{Item.ItemName}}</td>
            <td @click="SeleI =index">
                <div class="MLR4 MT2">
                    <ShowUpdatePVT :PVTF="{P:Item.Pic,V:Item.Video,T:Item.Remark, TextTitle:'场地情况简要介绍'}"
                                   @ShowUT="CallBack"></ShowUpdatePVT>
                </div>
            </td>
            <td class="MT2">
                <div class="TE" @click="DeleItem(index)">删除</div>
            </td>
        </tr>
        </tbody>
    </table>
    <div class="XR PA2">
        <div class="AN ANBlue" @click="Show1=true">添加</div>
    </div>
    <PopBott v-if="Show1">
        <SeleOneItem Title="项目名称选择" @FromSeleOneItem="AppendItem"></SeleOneItem>
    </PopBott>
    <p v-if="CurrSite.Pic.length==='' || CurrSite.Remark===''">备注：场地概况照片与文字必须要有！</p>
    <WXLink v-else :WLandTo="'TJSite_' + CurrSite._id" :Pic="'UserUpload/1/S'+CurrSite.Pic"
            :WTitle="'乒乓球场地推荐：'+CurrSite.SiteName" :WText="CurrSite.Remark"></WXLink>
</template>

<script>

    import { CloneObject, GetItemType, myMongoDBPost} from '@/components/SharedVues/Shared0.js'

    import PopTitle from "@/components/MyPopWin/PopTitle";
    import SeleOneItem from "../FrontPage/SeleOneItem";
    import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";
    import MyToast from "@/components/SharedVues/MyToast";
    import FYMap from "@/components/SharedVues/FYMap";
    import MyInput from "@/components/SharedVues/MyInput";
    import PopUpDown from "../MyPopWin/PopUpDown";
    import WXLink from "../IndependentVues/WXLink";
    import MySelectStr from "../SharedVues/MySelectStr";
    import MyDivider from "../SharedVues/MyDivider";
    import PopBott from "../MyPopWin/PopBott";

    export default {
        name: "SiteEdit",

        components: {
            PopBott,
            MyDivider,
            MySelectStr,
            WXLink,
            PopUpDown,
            MyInput,
            FYMap,
            MyToast,
            ShowUpdatePVT,
            SeleOneItem,

            PopTitle
        },
        props: ['CurrSiteDoc'],
        data() {
            return {
                CurrSite: this.CurrSiteDoc,
                aItemObj: {
                    ItemType: '',
                    ItemCode: '',
                    ItemName: '项目名称',
                    Pic: '',
                    Video: '',
                    Remark: ''
                },
                SeleI: -1,
                Show1: false,
                ShowMap: -1,
                InputI: -1,
                InputI1: -1,
                InputI2: -1,
            }
        },
        mounted() {
            this.CurrSite = this.CurrSiteDoc;
        },
        methods: {
            UpdateSiteAdmin(CAdminUser, index) {
                this.CurrSite.SiteAdmins.splice(index, 1, CAdminUser);
                this.SaveCurrSiteDoc();
            },
            AddSiteUsers(CAdminUser) {
                let that = this;
                myMongoDBPost("GetDocs/SiteUsers", {
                    CAdminUser: CAdminUser,
                    DocBool: '课程管理员'
                }, function (Docs) {
                    let aObj = {
                        SiteId: that.CurrSite._id,
                        Province: that.CurrSite.Province,
                        City: that.CurrSite.City,
                        DC: that.CurrSite.DC,
                        SiteName: that.CurrSite.SiteName,//对应场地的名称
                        SiteShortName: that.CurrSite.SiteShortName,
                        CAdminUser: CAdminUser,
                        MyCurrBool: true,
                        DocBool: '课程管理员'
                    };
                    if (Docs.length === 0) myMongoDBPost("PutDoc/SiteUsers", aObj, function (Docs) {
                        alert('提示：已开通“约课器”')
                    }); else alert('每个课程管理员只允许开通1套课表，若想管理多套课表,请再注册1个用户名!')
                });
            },
            DelSiteUsers(CAdminUser) {//遗留问题:照片和视频的删除问题
                myMongoDBPost("RemoveDoc/SiteUsers", {CAdminUser: CAdminUser}, function () {
                    myMongoDBPost("RemoveDoc/Schedules", {CAdminUser: CAdminUser}, function () {
                        alert( '删除完毕!')
                    });
                });
            },
            SaveCurrPosi(aObj) {
                if (aObj.CanUse) {
                    this.CurrSite.Longi = aObj.Longi;
                    this.CurrSite.Lati = aObj.Lati;
                    this.CurrSite.SiteShortName = this.CurrSite.SiteShortName.slice(0, 5);//留头不留尾，也就是截取的子串包含indexStart，不包含indexEnd

                    myMongoDBPost("DocPutBack/fysites", this.CurrSite, function () {
                    })
                    this.ShowMap = -1;
                }
            },
            DeleItem(index) {
                this.CurrSite.Items.splice(index, 1);
            },
            AppendItem(Val) {
                this.Show1 = false;
                this.aItemObj.ItemType = GetItemType(Val.split(":")[0]);//此时:Val=this.$store.state.CurrItemStr
                this.aItemObj.ItemCode = Val.split(":")[0];
                this.aItemObj.ItemName = Val.split(":")[1];
                let asd = CloneObject(this.aItemObj), Finded = false;
                if (this.aItemObj.ItemName === '项目名称') alert("未选择项目名称"); else {
                    for (let i = 0, len = this.CurrSite.Items.length; i < len; i++)
                        if (this.CurrSite.Items[i].ItemName === asd.ItemName) Finded = true;
                    if (Finded) alert("不允许项目重复"); else this.CurrSite.Items.push(asd);
                }
                this.SaveCurrSiteDoc();
            },
            SaveCurrSiteDoc() {
                myMongoDBPost("DocPutBack/fysites", this.CurrSite, function () {
                })
            },
            Quit() {
                this.$emit("FromSiteEdit");
            },
            CallBack0(Val) {
                if (Val.P) this.CurrSite.Pic = Val.P;
                if (Val.V) this.CurrSite.Video = Val.V;
                if (Val.T) this.CurrSite.Remark = Val.T;
                this.SaveCurrSiteDoc();
            },
            CallBack(Val) {
                if (Val.P) this.CurrSite.Items[this.SeleI].Pic = Val.P;
                if (Val.V) this.CurrSite.Items[this.SeleI].Video = Val.V;
                if (Val.T) this.CurrSite.Items[this.SeleI].Remark = Val.T;
                this.SaveCurrSiteDoc();
            }
        }
    }
</script>

<style scoped>
    .W22 {
        width: 22vw;
        }
    
    .W25 {
        width: 25vw;
        }
    
    .W12 {
        width: 12vw;
        }
</style>
