<template>
  <div>
    <img v-if="PV==='P'" src='../../../static/img/Media/PicColor.png' class="WH99" @click="Active=true,VPBool='1'">
    <img v-if="PV==='V'" src='../../../static/img/Media/VideoColor.png' class="WH99" @click="Active=true,VPBool='2'">
    <UpFiles1 v-if="Active " :VP="VPBool" CallFrom="PV" @FromU1="UpEnd"></UpFiles1>
  </div>
</template>

<script>

  import UpFiles1 from "./UpFiles1";

  export default {
    name: "UploadPV",
    components: {UpFiles1},
    props: ['PV'],
    data() {
      return {
        Active: false,
        VPBool:''
      }
    },
    methods: {
      UpEnd(VAL) {
        this.Active=false;
        if (VAL === '未选择') this.$emit("FromUploadPV", ''); else this.$emit("FromUploadPV", VAL);
      }
    }
  }
</script>

<style scoped>
</style>
