<template>
    <div class="XLR YC">
        <div class="BDRight">
            <MySelectStr :CallFrom="CallFrom" :Msg="['性别','男','女']" :CurrN="0" @eventFromChild="RefreshBYs2"></MySelectStr>
        </div>
        <div class="BDRight">
            <MySelectRange :CallFrom="CallFrom" :Msg="['年龄',Age+'岁']" @eventFromChild="RefreshBYs3"></MySelectRange>
        </div>
        <div v-if="CallFrom==='0'" class="BDRight">
            <MySelectStr :CallFrom="CallFrom" :Msg="['积分','相近','>0','>100']" :CurrN="0" @eventFromChild="RefreshBYs4"></MySelectStr>
        </div>
        <div class="BottGray TG NoWrap" @click="Show3 = true">
            &nbsp;&nbsp;{{TimeWinSele.Show===''?' 空闲匹配 ':TimeWinSele.Show}}&nbsp;&nbsp;
        </div>
    </div>
    <PopMenu v-if="Show3" :Menus="['某日时段', '某年某月', '节假日']" :BeginN="0" @FromPopMenu="PopMenuVal">
        <TimeWindowSet :Msg="{CallFrom:'单选'}" :TypeSele="TypeSele" @eventFromTWS="SetTimeWindowSele"></TimeWindowSet>
    </PopMenu>
</template>

<script>
    import MySelectStr from "@/components/SharedVues/MySelectStr";
    import MySelectRange from "@/components/SharedVues/MySelectRange";
    import PopMenu from "../MyPopWin/PopMenu";
    import TimeWindowSet from "../SharedVues/TimeWindowSet";

    export default {
        name: "TimeSexAge",
        components: {TimeWindowSet, PopMenu, MySelectStr, MySelectRange},
        props: ['BYs', 'CallFrom', 'ZyJF'],
        data() {
            return {
                TimeWinSele: {Show: '', SubStr: '初值'},
                Show3: false,
                Sex: '',
                Age: '10-80',
                JFStr: '积分',
                CurrN: 0
            }
        },
        mounted() {
            this.RefreshBYs();
        },
        methods: {
            RefreshBYs() {
                let that = this;
                //console.log(typeof []);
                if ((typeof this.BYs) === 'string') return;
                this.BYs.forEach(function (aDoc) {
                    let TJ1 = (aDoc.timeWindow.split(";").indexOf(that.TimeWinSele.SubStr) >= 0), TJ2 = true, TJ3 = true, TJ4 = true;
                    if (TJ1) aDoc.TimeWinState = "开放"; else aDoc.TimeWinState = "关闭";
                    if (aDoc.timeWindow === '' || that.TimeWinSele.SubStr === '初值') TJ1 = true;//若aDoc.timeWindow==='',则表示未设时间窗
                    if (that.Sex === '男' || that.Sex === '女') TJ2 = (aDoc.sex === that.Sex);
                    if (that.Age !== '年龄') TJ3 = (aDoc.age >= parseInt(that.Age.split("-")[0]) && aDoc.age <= parseInt(that.Age.split("-")[1]));
                    if (that.JFStr !== '积分') {
                        if (that.JFStr === '>0') TJ4 = aDoc.JF > 0;
                        if (that.JFStr === '>100') TJ4 = aDoc.JF > 100;
                        if (that.JFStr === '相近') TJ4 = Math.abs(aDoc.JF - that.ZyJF) < 2;
                    }
                    //if (that.$store.state.LandTo.split("XMYY").length>2) TJ5=aDoc.AppearanceFee>0;//对赌时只显示设了对赌泛约币的
                    aDoc.ShowBool = TJ1 && TJ2 && TJ3 && TJ4;// && TJ5;
                });
                console.log(this.BYs);
                this.$emit("FromChild", this.BYs);
            },
            SetTimeWindowSele(Val) {
                this.Show3 = false;
                if (Val === -1) {
                    this.TimeWinSele.Show = '';
                    this.TimeWinSele.SubStr = '初值';
                } else {
                    this.TimeWinSele.Show = Val.showValue;
                    this.TimeWinSele.SubStr = Val.countValue;
                }
                this.$emit("FromChild", this.BYs);
            },

            RefreshBYs2(Val) {
                this.Sex = Val;
                this.RefreshBYs()
            },
            RefreshBYs3(Val) {
                this.Age = Val;
                this.RefreshBYs()
            },
            RefreshBYs4(Val) {
                this.JFStr = Val;
                this.RefreshBYs()
            },
            PopMenuVal(Val) {
                if (Val === -1) {
                    this.Show3 = false;
                    this.TimeWinSele.Show = '';
                    this.TimeWinSele.SubStr = '初值';
                    this.$emit("FromChild", this.BYs)
                } else this.TypeSele = Val;
            }
        }
    }
</script>

