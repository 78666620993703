<template>
    <PopUpDown :Title="Msg.Channel+'>'+Msg.TopKey+'>'+Msg.SideKey" Pos="btt" :ANs="2" @FromPop5="SaveCurrQYDoc">
        <div class="MP4">
            <div class="XL YC">区域范围：
                <FYArea @FromFYArea="SetQYPCD"></FYArea>
            </div>
            <div class="XL YC MTB2">关键词标题(最长8个汉字)：<input type="text" class="W75" v-model.number="CurrQYDoc.Title"/></div>
            <div class="XL YC">附加信息：
                <ShowUpdatePVT :PVTF="{P:CurrQYDoc.Pic,V:CurrQYDoc.Video,T:CurrQYDoc.Text,  TextTitle:'文字内容表述'}" @ShowUT="CallBack"></ShowUpdatePVT>
            </div>
            <div class="XL YC TC MT2">
                发布信息认缴&nbsp;<input type="number" class="W12" v-model.number="CurrQYDoc.ZXn" @focus="CurrQYDoc.ZXn=null" style="height: 2rem"/>&nbsp;个泛约币
            </div>
        </div>
    </PopUpDown>
</template>

<script>
    import {myMongoDBPost,KeepZX} from '@/components/SharedVues/Shared0.js'
    import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import MyInput from "../SharedVues/MyInput";
    import PopUpDown from "../MyPopWin/PopUpDown";
    import FYArea from "../SharedVues/FYArea";

    export default {
        name: "CreateQY",
        components: {FYArea, PopUpDown, MyInput, PopTitle, ShowUpdatePVT},
        props: ['Msg'],
        data() {
            return {
                CurrQYDoc: {
                    UserName: this.$store.state.UserName,
                    Certified: this.$store.state.Certified,
                    UserLogo: this.$store.state.UserLogo,

                    Channel: this.Msg.Channel,
                    TopKey: this.Msg.TopKey,
                    SideKey: this.Msg.SideKey,
                    Title: '',

                    Province: '',
                    City: '',
                    DC: '',

                    ZXn: 10,

                    Pic: '',
                    Video: '',
                    Text: '',
                    Time: (new Date()).getTime(),
                    Strength: '随缘',
                    EndBool: 1
                }
            }
        },
        mounted() {

        },
        methods: {
            SetQYPCD() {
                let X = this.$store.state.FYArea.split(":");
                if (X[0].charAt(0) === 'P') this.CurrQYDoc.Province = X[1];
                if (X[0].charAt(0) === 'C') this.CurrQYDoc.Province = X[1], this.CurrQYDoc.City = X[2];
                if (X[0].charAt(0) === 'D') this.CurrQYDoc.Province = X[1], this.CurrQYDoc.City = X[2], this.CurrQYDoc.DC = X[3];
            },
            CallBack(Val) {
                if (Val.P) this.CurrQYDoc.Pic = Val.P;
                if (Val.V) this.CurrQYDoc.Video = Val.V;
                if (Val.T) this.CurrQYDoc.Text = Val.T;
            },
            SaveCurrQYDoc(Val) {
                let that = this;

                function Quit(Msg1, Msg2) {
                    if (Msg1 !== '取消') alert(Msg1+Msg2);
                    that.$emit("CreateQY");
                }

                if (Val === -1) Quit('取消', ''); else {
                    if (this.CurrQYDoc.Province === '') Quit('创建失败!', '区域范围必须选择。');
                    else if (this.CurrQYDoc.Title === '') Quit('创建失败!', '标题必须输入。');
                    else {
                        if (that.$store.state.ZX < that.CurrQYDoc.ZXn)
                            Quit('您当前泛约币不足，发布失败', '请先充值！');
                        else
                            myMongoDBPost("PutDoc/QYs", this.CurrQYDoc, function (data) {
                                KeepZX(that, that.$store.state.UserName, '支', that.CurrQYDoc.ZXn, "发布求缘信息", function () {
                                    that.$emit("CreateQY");
                                });
                            });
                    }
                }
            }
        }
    }
</script>
