<template>
    <div class="MP4 LH150 TF">
        时间:{{CurrYY.meetTimeStr}}<br>
        地点：{{CurrYY.meetAddrStr}}
        <div v-if="CurrYY.jdfy.length>0">简短附言：{{CurrYY.jdfy}}</div>
        <div class="XR MR4 YC MT2">
            <div class="TC">线下活动情况：&nbsp;</div>
            <ShowUpdatePVT :PVTF="{P:CurrYY.Pic,V:CurrYY.Video,T:CurrYY.Text, TextTitle:'线下活动情况'}" @ShowUT="CallBack1"></ShowUpdatePVT>
        </div>
    </div>
    <table style="background-color:#fff;">
        <thead>
        <tr class="TF">
            <th>被约</th>
            <th>情况</th>
            <th>战果</th>
            <th>履约<br>情况</th>
            <th>评价</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(y,I2) in CurrYY.by" :key="I2" style="line-height:1.3rem;">
            <td style="text-align: left">{{I2+1}}.{{y._id}}</td>
            <td>
                <div v-if="y.xy==='同意'">
                    <ShowUpdatePVT :PVTF="{P:y.Pic,V:y.Video,T:y.Text, TextTitle:'线下活动情况'}" @ShowUT="CallBack2($event,I2)"></ShowUpdatePVT>
                </div>
                <div v-else>被约:{{y.xy}}</div>
            </td>
            <td class="W26 LH150">{{y.zg}}<br>{{y.zg==='被约胜' && y.AppearanceFee>0 ? "收到泛约币"+y.AppearanceFee+"个":""}}</td>
            <td>{{y.b_zylyqk}}</td>
            <td>{{y.b_ts}}</td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    import ShowPVT from "../MyPVTs/ShowPVT";
    import ShowUpdatePVT from "../MyPVTs/ShowUpdatePVT";
    import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'

    export default {
        name: "OpenHistoryZY",
        components: {ShowUpdatePVT, ShowPVT},
        props: ['CurrYY'],
        methods: {
            CallBack1(Val) {
                let UPObj;
                if (Val.P) UPObj = {Pic: Val.P};
                if (Val.V) UPObj = {Video: Val.V};
                if (Val.T) UPObj = {Text: Val.T};
                let asd = {UPTJ: {_id: this.CurrYY._id}, UPObj: UPObj};
                if (Val.P || Val.V || Val.T) myMongoDBPost("UpdateDoc/yy-" + this.CurrYY.ItemStr.split(":")[0], asd, function () {
                })
            },
            CallBack2(Val, I2) {
                let NewBy = this.CurrYY.by;
                if (Val.P) NewBy[I2].Pic = Val.P;
                if (Val.V) NewBy[I2].Video = Val.V;
                if (Val.T) NewBy[I2].Text = Val.T;
                let asd = {UPTJ: {_id: this.CurrYY._id}, UPObj: {by: NewBy}};
                if (Val.P || Val.V || Val.T) myMongoDBPost("UpdateDoc/yy-" + this.CurrYY.ItemStr.split(":")[0], asd, function () {
                })
            }
        }
    }
</script>

<style scoped>

</style>