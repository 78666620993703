<template>
    <div>
        &nbsp;&nbsp;离开泛约:
        <el-divider/>
        <div class="XLR MP4 TB" @click="Show9 = !Show9">
            退出登录
            <div v-if="Show9">
                <el-icon>
                    <ArrowDown/>
                </el-icon>
            </div>
            <div v-else>
                <el-icon>
                    <ArrowUp/>
                </el-icon>
            </div>
        </div>

        <div v-if="Show9" class="MLR4">
            <p>如果退出登录，您将不能正常打开其他用户向您发送的邀约信息！是否退出登录，请进一步确认！</p>
            <div class="XR">
                <div class="TE" @click="Tiped1">退出登录</div>
            </div>
        </div>
        <el-divider/>

        <div class="XLR MP4 TB" @click="Show10 = !Show10">
            申请注销用户
            <div v-if="Show9">
                <el-icon>
                    <ArrowDown/>
                </el-icon>
            </div>
            <div v-else>
                <el-icon>
                    <ArrowUp/>
                </el-icon>
            </div>
        </div>
        <div v-if="Show10">
            <div class="MLR4">
                <p>若要注销用户，需要用户提出申请,由平台管理员进行操作；同时,您在泛约大厅的所有数据将会永久性丢失。</p>
                <p>现在要继续提交注销申请吗?</p>
            </div>
            <div class="XLR YC MP4">
                <div>
                    <div v-if="WithdrawBool" class="YC TG T5" @click="WithdrawBool=false">☑ <span
                            class="T4">我已考虑清楚!</span>
                    </div>
                    <div v-else class="YC TF T5" @click="WithdrawBool=true">☐ <span class="T4">我已考虑清楚!</span></div>
                </div>
                <div v-if="WithdrawBool" class="TB" @click="AskWithdraw()">提交申请</div>
                <div v-else class="TF">提交申请</div>
            </div>
        </div>        　
    </div>
</template>

<script>
    import {myMongoDBPost, UpdatelocalStorage} from '@/components/SharedVues/Shared0.js'

    export default {
        name: "QuitFY",
        data() {
            return {
                Show9: false,
                Show10: false,

                WithdrawBool: false,
            }
        },
        methods: {
            AskWithdraw() {
                let that = this;
                if (this.$store.state.UserName === '游客')
                    alert("提示：游客用户操作无效！");
                else
                    myMongoDBPost("UpdateDoc/fyuser0", {
                        UPTJ: {UserName: that.$store.state.UserName},
                        UPObj: {RealTimeStr: '申请注销用户....'}
                    }, function () {//将来增加申请注销时间判断,以在登录页面的提示中增加催促管理员的提醒信息,
                        window.localStorage.clear();
                        alert('用户注销申请已提交!, 您的平台应用数据也将会永久删除。');
                    });//删除一个用户时,应当把所有与该用户有关的文档全部删掉
            },
            Tiped1() {
                this.$store.commit('ChangeUserName', '游客');
                UpdatelocalStorage('fyUserName', '游客');
                this.$emit('FromQuitFY')

            }
        }
    }
</script>

<style scoped>

</style>
