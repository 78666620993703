<template>
    <br>
    <PopOneAN v-if="ShowAct" @FromPopUp="ShowAct=false">
        <Act0Main :ActDoc="ActDoc"></Act0Main>
    </PopOneAN>
    <div v-else>
        <div class="TC XLR YC MP4" v-for="(Sites,index1) in Sitess" :key="index1">
            
            <div v-for="(Site,index2) in Sites" :key="index2">
                <div v-if="Site===null">&nbsp;</div>
                <div v-else class="AlignC" @click="SiteAct0(Site)">
                    <ShowP :Pic="Site.Pic" Class="WHcc"></ShowP>
                    <div class="TB">{{Site.SiteShortName}}</div>
                </div>
            </div>
        
        </div>
    </div>
</template>

<script>
    import {GetEndBool3Act} from '@/components/M3_Acts/ActShared.js'
    import {myMongoDBPost, Compare} from '@/components/SharedVues/Shared0.js'
    import {SetEnjoySite} from "@/components/M4_Sites/SiteShared.js"
    import ShowP from "../MyPVTs/ShowP";
    import PopOneAN from "../MyPopWin/PopOneAN";
    import Act0Main from "../M3_Acts/Act0Main";

    export default {
        name: "SDPZ",//碰撞式邀约
        components: {Act0Main, PopOneAN, ShowP},
        props: ['ItemStr'],
        data() {
            return {
                Sitess: [[]],//二维数组
                ActDoc: null,
                ShowAct: false,
            }
        },
        mounted() {
            let that = this, CurrItem = this.ItemStr.split(":")[1];
            let aObj = {City: this.$store.state.UserC, ItemName: CurrItem};
            myMongoDBPost("ReadCitySiteData", aObj, function (Docs) {
                if (Docs && Docs.length > 0) {
                    console.log(Docs)
                    let Sites = Docs.sort(Compare('SiteShortName'));//下边要把Sites改造成1个二维数组,每个1级数组元素中要包含4个场地
                    let M = 0, N = 0;
                    for (let i = 0; i < Sites.length; i++) {
                        that.Sitess[M].push(Sites[i]);
                        N++;
                        if (N === 4) {
                            that.Sitess.push([]);
                            M++;
                            N = 0;
                        }
                    }
                    if (that.Sitess[M].length === 1) that.Sitess[M].push(null, null, null);
                    else if (that.Sitess[M].length === 2) that.Sitess[M].push(null, null);
                    else if (that.Sitess[M].length === 3) that.Sitess[M].push(null);
                } else
                    alert('您所在城市找不到' + CurrItem + '项目的泛约活动场地！');
            });
        },
        methods: {
            SiteAct0(Site) {//如果所选场地不是自己的喜约场地，应提前自动设为喜约场地
                let that = this;
                SetEnjoySite(this.$store.state.UserName, that.ItemStr, Site._id, function () {
                    GetEndBool3Act(Site.SiteShortName, that.ItemStr, that.$store.state.FYArea, function (ActDoc) {
                        that.ActDoc = ActDoc;
                        that.ShowAct = true;
                    });
                });
            }
        }
    }
</script>

<style scoped>

</style>