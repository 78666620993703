<template>
    <img v-if="Pic2===''" src="../../../UserUpload/static/After2021/Head.jpg" class="AvatarWH" @click="Active=true"/>
    <div v-else>
        <ShowPFromThumb v-if="FreshBool" :Msg="{Pic:Pic2,Update:true}" @FromChildP="this.Active = true, this.FreshBool = false;"></ShowPFromThumb>
    </div>
    <UpFiles1 v-if="Active" VP="1" CallFrom="HS" @FromU1="UpEnd"></UpFiles1>
</template>
<script>
    import UpFiles1 from "../MyPVTs/UpFiles1";
    import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import ShowPFromThumb from "../MyPVTs/ShowPFromThumb";

    export default {
        name: "HeadShot",
        components: {ShowPFromThumb, UpFiles1},
        data() {
            return {
                Pic2: '',
                Active: false,
                FreshBool: true
            }
        },
        mounted() {
            console.log("HeadShot>>>this.$store.state.UserLogo=", this.$store.state.UserLogo);
            this.Pic2 = this.$store.state.UserLogo;
        },
        methods: {
            UpEnd(Val) {
                let that = this;
                this.Active = false;
                if (Val !== '未选择') {
                    that.Pic2 = Val;
                    that.$store.commit('ChangeUserLogo', Val);
                    myMongoDBPost("UpdateDoc/fyuser0", {
                        UPTJ: {UserName: this.$store.state.UserName},
                        UPObj: {UserLogo: Val}
                    }, function () {
                        that.FreshBool = true;
                        that.$emit("HeadShot");
                    });
                }
            }
        }
    }
</script>

<style scoped>
    .AvatarWH {
        width: 16mm;
        height: 20mm;
    }
</style>
