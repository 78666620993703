<template>
    <FrontPage v-if="DLed" @FromFrontPage="ProcessFromFrontPage"></FrontPage>
    <PopTitle v-if="ToReg" Title="请准确提供以下信息." @FromPopUp="DLed=true,ToReg=false">
        <FYReg @FromReg="ProcessFromReg"></FYReg>
    </PopTitle>
    <PopTitle v-if="ToLogin" Title="请准确输入登录信息." @FromPopUp="DLed=true,ToLogin=false">
        <div class="XL YC BottGray MLR2">
            泛约昵称:
            <input type="text" class="MP4 W50" @focus="dlData.fyUserName=null" v-model="dlData.fyUserName"/>
        </div>
        <div class="XLR YC BottGray MLR2">
            <div class="XL YC">密码:
                <input type="password" maxlength="18" @focus="dlData.fyPassword=null" placeholder="6-18位数字&字母组合" class="MP4 W50" v-model="dlData.fyPassword"/>
            </div>
            <div class="TG" @click="Show1=true">找回<br>密码</div>
        </div>
        <div class="XLR YC MTB2">
            &nbsp;
            <div class="Item YC MP4"><input id="Input12" type="checkbox" class="aui-switch" v-model="dlData.AutoDl"/>&nbsp;
                <label for="Input12">下次自动登录!</label></div>
            　
        </div>
        <div class="BGBlue AlignC PA3" @click="Login2()">
            <div class="T7 TW MP4">登&nbsp;&nbsp; &nbsp;&nbsp;录</div>
        </div>
        <PopUpDown v-if="Show1" Title="请正确回答" Pos="btt" :ANs="1" @FromPop5="Show1=false">
            <div class="MT2">
                <div class="MP4 XL YC BottGray">
                    用户名：<input type="text" class="W50" v-model="UserDoc.UserName">
                </div>
                <div class="MP4 XL YC">
                    性别:
                    <div class="XL YC ML4">
                        <input id="Sex1" type="radio" class="AlignT" name="Sex" v-model="UserDoc.Sex" value="男">
                        <label for="Sex1">男</label>
                    </div>
                    <div class="XL YC ML4">
                        <input id="Sex2" type="radio" class="AlignT" name="Sex" v-model="UserDoc.Sex" value="女">
                        <label for="Sex2">女</label></div>
                </div>
                <div class="MP4 XL YC">出生日期: &nbsp;
                    <el-date-picker v-model="BirthStr" format="YYYY-MM-DD" value-format="YYYY-MM-DD" type="date" class="W60"
                                    placeholder="Pick a day" @change="SetBirth"/>
                </div>
                <br><br>
                <div class="BD_Radius1 BGBlue T5 TW XYC H3 MT2" @click.once="NewFound">提交</div>
            
            </div>
        
        </PopUpDown>
    </PopTitle>
</template>

<script>//用localStorage自动登录：判断是新用户，还是老用户；此处不进行LandTo判断,不考虑小程序的调用，小程序调用应该直接对后台
import FrontPage from "./FrontPage";
import PopTitle from "./MyPopWin/PopTitle";
import FYReg from "./M0_RegLogin/FYReg";
import {myMongoDBPost, myWXget, UrlParse} from '@/components/SharedVues/Shared0.js';
import {ElMessage} from 'element-plus';
import MyInput from "./SharedVues/MyInput";
import PopUpDown from "./MyPopWin/PopUpDown";

export default {
    name: "AppDL",
    components: {PopUpDown, MyInput, FYReg, PopTitle, FrontPage},
    data() {
        return {
            DLed: false,
            ToReg: false,
            ToLogin: false,
            BirthStr: '1960-11-01',
            dlData: {
                fyUserName: "",
                fyPassword: "",
                AutoDl: true,
                ItemStr: '',
            },
            UserDoc: {
                UserName: "",
                BirthYear: 0,//出生年份
                BirthMonth: 1,
                BirthDay: 1,
                Sex: "？"
            },
            Show1: false,
            Show2: false,
            Show3: false,
            NewId: '',
            TXKL: '',
            ShowNewId2: '',
        }
    },

    mounted() {
        let that = this;
        let MyStorage = window.localStorage; //本地存储应用特别事项:第一,数类型均为字符串;第二;并不安全
        if (MyStorage && MyStorage.M1) {
            that.dlData = {
                fyUserName: MyStorage.M1.split("-=_+")[0],
                fyPassword: MyStorage.M1.split("-=_+")[1],
                AutoDl: MyStorage.M1.split("-=_+")[2] === "true"
            };
            if (that.dlData.fyUserName === '游客') this.DL('直接返回', function () {
                that.DLed = true;
            }); else if (that.dlData.AutoDl) this.DL('去登录', function () {
                that.DLed = true;
            }); else this.DL('直接返回', function () {
                that.DLed = true;
            });
        } else this.DL('直接返回', function () {
            that.DLed = true;
        });
        if (MyStorage && MyStorage.M2) this.$store.commit("ChangeFYArea", JSON.parse(MyStorage.M2).FYArea);
    },
    methods: {
        ProcessFromFrontPage(Val) {
            console.log("ProcessFromFrontPage=", Val);
            this.DLed = false;
            if (Val === 'ToReg') this.ToReg = true;
            if (Val === 'ToLogin') this.ToLogin = true;
        },
        ProcessFromReg() {
            this.ToLogin = true;
            this.ToReg = false;
            this.$store.commit('ChangeLandTo', 'Login');
            ElMessage('注册成功， 请登录一下试试看！');
        },
        SetDLed() {
            this.DLed = true;
            this.ToLogin = false;
            ElMessage('登录成功!');
        },
        DL(Bool, CallBack) {//三件事情：1.修改全局变量 2.修改本地存储  3.ChangeCurrScr
            let WXNickName = '', that = this;
            let UrlParameters = UrlParse(), LandTo = UrlParameters.LandTo;//凡是来自微信或小程序的访问都会有LandTo，来自浏览器的访问则LandTo==undefined
            console.log("dl-Bool=", Bool)

            function GetVisitWXId(Bool, Callback) {
                let VisitWXId = UrlParameters.VisitWXId;
                if (that.$store.state.WXId !== '') Callback(VisitWXId);//选择'按钮登录'时,如果本GetVisitWXId,已被调用过,立即再次调用后台会出现40001号错误
                else if (LandTo === undefined) Callback('from浏览器'); else {
                    if (Bool === '按钮登录' || VisitWXId === undefined) {//从自定义菜单或'WXLink' 或LandTo === 'ZY2'等 过来的访问;
                        myWXget("GetOpenidFromCode/?Code=" + UrlParameters.code, function (aObj) { //alert(JSON.stringify(aObj));
                            VisitWXId = (aObj.subscribe === 0 ? '未关注' : aObj.openid);
                            WXNickName = aObj.nickname;
                            if (VisitWXId === null || VisitWXId === undefined)
                                alert('网络异常导致的程序错误，VisitWXId =' + VisitWXId);
                            else
                                that.$store.commit('ChangeWXId', VisitWXId);//登录前赋值也是为了防止后台出现40001号错误
                            Callback(VisitWXId);
                        })
                    } else Callback(VisitWXId);
                }
            }

            GetVisitWXId(Bool, function (VisitWXId) {//设置与绑定WXId
                function SetCommit(data) {
                    that.$store.commit('ChangeUserName', data.UserName);//登录成功,把游客改为泛约昵称
                    that.$store.commit('ChangeUserLogo', data.UserLogo);
                    that.$store.commit('ChangeCertified', data.Certified);
                    that.$store.commit('ChangeZX', data.ZX);
                    that.$store.commit('ChangeRealName', data.RealName);
                    that.$store.commit('ChangeMobile', data.Mobile);
                    that.$store.commit('ChangeDefaultItem', data.DefaultItem);
                    that.$store.commit('ChangeSex', data.Sex);
                    that.$store.commit('ChangeAge', data.Age);
                    that.$store.commit('ChangeUserBools', data.UserBools);
                    that.$store.commit('ChangeUserP', data.UserP);
                    that.$store.commit('ChangeUserC', data.UserC);
                    that.$store.commit('ChangeUserDC', data.UserDC);
                }

                if (Bool === '直接返回') CallBack(); else
                    myMongoDBPost("DL", that.dlData, function (data) { //此处,服务器端也应该去解析dlData
                        if (data === "未找到!" || data === '网络出错啦!') CallBack(); else { //登录成功,需要完成三件事情:
                            SetCommit(data);//1.
                            that.$store.commit('ChangeWXId', VisitWXId);
                            if (window.localStorage) {
                                window.localStorage.M1 = that.dlData.fyUserName + "-=_+" + that.dlData.fyPassword + "-=_+" + that.dlData.AutoDl;
                                let FYArea = 'N:P:C:D';
                                if (window.localStorage.M2) FYArea = JSON.parse(window.localStorage.M2).FYArea;//如果已经存在
                                window.localStorage.M2 = JSON.stringify({
                                    fyUserName: that.dlData.fyUserName,
                                    ZX: data.ZX,
                                    Certified: data.Certified,
                                    FYArea: FYArea
                                }); //可以将json对象转换成json对符串
                            } //2.如果浏览器支持localStorage,则写入localStorage

                            if (VisitWXId === 'from浏览器') CallBack();
                            else if (that.$store.state.UserName === '游客') CallBack();
                            else if (VisitWXId || WXNickName) {//重新绑定
                                let oOBJ = {};
                                if (WXNickName !== '' && WXNickName !== undefined && WXNickName !== null) oOBJ.WXNickName = WXNickName;
                                if (VisitWXId) oOBJ.WXId = VisitWXId;
                                if (JSON.stringify(oOBJ) !== "{}") myMongoDBPost("UpdateDoc/fyuser0", {
                                    UPTJ: {UserName: that.$store.state.UserName}, UPObj: oOBJ
                                }, function () {
                                    CallBack();
                                }); else CallBack();
                            } else CallBack();
                        }
                    });
            });
        },
        SetBirth() {
            console.log(this.BirthStr, typeof this.BirthStr)
            this.UserDoc.BirthYear = parseInt(this.BirthStr.split("-")[0]);
            this.UserDoc.BirthMonth = parseInt(this.BirthStr.split("-")[1]);
            this.UserDoc.BirthDay = parseInt(this.BirthStr.split("-")[2]);
        },
        NewFound() { //找回密码
            myMongoDBPost("GetDocs/fyuser0", this.UserDoc, function (Docs) {
                if (Docs.length > 0)
                    alert("您的密码是：" + Docs[0].Password);
                else
                    alert('用户名、性别、出生年月全部回答正确，才有可能找回密码!');
            })
        },
        Login2() {
            let that = this;
            this.DL('按钮登录', function () {
                if (that.$store.state.UserName === '游客') alert('登录失败, 请检查用户名或密码是否正确!');
                else that.SetDLed();
            });
        },
    }
}
</script>
