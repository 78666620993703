<template>
    <div v-if="IsLoading">IsLoading...</div>
    <div v-else>
      <table border="1px">
        <thead>
        <tr class="TF">
          <th>序号</th>
          <th>
            <div class="MTB2 LH120">昵称与实名<br>身份证号</div>
          </th>
          <th>照片与视频</th>
          <th>操作</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(CU,index) in CUsers" :key="index">
          <td>{{index+1}}</td>
          <td class="LH120">
            <div class="T3">{{CU.UserName}}/{{CU.RealName}}</div>
            <div class="T1">{{CU.IDNumber}}</div>
          </td>
          <td>
            <div class="XLR YC">
              <img v-if="CU.Pic.length===0" src='/static/img/Media/PicGray.jpg' class="WH99">
              <ShowPFromThumb v-else :Msg="{Pic:CU.Pic,Update:false}"></ShowPFromThumb>&nbsp;
              <img v-if="CU.Video.length===0" src='/static/img/Media/VideoGray.jpg' class="WH99">
              <ShowVFromThumb v-else :Msg="{Video:CU.Video,Update:false}"></ShowVFromThumb>
            </div>
          </td>
          <td>
            <div v-if="CU.Certified==='尚未' || CU.Certified==='待验证'" class="PA1 TB" @click="CheckYes(index,'成功')">OK</div>
            <div v-if="CU.Certified==='尚未' || CU.Certified==='待验证'" class="PA1 TG" @click="CheckYes(index,'失败')">NO</div>
            <div v-if="CU.Certified==='成功'" class="TC">成功</div>
            <div v-if="CU.Certified==='失败'" class="TC AlignC">
              失败
              <div class="TB" @click="CheckYes(index,'成功')">NowOK</div>
            </div>
          </td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
</template>

<script>
  //原则上,只要用户提出申请,后台早晚会同意并进行注销操作

  import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'
  import ShowPFromThumb from "../MyPVTs/ShowPFromThumb";
  import ShowVFromThumb from "../MyPVTs/ShowVFromThumb";

  export default {
    name: "UserCertify",
    components: {ShowVFromThumb, ShowPFromThumb},
    data() {
      return {
        IsLoading: true,
        CUsers: []
      }
    },
    mounted() {
      let that = this;
      myMongoDBPost("GetDocs/fyuser0", {}, function (Docs) { //Certified:"";应该为未认证或失败的
        that.IsLoading = false;
        that.CUsers = Docs;
      })
    },
    methods: {
      CheckYes(index, X) {
        let that = this;
        that.CUsers[index].Certified = X;
        let asd = that.CUsers[index];
        if (X === '成功') myMongoDBPost("UpdateDoc2/fyUser0", {
          UPTJ: {_id: this.CUsers[index]._id},
          UPObj: {$addToSet: {UserBools: '日记本'}, Certified: X}
        }, function () {
          that.CUsers.splice(index, 1, asd);
        }); else myMongoDBPost("UpdateDoc/fyUser0", {UPTJ: {_id: this.CUsers[index]._id}, UPObj: {Certified: X}}, function () {
          that.CUsers.splice(index, 1, asd);
        });
      }
    },
  }
</script>

<style scoped>

</style>

