<template>
    <div>
        <div class="ML4 MT2">附件１：照片</div>
        <table class="TG">
            <thead>
            <tr class="LH120">
                <th v-for="(R0,index2) in PABs[0]" :key="index2" style="padding: 2mm 0">
                    <div v-if="index2===0" @click="SetActive(1, 0, 0)" style="min-width: 5rem">合影</div>
                    <div v-else @click="SetActive(1, 0, index2)">{{Act1Doc.Bs[index2-1]}}</div>
                    <div v-if="R0.length>10" class="XLR YC">
                        <div v-if="CanEditVP" class="PA2 TE T5" @click="DeleteVP(1,0,index2)">删</div>
                        <div v-else></div>
                        <img :src="'/UserUpload/1/S'+R0" class="WHaa" @click="PI1=0,PI2=index2,ShowPic=true">
                    </div>
                </th>
            </tr>
            </thead>
            <tbody v-for="(Cols,index1) in PABs" :key="index1">
            <tr v-if="index1>0">
                <td v-for="(ZD,index2) in Cols" :key="index2" class="LH120">
                    <div v-if="index2===0" style="min-width: 5rem">
                        <div class="PA2" @click="SetActive(1,index1,index2)">{{Act1Doc.As[index1-1]}}</div>
                        <div v-if="ZD.length>10" class="XLR YC">
                            <img :src="'/UserUpload/1/S'+ZD" class="WHaa" @click="PI1=index1,PI2=index2,ShowPic=true">
                            <div v-if="CanEditVP" class="PA2 TE T5" @click="DeleteVP(1,index1,index2)">删</div>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="ZD.length<10" class="PA2" @click="SetActive(1,index1,index2)">―</div>
                        <div v-else>
                            <img :src="'/UserUpload/1/S'+ZD" class="WHaa" @click="PI1=index1,PI2=index2,ShowPic=true">
                            <div v-if="[Act1Doc.UserName, Act1Doc.SeleBool].indexOf($store.state.UserName) >= 0" class="XLR YC">
                                <div class="PA2 TB" @click="SetActive(1,index1,index2)">改</div>
                                <div class="PA2 TE T5" @click="DeleteVP(1,index1,index2)">删</div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <br>
        <div class="ML4 MT2">附件２：视频</div>
        <table class="TB">
            <thead>
            <tr class="LH120">
                <th v-for="(R0,index2) in VABs[0]" :key="index2" style="padding: 2mm 0">
                    <div v-if="index2===0" @click="SetActive(2, 0, 0)" style="min-width: 5rem">合录</div>
                    <div v-else @click="SetActive(2, 0, index2)">{{Act1Doc.Bs[index2-1]}}</div>
                    
                    <div v-if="R0.length>10" class="XLR YC">
                        <div v-if="CanEditVP" class="PA2 TE T5" @click="DeleteVP(2,0,index2)">删</div>
                        <div v-else></div>
                        <img v-if="R0" :src="'/UserUpload/2/S'+R0.split('.')[0] + '.jpg'" class="WHaa" @click="VI1=0,VI2=index2,ShowVideo=true">
                    </div>
                </th>
            </tr>
            </thead>
            <tbody v-for="(Cols,index1) in VABs" :key="index1">
            <tr v-if="index1>0">
                <td v-for="(ZD,index2) in Cols" :key="index2" class="LH120">
                    <div v-if="index2===0" style="min-width: 5rem">
                        <div class="PA2" @click="SetActive(2,index1,index2)">{{Act1Doc.As[index1-1]}}</div>
                        <div v-if="ZD.length>10" class="XLR YC">
                            <img v-if="ZD" :src="'/UserUpload/2/S'+ZD.split('.')[0] + '.jpg'" class="WHaa" @click="VI1=index1,VI2=index2,ShowVideo=true">
                            <div v-if="CanEditVP" class="PA2 TE T5" @click="DeleteVP(2,index1,index2)">删</div>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="ZD.length<10" class="PA2" @click="SetActive(2,index1,index2)">―</div>
                        <div v-else>
                            <img v-if="ZD" :src="'/UserUpload/2/S'+ZD.split('.')[0] + '.jpg'" class="WHaa" @click="VI1=index1,VI2=index2,ShowVideo=true">
                            <div v-if="CanEditVP" class="XLR YC">
                                <div class="PA2 TB" @click="SetActive(2,index1,index2)">改</div>
                                <div class="PA2 TE T5" @click="DeleteVP(2,index1,index2)">删</div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        
        <PopUpDown v-if="ShowPic" :Title="PI1===0 && PI2===0 ? '合影':((PI1===0 ? '':Rows[PI1][0])+'-'+(PI2===0 ? '':Rows[0][PI2]))" Pos="btt" :ANs="1"
                   @FromPop5="ShowPic=false">
            <img class="WHa" :src="'/UserUpload/1/'+PABs[PI1][PI2]">
        </PopUpDown>
        
        <PopUpDown v-if="ShowVideo" :Title="VI1===0 && VI2===0 ? '合录':((VI1===0 ? '':Rows[VI1][0])+'-'+(VI2===0 ? '':Rows[0][VI2]))" Pos="btt" :ANs="1"
                   @FromPop5="ShowVideo=false">
            <video :src="'/UserUpload/2/'+VABs[VI1][VI2]" webkit-playsinline="" playsinline="" x5-playsinline=""
                   width="100%" height="auto" controls></video>
        </PopUpDown>
        
        <UpFiles1 v-if="PI1>-1 && PI2>-1 && Active" VP="1" CallFrom="A1" @FromU1="SetPABs($event,PI1,PI2)"></UpFiles1>
        <UpFiles1 v-if="VI1>-1 && VI2>-1 && Active" VP="2" CallFrom="A1" @FromU1="SetVABs($event,VI1,VI2)"></UpFiles1>
    
    </div>
</template>
<script>
    import {myMongoDBPost,  myVodpost,  CloneObject} from '@/components/SharedVues/Shared0.js'
    import PopTitle from "../MyPopWin/PopTitle";
    import UpFiles1 from "../MyPVTs/UpFiles1";
    import WXLink from "../IndependentVues/WXLink";
    import PopUpDown from "../MyPopWin/PopUpDown";

    export default {
        name: "Act1PVABs",
        components: {PopUpDown, WXLink, UpFiles1, PopTitle},
        props: ['Act1Doc', 'CallFrom'],
        data() {
            return {
                Rows: this.Act1Doc.TableCols,//注意：这是一个二维叔组　
                PABs: this.Act1Doc.PABs,
                PI1: -1,
                PI2: -1,
                VABs: this.Act1Doc.VABs,
                VI1: -1,
                VI2: -1,
                Active: false,
                ShowPic: false,
                ShowVideo: false,
                CanEditVP: [this.Act1Doc.UserName, this.Act1Doc.SeleBool].indexOf(this.$store.state.UserName) >= 0,
            }
        },
        mounted() {
            console.log("CallFrom=" + this.CallFrom);
            console.log(this.Act1Doc)
        },
        methods: {
            SetActive(Bool, index1, index2) {
                if (this.$store.state.UserName !== '游客') {
                    if (Bool === 1) this.PI1 = index1, this.PI2 = index2;
                    if (Bool === 2) this.VI1 = index1, this.VI2 = index2;
                    this.Active = true;
                } else alert('无效操作：只有注册用户才能上传!');
            },
            SetPABs(Val, index1, index2) {
                if (Val !== '未选择') {
                    let Cols = CloneObject(this.PABs[index1]);

                    let P = Cols[index2];
                    if (P.length > 10) myVodpost("DeleteMedia", {FileId: P}, function () {
                    });//若图片已存在,则应先删除老图片

                    Cols.splice(index2, 1, Val);//1.先把当前数据写入
                    this.PABs.splice(index1, 1, Cols);

                    myMongoDBPost("UpdateDoc/Act1", {UPTJ: {_id: this.Act1Doc._id}, UPObj: {PABs: this.PABs}}, function () {
                    });
                }
                this.PI1 = -1;
                this.PI2 = -1;
                this.Active = false;
            },
            SetVABs(Val, index1, index2) {
                if (Val !== '未选择') {
                    let Cols = CloneObject(this.VABs[index1]);
                    let V = Cols[index2];
                    if (V.length > 10) myVodpost("DeleteMedia", {FileId: V}, function () {
                    });//若视频已存在,则应先删除老视频

                    Cols.splice(index2, 1, Val);//1.先把当前数据写入
                    this.VABs.splice(index1, 1, Cols);

                    if (this.$store.state.UserName !== '游客') myMongoDBPost("UpdateDoc/Act1", {
                        UPTJ: {_id: this.Act1Doc._id},
                        UPObj: {VABs: this.VABs}
                    }, function () {
                    }); else alert('无效操作：只有注册用户才能上传照片!');
                }
                this.VI1 = -1;
                this.VI2 = -1;
                this.Active = false;
            },
            DeleteVP(Bool, index1, index2) {
                let that = this;
                if (Bool === 1) {
                    myVodpost("DeleteMedia", {FileId: this.PABs[index1][index2]}, function () {
                        that.PABs[index1].splice(index2, 1, '');
                        myMongoDBPost("UpdateDoc/Act1", {UPTJ: {_id: that.Act1Doc._id}, UPObj: {PABs: that.PABs}}, function () {
                        });
                    });
                }
                if (Bool === 2) {
                    myVodpost("DeleteMedia", {FileId: this.VABs[index1][index2]}, function () {
                        that.VABs[index1].splice(index2, 1, '');
                        myMongoDBPost("UpdateDoc/Act1", {UPTJ: {_id: that.Act1Doc._id}, UPObj: {VABs: that.VABs}}, function () {
                        });
                    });
                }
            }
        }
    }
</script>

<style scoped>
</style>


