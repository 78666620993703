<template>
  <div>
    <div v-if="IsLoading">IsLoading...</div>
    <div class="AlignC BottO">
      <TimeSexAge :BYs="BYs" CallFrom="1" @FromChild="RefreshBYs"></TimeSexAge>
    </div>
    <div v-if="!IsLoading">
      <div v-for="(x,index) in BYs" :key="index" class="BottO MT2">
        <div v-if="x.ShowBool" :class="'XLR YC '+(x.SelectedBool ? 'TG':'TF')">
          &nbsp;
          <div class="AlignC T3">
            <div class="XLR">
              <img v-if="x.UserLogo!==''" :src="'UserUpload/1/S'+x.UserLogo" @click="SeleI=index" class="BDCircle WH99"/>
              <img v-else :src="'UserUpload/static/After2021/Head.jpg'" class="BDCircle WH99"/>
              <ShowTimeWin :TW="x.TimeWinState"></ShowTimeWin>
            </div>
            <div class="T4 TC NoWrap">{{x.fyUserName}}</div>
            <div class="XLR YC">
              <el-badge v-if="x.AppearanceFee>0" :value="x.AppearanceFee.toString()"></el-badge>
              <div class="T2">{{x.sex}}&nbsp;&nbsp;{{x.age}}</div>
              <UserFace3 :Certified="x.Certified"></UserFace3>
            </div>
          </div>
          <div style="width: 58vw">
            <div class="XLR YC T2">
              {{x.domicile==='不限'?"―":x.domicile}}
              <div class="T2">主约:{{x.ZyTimes}}次</div>
              被约:{{x.ByTimes}}次
            </div>
            <div class="XLR T2 MP1">
              人气:{{x.PopularityCredit.toFixed(2)}}<div class="T2">才艺:{{x.QN.toFixed(2)}}</div>
            </div>
            <ShowPVT :PVT="{P:x.fyUserPic,V:x.fyUserVideo,T:x.fyUserText,Update:false}"></ShowPVT>
          </div>&nbsp;
          <div class="W12">
            <input type="checkbox" class="aui-switch" v-model="x.SelectedBool" @change="bySelect(x.SelectedBool,x.fyUserName,x.AppearanceFee)" />
          </div>&nbsp;
        </div>
      </div>
      <img v-if="SeleI!==-1" :src="'UserUpload/1/'+BYs[SeleI].UserLogo"  class="FullScr2" @click="SeleI=-1"/>
    </div>
  </div>
</template>

<script>
  import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'
  import TimeSexAge from "./TimeSexAge";
  import ShowTimeWin from "./ShowTimeWin";
  import ShowPVT from "@/components/MyPVTs/ShowPVT";
  import mixinsBySelected from '@/components/ListYY/mixinsBySelected'
  import PopTitle from "@/components/MyPopWin/PopTitle";
  import UserFace3 from "../SharedVues/UserFace3";

  export default {
    name: "TM1bySele",
    components: {
      UserFace3,　
      PopTitle,
      TimeSexAge,
      ShowTimeWin,
      ShowPVT
    },
    mixins: [mixinsBySelected],
    data() {
      return {
        Show: -1,
        IsLoading: true,//数据准备不就绪,就不让它显示
        fyUserName: this.$store.state.UserName,
        BYs: [],
        SelectedBYs: [],
        T2Bool: true,
        SeleI: -1
      }
    },
    mounted() {
      this.GetBYs();
    },
    methods: {
      GetBYs() {//注意:不带入口参数
        this.IsLoading = true;
        let that = this;
        myMongoDBPost("XXYLReadByListData", {
          fyUserName: this.$store.state.UserName,
          ItemCode: this.$store.state.CurrItemStr.split(":")[0],
          AreaStr: this.$store.state.FYArea
        }, function (byList) {
          that.SelectedBYs = [];
          that.BYs = byList;
          if (that.BYs.length === 0) that.$emit("eventFromTM", 'NoBY'); else that.$emit("eventFromTM", that.SelectedBYs);
          that.IsLoading = false;
        });
      },
      RefreshBYs(Val) {
        console.log(Val)
        this.BYs = Val;
      }
    }
  }
</script>

<style scoped>

  .Css1 {
    width: 10vw;
    text-align: center
  }

  .Css4 {
    width: 20vw;
    text-align: left;
    //overflow: hidden;
  }

  .Css3 {
    width: 5vw;
    text-align: center
  }

  .W18 {
    width: 18vw;
  }
</style>

