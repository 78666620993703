<template>
    <div>
        <div class="DispIB">
            <img :src="CurrPic" class="WH129 Cursor0" @click="Show1 = true">
        </div>
        <PopUpDown v-if="Show1" Title="图片浏览" Pos="btt" :ANs="1" @FromPop5="Show1 = false">
            <img v-if="Show2" :src="CurrPic" @click="Show2=false" style="width: 100vw;height: auto"/>
            <img v-else :src="CurrPic" @click="Show2=true"
                 style="transform:rotate(90deg);position:fixed;left:-51vw;top:20vh;width:100vh;height:auto;"/>
            <div v-if="Msg.Update && Show2" class="XR MP4">
                <div class="TB Cursor0" @click="UpdatePic()">更新图片</div>
                <br>
            </div>
        </PopUpDown>
    </div>
</template>

<script>
    import PopTitle from "../MyPopWin/PopTitle";
    import PopUpDown from "../MyPopWin/PopUpDown";
    import {myVodpost} from '@/components/SharedVues/Shared0.js';

    //width="100%" height="70%"
    export default {
        name: "ShowPFromThumb",
        components: {PopUpDown, PopTitle},
        data() {
            return {
                Show1: false,
                Show2: true,
                CurrPic: ''
            }
        },
        props: ['Msg'],
        mounted() {
            let that = this;
            myVodpost("getMediaInfos", {FileId: this.Msg.Pic}, function (aObj) {
                that.CurrPic = aObj.MediaUrl;
            })
        },
        methods: {
            UpdatePic() {
                let that = this;
                myVodpost("DeleteMedia", {FileId: this.Msg.Pic}, function () {
                    that.Show1 = false;
                    that.CurrPic = '';
                    that.$emit("FromChildP");
                })
            }
        }
    }
</script>

<style scoped>

</style>
