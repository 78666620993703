<template>
    <div>
        <img v-if="Video2===''" src='../../../static/img/Media/VideoColor.png' class="WH99" @click="Active=true">
        <div v-else>
            <ShowVFromThumb v-if="FreshBool" :Msg="{Video:Video2,Update:true}" @FromChildV="Active=true,FreshBool=false"></ShowVFromThumb>
        </div>
        <UpFiles1 v-if="Active" VP="2" CallFrom="V"  @FromU1="UpEnd"></UpFiles1>
    </div>
</template>

<script>
    import UpFiles1 from "./UpFiles1";
    import ShowVFromThumb from "./ShowVFromThumb";

    export default {
        name: "ShowUpdateV",
        components: {ShowVFromThumb, UpFiles1},
        props: ['Video'],
        data() {
            return {
                Video2: '',
                Active: false,
                FreshBool: true
            }
        },
        mounted() {
            this.Video2 = this.Video;
            console.log(this.Video2, this.Video)
        },
        methods: {
            UpEnd(VAL) {
                this.Active = false;
                if (VAL === '未选择') this.$emit("ShowUP", {V: this.Video2}); else {
                    this.Video2 = VAL;
                    this.FreshBool = true;
                    this.$emit("ShowUP", {V: VAL});
                }
            }
        }
    }
</script>

<style scoped>
</style>


