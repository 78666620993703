<template>
    <div class="XR YC MP1">
        <img src="../../../static/img/ButtonPic/AConfirm.png" class="WH77" @click="Save">
    </div>
    <div class="PA3 TF">
        <div class="XL YC">标题名称：
            <input type="text" class="PA3 W80" v-model="CurrTool.Title"/>
        </div>
        <br>
        <div class="XLR YC TF MT2">
            允许翻看用户：
            <el-radio-group v-model="CurrTool.ScopeBool">
                <el-radio-button label='任意用户' value="任意用户"/>
                <el-radio-button label='凭授权口令' value="凭授权口令"/>
            </el-radio-group>
        </div>
        <div v-if="CurrTool.ScopeBool==='凭授权口令'" class="XL YC">授权口令：
            <input type="text" class="MP4 W26" v-model="CurrTool.PassCode" placeholder="必须输入"/>
        </div>
        <br>
    </div>
</template>

<script>
    import {myMongoDBPost} from '../SharedVues/Shared0.js'
    import PopUpDown from "../MyPopWin/PopUpDown";

    export default {
        name: "GXXCPub",
        props: ['ToolDoc'],
        components: {PopUpDown},
        data() {
            return {
                ShowPoppup: false,
                CurrTool: {
                    UserName: this.$store.state.UserName,
                    Certified: this.$store.state.Certified,
                    UserLogo: this.$store.state.UserLogo,

                    ToolM: '共享相册',
                    ClassifyKey: '',
                    Title: '',

                    SeleBool: '单选',//当选择记账时为"出纳泛约用户名"
                    ScopeBool: '任意用户',
                    PassCode: '',
                },

            }
        },
        mounted() {
            if (this.ToolDoc !== '新增') this.CurrTool = this.ToolDoc;//此处判断是要新增还是要修改
            this.ShowPoppup = true;
        },
        methods: {
            Save(Val) {
                let that = this;
                if (Val === -1) {
                    this.ShowPoppup = false;
                    this.$emit("GXXCPub", that.CurrTool.PassCode);
                } else if (that.CurrTool.Title === '') alert('参数不完整，请确保所有选项都已完成！');
                else {
                    if (this.ToolDoc === '新增')
                        myMongoDBPost("PutDoc/Tools", that.CurrTool, function (data) {
                            that.ShowPoppup = false;
                            that.$emit("GXXCPub", that.CurrTool.PassCode);
                        }); else myMongoDBPost("DocPutBack/Tools", that.CurrTool, function () {
                        that.ShowPoppup = false;
                        that.$emit("GXXCPub", that.CurrTool.PassCode);
                    });
                }
            },
        }
    }
</script>

<style scoped>
</style>
