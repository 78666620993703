<template>
    <div>
        <div v-if="IsLoading"></div>
        <div v-else class="TF">
            <div class="XL MP4">
                <img v-if="CurrU.UserLogo && CurrU.UserLogo!==''" :src="'UserUpload/1/S' + CurrU.UserLogo" @click="Show4=true"
                     class="AvatarWH">
                <img v-else :src="'UserUpload/static/After2021/Head.jpg'" class="AvatarWH"/>
                <div class="ML2 LH150">
                    实名：<span class="TC">{{CurrU.RealName}}</span>
                    <UserFace1 :Certified="CurrU.Certified" ShowHZ="显示汉字"></UserFace1>
                    <div>
                        常驻：<span class="TC">{{CurrU.FixedAddr.P}}{{CurrU.FixedAddr.C}}{{CurrU.FixedAddr.DC}}</span>
                    </div>
                    <div class="XLR LH150">
                        <div>血型：<span class="TC">{{X1}}</span></div>
                        <div>婚姻状况：<span class="TC">{{X2}}</span></div>
                    </div>
                </div>
            </div>
            <div class="XLR MLR4">
                <div>手机号码：<span class="TC">{{X3}}</span></div>
                <div>学历：<span class="TC">{{X4}}</span></div>
            </div>
            <!--div class="ML4">
              出国经历：<span class="TC">{{X5}}</span>
            </div-->
            <div class="XLR YC MLR4">自我展示：
                <ShowPVT :PVT="{P:CurrU.Pic2,V:CurrU.Video2,T:CurrU.Text2,Update:false}"></ShowPVT>
                <div></div>
            </div>
            <MyDivider ShowStr="喜欢邀约的项目"></MyDivider>
            
            <div v-if="CurrU.Bysets && CurrU.Bysets.length===0" class="MP4 TE">该用户尚未设置喜好项目!</div>
            <table v-else class="MT2" style="background-color:#fff;">
                <thead>
                <tr class="TF" style="line-height: 100%;">
                    <th>序<br>号</th>
                    <th>项目</th>
                    <th>表演展示</th>
                    <th style="padding: 2mm 0 1mm">喜好<br>时间</th>
                    <th>邀约</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(CB,index) in CurrU.Bysets" :key="index" style="line-height:1.8rem;text-align: left">
                    <td>{{index+1}}</td>
                    <td>
                        <div class="BottGray T3">{{ItemTypeName(CB.ItemStr)[0]}}</div>
                        <div class="TC">{{ItemTypeName(CB.ItemStr)[1]}}</div>
                    </td>
                    <td>
                        <ShowPVT :PVT="{P:CB.Pic,V:CB.Video,T:CB.Text,Update:false}"></ShowPVT>
                    </td>
                    <td>
                        <div v-if="CB.TimeWinStrs && CB.TimeWinStrs.length>0" @click="SeleI=index" class="TG">打开</div>
                        <div v-else class="TF">未设</div>
                    </td>
                    <td v-if="$store.state.UserName===He" class="TF">No</td>
                    <td v-else @click="DoYY(index)">
                        <el-icon><ArrowRight/></el-icon>
                    </td>
                </tr>
                </tbody>
            </table>
            <div v-if="!Show2 && $store.state.UserName!=='游客'" class="MP4">
                <MyDivider ShowStr="发送微信消息"></MyDivider>
                <div v-if="CurrU.WXId===''" class="MP4 TE">该用户未关注“泛约大厅”微信公众号!</div>
                <div v-else>
                    <textarea class="Box43 Mtextarea2" v-model="WXMsg"></textarea>
                    <div class="XR MR4">
                        <div class="AN ANGreen T2" @click="SendWX()">发送</div>
                    </div>
                </div>
            </div>
            <br><br>
        </div>
        <PopTitle v-if="Show3 && SeleI!==-1" :Title="'直接邀约：'+He" @FromPopUp="SeleI=-1">
            <XMYY :ItemStr="CurrU.Bysets[SeleI].ItemStr" :FromOpenUser="He"></XMYY>
        </PopTitle>
        <PopTitle v-if="!Show3 && SeleI!==-1" Title="喜好时间窗" @FromPopUp="SeleI=-1">
            <TimeWindowShow :TimeWinStrs="CurrU.Bysets[SeleI].TimeWinStrs"></TimeWindowShow>
        </PopTitle>
        <PopTitle v-if="Show4" Title="头像照片" @FromPopUp="Show4=false">
            <img :src="'UserUpload/1/' + CurrU.UserLogo" style="width: 100vw"/>
        </PopTitle>
    </div>
</template>

<script>
    import {myMongoDBPost, GetItemType, SendWXMsg} from '@/components/SharedVues/Shared0.js'
    import ShowPVT from "@/components/MyPVTs/ShowPVT";

    import MyToast from "@/components/SharedVues/MyToast";
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import TimeWindowShow from "@/components/SharedVues/TimeWindowShow";
    import XMYY from "../ListYY/XMYY";
    import MyInput from "@/components/SharedVues/MyInput";
    import UserFace1 from "../SharedVues/UserFace1";
    import MyDivider from "../SharedVues/MyDivider";

    export default {
        name: "OpenUser",
        components: {
            MyDivider,
            UserFace1,
            MyInput,
            XMYY,
            TimeWindowShow,
            PopTitle, MyToast,
            ShowPVT
        },
        data() {
            return {
                CurrU: {},
                IsLoading: true,
                WXMsg: '',
                Show2: false,
                Show3: false,
                SeleI: -1,
                Show4: false,
                InputI: -1
            }
        },
        props: ['He'],
        computed: {

            X1() {
                return this.CurrU.BloodType === '请选择录入' ? "―" : this.CurrU.BloodType;
            },
            X2() {
                return this.CurrU.Marriage === '请选择录入' ? "―" : this.CurrU.Marriage;
            },
            X3() {
                return this.CurrU.Mobile === '请选择录入' ? "―" : this.CurrU.Mobile;
            },
            X4() {
                return this.CurrU.Education === '请选择录入' ? "―" : this.CurrU.Education;
            },
            X5() {
                return this.CurrU.AbroadExperience === '请选择录入' ? "―" : this.CurrU.AbroadExperience;
            }
        },
        mounted() {
            let that = this;
            myMongoDBPost("GetDocs/fyuser0", {UserName: that.He}, function (Docs) {
                console.log(Docs);
                if (Docs.length > 0) {
                    that.CurrU = Docs[0];
                    that.CurrU.Bysets = [];
                    myMongoDBPost("GetDocs/byset0", {UserName: that.He}, function (Docs2) {
                        for (let i = 0, len = Docs2.length; i < len; i++)
                            that.CurrU.Bysets.push({
                                ItemStr: Docs2[i].ItemStr,
                                Pic: Docs2[i].Pic,
                                Video: Docs2[i].Video,
                                Text: Docs2[i].Text,
                                TimeWinStrs: Docs2[i].TimeWinStrs
                            })
                        that.IsLoading = false;
                    })
                }
            })
        },
        methods: {
            SendWX() {
                if (this.WXMsg.length === 0) alert("请输入信息!"); else {
                    let TemplateObj = {
                        TemplateIndex: 4,
                        Data: {thing6: {value: this.WXMsg}, thing12: {value: this.$store.state.UserName + "/" + this.$store.state.RealName}}
                    }
                    SendWXMsg(this.He, this.$store.state.UserName + "：" + this.WXMsg, TemplateObj);
                    alert("信息已发送!");
                    this.Show2=true;
                    
                }
            },
            ItemTypeName(ItemStr) {
                let Code = ItemStr.split(":")[0];
                console.log(ItemStr);
                return [GetItemType(Code), ItemStr.split(":")[1].substr(0, 4)]
            },
            DoYY(index) {
                if (this.$store.state.UserName === '游客') alert('游客操作无效!，请先注册、登录后，再进行该操作。'); else {
                    this.Show3 = true;
                    this.SeleI = index;
                }
            }
        }
    }
</script>

<style scoped>
</style>
