<template>
    <div>
        <div class="LargeMVideoFrame TW T3 ML4" @click="Show1=true"
             :style="'overflow:hidden;background:0 0 / 100% 100% url('+CurrThrumb+') no-repeat'"><br>&nbsp;&nbsp;&nbsp;{{Duration}}
        </div>
        <ShowVFullScreen v-if="Show1" :VideoSrc="CurrVideo" @FromShowV="Show1=false"></ShowVFullScreen>
    </div>
</template>

<script>
    import {myVodpost} from '@/components/SharedVues/Shared0.js';
    import ShowVFullScreen from "./ShowVFullScreen";

    export default {
        name: "ShowVFromLargeThumb",
        props: ['Video'],
        components: {ShowVFullScreen},
        data() {
            return {
                Show1: false,
                CurrThrumb: '',
                CurrVideo: '',
                Duration: 0,
            }
        },
        mounted() {
            let that = this;
            myVodpost("getMediaInfos", {FileId: that.Video}, function (Obj) {
                let asd1 = Math.round(Obj.Duration / 60) - 1, asd2 = Math.round(Obj.Duration % 60);
                asd1 = asd1 < 0 ? 0 : asd1;
                that.Duration = "" + (asd1 < 10 ? "0" + asd1 : asd1) + ":" + (asd2 < 10 ? "0" + asd2 : asd2);
                that.CurrThrumb = Obj.CoverUrl;
                that.CurrVideo = Obj.MediaUrl;
            });
        }
    }
</script>
<style scoped>
    .LargeMVideoFrame {
        width: 90vw;
        height: 50vw;
        border-right: 4px dotted #5f5f5f;
        border-left: 4px dotted #5f5f5f;
        outline: #131313 solid 1px;
        }
</style>