import {myMongoDBPost} from "../SharedVues/Shared0";

function SetEnjoySite(Username, ItemStr, SiteId, CallBack) {
    let aObj = {
        UserName: Username,
        ItemStr: ItemStr,
        Bool: true,
        SiteId: SiteId,
        //City: SiteDoc.City,
        //SiteShortName: SiteDoc.SiteShortName,
        //SiteName: SiteDoc.SiteName,
        //Address: SiteDoc.Address,
        //Lati: SiteDoc.Lati,
        //Longi: SiteDoc.Longi,
        JF: 0,
        JFStamp: (new Date()).getTime(),//最新积分时间戳
    };
    myMongoDBPost("UpdateMySite", aObj, function () {
        CallBack();
    })
}//当触发某个项目场地时，先自动将其设为我的喜约场地

export {SetEnjoySite}